import React, { Fragment, useCallback, useEffect, useState } from "react";
import Switch from "react-switch";
import { BsMicMute, BsMic } from "react-icons/bs";
import { useRoomConnect } from "../../hooks/useRoomConnect";
import { SwipeButton, CircleButton, Wrapper } from "./styled";
import { MicOnIconButton, MicOffIconButton } from "../../assets/icons";
import {setSSAudioEnable} from "../../sessionstorage"

type IToggleAudioButtonProps = {
  className?: string;
  type: "circle" | "swipe";
  enabled?: boolean;
  pauseRoom?: boolean;
  bgColor?: string;
  onClickCustom?: (audioEnable: boolean) => void;
};

export const ToggleAudioButton: React.FC<IToggleAudioButtonProps> = (
  props: IToggleAudioButtonProps
) => {
  const [audioEnable, setAudioEnable] = useState<boolean>(false);
  const { enableAudio, disableAudio, audioTrack } = useRoomConnect();
  const [audioEnabledByButtonClick, setAudioEnabledByButtonClick] =
    useState<boolean>(false);

  const enableInternalLocalAudio = useCallback(async () => {
    await enableAudio();
    setAudioEnable(true);
    // eslint-disable-next-line
  }, []);

  const disableInternalLocalAudio = useCallback(async () => {
    await disableAudio();
    setAudioEnable(false);
    // eslint-disable-next-line
  }, []);

  const getAudio = useCallback(async () => {
    if (props.onClickCustom) {
      props.onClickCustom(!audioEnable);      
    }

    if (!audioEnable) {
      await enableInternalLocalAudio();
      setAudioEnabledByButtonClick(true);
      setSSAudioEnable('true')
    } else {
      await disableInternalLocalAudio();
      setAudioEnabledByButtonClick(false);
      setSSAudioEnable('false')
    }
    // eslint-disable-next-line
  }, [audioEnable]);

  useEffect(() => {
    if (props.enabled) {
      enableInternalLocalAudio();
      setAudioEnabledByButtonClick(true);
    } else {
      disableInternalLocalAudio();
    }
  }, [props.enabled]); //eslint-disable-line

  useEffect(() => {    
    if (audioTrack.isEnabled) {
      enableInternalLocalAudio();
      setAudioEnabledByButtonClick(true);
    } else {
      setAudioEnable(false);
    }
  }, [audioTrack]); //eslint-disable-line

  useEffect(() => {
    if (props.pauseRoom) disableInternalLocalAudio();

    if (props.pauseRoom === false && audioEnabledByButtonClick)
      enableInternalLocalAudio();
  }, [props.pauseRoom]); //eslint-disable-line

  return (
    <Wrapper>
      {/* {props.type === "circle" && (
        <CircleButton onClick={getAudio} tabIndex={0}
        role="checkbox"
        aria-label={ audioEnable ? "Clique para desativar áudio" : "Clique para ativar áudio"  }
        aria-checked={audioEnable}
        style={{backgroundColor: props.bgColor}}>
          <div>
            {audioEnable && <MicOnIconButton />}
            {!audioEnable && <MicOffIconButton />}
          </div>
        </CircleButton>
      )}

      {props.type === "swipe" && (
        <SwipeButton
          audioEnable={audioEnable}
          onClick={getAudio}
          {...props}
          tabIndex={0}
          role="checkbox"
          aria-label={ audioEnable ? "Clique para desativar áudio" : "Clique para ativar áudio"  }
          aria-checked={audioEnable}
          style={{backgroundColor: props.bgColor}}
        >
          <span aria-hidden="true">Áudio</span>
          <div>
            {audioEnable && <MicOnIconButton />}
            {!audioEnable && <MicOffIconButton />}
          </div>
        </SwipeButton>
      )} */}
      {audioEnable 
      ? <BsMicMute 
          color="#fff" 
          size={20}
        /> 
      : <BsMic 
          color="#fff" 
          size={20}
        />
      }

      <p aria-hidden="true">Áudio</p>

      <Switch 
        onChange={getAudio} 
        checked={audioEnable} 
        uncheckedIcon={false}
        checkedIcon={false}
        onColor="#48c664"
        offColor="#d02a3e"
        aria-label={ audioEnable ? "Clique para desativar áudio" : "Clique para ativar áudio"  }
        aria-checked={audioEnable}
        width={50}
        height={25}
        handleDiameter={23}
      />
    </Wrapper>
  );
};
