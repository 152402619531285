import styled, { css } from "styled-components";
import closeChat from "../../assets/images/closeChat.svg";

export const ChatStyled = styled.div`
  position: fixed;
  background: #1a1422;
  z-index: 1;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 90%);
  overflow: auto;
  box-sizing: border-box;
  transition: all ease-in 0.4s;
  margin: 0px 20px 10px 20px;
  border-radius: 12px;

  ${({ toggleChat }: { toggleChat: boolean }) =>
    !toggleChat
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `}

  flex-direction: column;
  flex-wrap: nowrap;
  height: 80vh;
  align-items: center;
  padding: 15px 20px;

  bottom: 0;
  left: 0;
  right: 0;

  @media (max-width: 768px) {
    justify-content: flex-end;
  }
`;

export const ToogleChat = styled.button`  
  background-color: #fff;
  mask-image: url(${closeChat});
  mask-size: cover;
  mask-repeat: no-repeat;
  left: 0px;
  transform: scale(0.6);
  top: -5px;
  z-index: 20;
  position: absolute;  
  width: 50px;
  height: 50px;  
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
`;

export const ChatBar = styled.div`
  position: relative;
  height: 50px;
  display: flex;
  border-radius: 60px;
  background-color: #e5e5e5;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  input {
    background: transparent;
    border: 0px;
    height: 100%;
    width: 100%;
    margin-left: 10px;
    min-height: 45px;    
    ::placeholder {
      color: #8a8a8a;
      font-size: 16px;
    }
  }

  button {
    cursor: pointer;
    border: 0;
    border-radius: 100%;
    padding: 7px;
    background-color: ${({ bgColor }: { bgColor: string }) => bgColor};
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 3px;

    svg {
      width: 18px;
    }
  }
`;

export const ChatMessagesArea = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  margin-bottom: ${({ showFileUpload }: { showFileUpload: boolean }) =>
    showFileUpload ? "10px" : "45px"};
  margin-top: 20px;
`;

export const FileuploadArea = styled.label`
  bottom: 15px;
  cursor: pointer;
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 30px;
  margin-bottom: 10px;

  input {
    display: none;
  }
`;

export const FileIconArea = styled.div`
  height: 18px;
  width: 18px;
  padding: 5px;
  background-color: ${({ bgColor }: { bgColor: string }) => bgColor};
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

export const AccessibilityChatArea = styled.span`
	width:0px;
	height:0px;
	padding:0;
	margin:-1px;
	overflow:hidden;
  clip:rect(0,0,0,0);
	border:0
`;