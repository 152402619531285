import styled, { css } from "styled-components";

export const QueueContainer = styled.div`
  ${(props: { pageAssets?: any }) =>
    props.pageAssets && props.pageAssets.backgroundColor
      ? css`
          background: linear-gradient(
            180deg,
            ${props.pageAssets.backgroundColor} -18.72%,
            ${props.pageAssets.contrastColor || '#0a070f'} 75.27%
          );
        `
      : css`
          background: linear-gradient(180deg, #2c1050 -18.72%, #0a070f 75.27%);
        `}

  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  .logo-client {
    max-width: 200px;
    max-height: 100px;
  }
  img {
    margin-top: 16px;
    width: 160px;
  }
  img:last-of-type {
    margin-bottom: 16px;
    margin-top: 0px;
  }

  @media (max-width: 768px) {
    img {
      width: 90px;
    }
  }
`;

export const VideoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60vw;
  video {
    width: 80%;

    border-radius: 22px;
  }
  button {
    border: none;
    height: 50px;
    margin-top: 16px;
    width: 200px;
    border-radius: 20px;
    outline: none;
  }
  @media (max-width: 768px) {
    width: 100vw;
    video {
      width: 90%;
      height: 400px;
    }
    button {
      justify-self: flex-end;
    }
  }
`;
