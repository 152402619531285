import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import Loader from "./components/loader";
import { RoomConnectProvider } from "./hooks/useRoomConnect";
import { Store } from "./redux/store";
import './assets/Poppins/Poppins-Regular.ttf';
import './assets/Poppins/Poppins-Bold.ttf';
import './index.css'


ReactDOM.render(
  <React.StrictMode>
    <RoomConnectProvider>
      <Provider store={Store}>
        <Loader />
        <App />
      </Provider>
    </RoomConnectProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
