import styled, { css } from "styled-components";

export const RoomContainer = styled.div`
  ${(props: { pageAssets?: any; openChat?: boolean }) =>
    props.pageAssets && props.pageAssets.backgroundColor
      ? css`
          background: linear-gradient(
            180deg,
            ${props.pageAssets.backgroundColor} -18.72%,
            ${props.pageAssets.contrastColor || "#0a070f"} 75.27%
          );
        `
      : css`
          background: linear-gradient(180deg, #2c1050 -18.72%, #0a070f 75.27%);
        `}
  ${(props: { pageAssets?: any; openChat?: boolean }) =>
    props.openChat
      ? css`
          justify-content: flex-start;
        `
      : css`
          justify-content: center;
        `}


  //height: --webkit-fill-available;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) and (max-height: 700px) {
    justify-content: space-evenly;
  }
`;
export const LogoClient = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  img {
    max-width: 100px;
    max-height: 100px;
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;
export const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
`;

export const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: all 0.5s linear;
  position: relative;
  padding: 0 15px;
  min-height: calc(100% - 260px);
  max-height: calc(100% - 300px);
  overflow: auto;

  div[id*="-visitant"] {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
  }
`;

export const RoomHeader = styled.div`
  text-align: center;
  z-index: 9;
  color: ${({ color }: { color: string }) => color};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 10px;
  position: relative;
  top: 20px;

  img {
    width: 90px;
    max-width: 90px;
  }
  & > div {
    span {
      font-size: 9px;
      letter-spacing: 0.5px;
    }
    p {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      line-height: 14px;
    }
  }
`;

export const OverlayButtons = styled.div`
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  margin: 0px;

  .peoplesBox {
    background-color: rgb(0 0 0 / 63%);
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    color: #fff;
    bottom: 72px;
    right: -13px;
    z-index: 300;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-size: 10px;
      }
    }

    h6 {
      font-size: 10px;
      margin: 0;
    }
  }
`;

export const PeoplesMarker = styled.div`
  margin: 0px;
  position: relative;
  button {
    cursor: pointer;
    border: 0px;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    height: 48px;
    width: 48px;
  }
`;

export const PauseRoomScreen = styled.div`
  width: 100%;

  height: 80%;
  min-height: 400px;
  background-color: #ccc;
  z-index: 1500;
  border-radius: 10px;
  top: 0;
  position: absolute !important;
`;

export const OpenChatButton = styled.button`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  background-color: #fff;
`;

export const ChatIconArea = styled.div`
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;

export const LocalVideoArea = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 25%;
  max-height: 20%;
  border-radius: 8px;
  z-index: 200;
  top: 20px;
  left: 25px;

  video {
    object-fit: cover;
    transform: scaleX(-1);
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .icon-avatar {
    width: 100%;
    height: fit-content;
    background-color: #ccc;
    z-index: 1500;
    border-radius: 10px;
    top: 0;
    position: absolute;
  }
`;

export const GuestVideoArea = styled.div`
  position: absolute;
  border-radius: 8px;
  z-index: 200;
  top: 15px;
  right: 30px;
  width: 100%;
  height: 100%;
  max-width: 25%;
  max-height: 20%;

  min-height: 40px;
  min-width: 40px;

  video {
    transform: scaleX(-1);
    width: 100%;
    border-radius: 10px;

    :disabled {
      background-color: #fff;
    }
  }

  .icon-avatar {
    width: 100%;
    height: fit-content;
    background-color: #ccc;
    z-index: 1500;
    border-radius: 10px;
    top: 0;
    position: absolute;
  }
`;

export const AttendantVideoArea = styled.div`
  width: 100%;
  ${(props: { openChat: boolean }) =>
    props.openChat &&
    css`
      top: -135px;
    `}
  ${(props: { openChat: boolean }) =>
    !props.openChat
      ? css`
          height: 80vh;
        `
      : css`
          height: 60vh;
        `}


  border-radius: 10px;
  margin-bottom: 15px;
  overflow: hidden;
  min-height: 40px;
  min-width: 40px;

  video {
    object-fit: cover;
    background-color: #000;
    width: 100%;
    height: 100%;
    transform: scaleX(-1);
    border-radius: 15px;
  }

  div {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .icon-avatar {
      width: 100%;
      height: 100%;
      min-height: 40vh;
      background-color: #f0f0f0;
      z-index: 300;
      border-radius: 22px;
      top: 0;

      h5 {
        text-align: center;
        font-size: 24px;
        margin-top: 70px;
        position: absolute;
      }
    }
  }
`;

export const AwaitingAttendantOnCam = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  h5 {
    color: #fff;
  }
`;

export const BackgroundWhenTrackIsZero = styled.div`
  width: 100%;
  height: 100%;
  background-color: #00000052;
  border-radius: 8px;
`;

export const AreaButtons = styled.div`
  margin: 30px 0;
`;

export const SwitchButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  height: 50px;

  button {
    margin: 0 5px;
    padding: 0px;
    height: 48px;
    width: 48px;
    background: #fff;
    border-radius: 100%;
    position: relative;

    span {
      position: absolute;
      top: 8px;
      background: #414141;
      color: #fff;
      right: 8px;
      font-size: 10px;
      border-radius: 100%;
      width: 11px;
      height: 11px;
    }
  }
`;

export const ChatContainer = styled.div`
  background-color: #000;
  padding: 0 15px 20px 15px;
`;

export const VideoDisabledIconContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;

  min-width: ${({ props }: { props: any }) => props.width + "px"};
  min-height: ${({ props }: { props: any }) => props.height + "px"};
  img {
    width: auto;
  }
`;
