import React, { Fragment, useCallback, useEffect, useState } from "react";
import Switch from "react-switch";
import { BsCameraVideoOff, BsCameraVideo } from "react-icons/bs";
import { useRoomConnect } from "../../hooks/useRoomConnect";
import { SwipeButton, CircleButton, Wrapper } from "./styled";
import { CamOnIconButton, CamOffIconButton } from "../../assets/icons";
import {setSSVideoEnable} from "../../sessionstorage"

type IToggleVideoButtonProps = {
  className?: string;
  type: "circle" | "swipe";
  enabled?: boolean;
  pauseRoom?: boolean;
  onClickCustom?: (videoEnable: boolean) => void;
  bgColor?: string;
};

export const ToggleVideoButton: React.FC<IToggleVideoButtonProps> = (
  props: IToggleVideoButtonProps
) => {
  const [videoEnable, setVideoEnable] = useState<boolean>(false);
  const { enableVideo, disableVideo, videoTrack } = useRoomConnect();
  const [videoEnabledByButtonClick, setVideoEnabledByButtonClick] =
    useState<boolean>(false);

  const enableInternalLocalVideo = useCallback(async () => {
    await enableVideo();
    setVideoEnable(true);
    // eslint-disable-next-line
  }, []);

  const disableInternalLocalVideo = useCallback(async () => {
    await disableVideo();
    setVideoEnable(false);
    // eslint-disable-next-line
  }, []);

  const getVideo = useCallback(async () => {
    if (props.onClickCustom) {
      props.onClickCustom(!videoEnable);      
    }
    if (!videoEnable) {
      await enableInternalLocalVideo();
      setVideoEnabledByButtonClick(true);
      setSSVideoEnable('true')
    } else {
      await disableInternalLocalVideo();
      setVideoEnabledByButtonClick(false);
      setSSVideoEnable('false')
    }
    // eslint-disable-next-line
  }, [videoEnable]);

  useEffect(() => {
    if (props.enabled) {
      enableInternalLocalVideo();
      setVideoEnabledByButtonClick(true);
    }

    if (props.enabled === false && videoEnable) {
      disableInternalLocalVideo();
    }
  }, [props.enabled]); //eslint-disable-line

  useEffect(() => {
    if (videoTrack.isEnabled) {
      setVideoEnabledByButtonClick(true);
      enableInternalLocalVideo();
    } else {
      setVideoEnable(false);
    }
  }, [videoTrack]); //eslint-disable-line

  useEffect(() => {
    if (props.pauseRoom) disableInternalLocalVideo();
  
    if (props.pauseRoom === false && videoEnabledByButtonClick)
      enableInternalLocalVideo();
  }, [props.pauseRoom]); //eslint-disable-line

  return (
    <Wrapper>
      {/* {props.type === "circle" && (
        <CircleButton onClick={getVideo} tabIndex={0}
        role="checkbox"
        aria-label={ videoEnable ? "Clique para desativar video" : "Clique para ativar video"  }
        aria-checked={videoEnable}
        style={{backgroundColor: props.bgColor}}>
          {videoEnable && <CamOnIconButton />}
          {!videoEnable && <CamOffIconButton />}
        </CircleButton>
      )}
      {props.type === "swipe" && (
        <SwipeButton
          videoEnable={videoEnable}
          onClick={getVideo}
          {...props}
          tabIndex={0}
          role="checkbox"
          aria-label={ videoEnable ? "Clique para desativar video" : "Clique para ativar video"  }
          aria-checked={videoEnable}
          style={{backgroundColor: props.bgColor}}
        >
          <span aria-hidden="true">Vídeo</span>
          <div>
            {videoEnable && <CamOnIconButton />}
            {!videoEnable && <CamOffIconButton />}
          </div>
        </SwipeButton>
      )} */}

      {videoEnable 
      ? <BsCameraVideoOff 
          color="#fff" 
          size={24}
        /> 
      : <BsCameraVideo 
          color="#fff" 
          size={24}
        />
      }

      <p aria-hidden="true">Vídeo</p>

      <Switch 
        onChange={getVideo} 
        checked={videoEnable} 
        uncheckedIcon={false}
        checkedIcon={false}
        onColor="#48c664"
        offColor="#d02a3e"
        aria-label={ videoEnable ? "Clique para desativar vídeo" : "Clique para ativar vídeo"  }
        aria-checked={videoEnable}
        width={50}
        height={25}
        handleDiameter={23}
      />
    </Wrapper>
  );
};
