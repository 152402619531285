import React, { Fragment, useState } from "react";
// @ts-ignore
import { mask, unMask } from "remask";
import { FormContainer } from "./styled";

type FormProps = {
  formFields: Array<{
    title: string;
    key: string;
    required: string;
    msgError?: string;
  }>;
  message: string;
  onHandleSubmit: Function;
  style: {backgroundColor: string, contrastColor: string}
};

const maskValue = (e: any, masks: Array<string>) => {
  const { value } = e.currentTarget;
  const originalMaks = unMask(value);
  const maskedValue = mask(originalMaks, masks);
  return maskedValue;
};

export const DinamicForm: React.FC<FormProps> = ({
  formFields,
  message,
  onHandleSubmit,
  style
}: FormProps) => {
  const [finalFields, setFinalFields] = useState<
    Array<{
      key: string;
      value: string;
    }>
  >([]);

  const sendFormData = async (e: any) => {
    e.preventDefault();
    return onHandleSubmit(finalFields);
  };

  const onChangeInput = (value: string, key: string) => {
    let newFinalFields: Array<{
      key: string;
      value: string;
    }> = finalFields;

    if (newFinalFields.length === 0) {
      setFinalFields([{ key, value }]);
      return;
    }

    const existField = finalFields.findIndex((field) => field.key === key);

    if (existField >= 0) {
      newFinalFields[existField].value = value;
    } else {
      newFinalFields.push({
        key,
        value,
      });
    }

    setFinalFields(newFinalFields);
  };

  return (
    <FormContainer pageAssets={style}>
      <h1>{message}</h1>
      <form onSubmit={sendFormData}>
        {formFields.map((field) => {
          return (
            <Fragment key={field.key}>
              <input
                type={field.title === "Telefone" ? "Input" : "Input"}
                placeholder={`${
                  field.required ? `* ${field.title}` : field.title
                }`}
                key={field.title}
                onChange={(e) => {
                  if (field.title === "Telefone") {
                    e.currentTarget.value = unMask(e.currentTarget.value);
                  }
                  onChangeInput(e.currentTarget.value, field.key);

                  e.target.value =
                    field.title === "Telefone"
                      ? maskValue(e, ["(99) 9999-9999", "(99) 9-9999-9999"])
                      : e.target.value;
                }}
              />
              {field.msgError && (
                <p className="error-field">{field.msgError}</p>
              )}
            </Fragment>
          );
        })}
        <button type="submit" className="btn-enter">
          Entrar{" "}
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.07807 6.37982L7.494 8.6267C7.20702 9.03383 7.49925 9.59648 7.99686 9.59648L8.96569 9.59648L8.96569 20.3848C8.96569 20.7245 9.24115 21 9.58092 21C9.9207 21 10.1962 20.7245 10.1962 20.3848L10.1962 9.59648L11.1649 9.59648C11.6658 9.59648 11.9526 9.03083 11.6678 8.62675L10.0838 6.37986C9.83912 6.03271 9.32306 6.03299 9.07807 6.37982Z"
              fill={style.backgroundColor || "#6229BF"}
            />
            <path
              d="M-3.66375e-07 9.58071C-2.11912e-07 13.1144 1.91777 16.218 4.78994 17.8796C5.08407 18.0497 5.46043 17.9492 5.63056 17.6551C5.80069 17.3609 5.70012 16.9846 5.40604 16.8145C2.90777 15.3692 1.23047 12.668 1.23047 9.58071C1.23047 4.9762 4.97648 1.23015 9.58103 1.23015C14.1856 1.23015 17.9316 4.9762 17.9316 9.58071C17.9316 12.6699 16.2527 15.3702 13.7559 16.8145C13.4618 16.9846 13.3613 17.361 13.5314 17.6551C13.7015 17.9492 14.0779 18.0498 14.372 17.8796C17.245 16.2177 19.162 13.1135 19.162 9.58071C19.1621 4.28525 14.8769 -0.000321085 9.58103 -0.000320853C4.28553 -0.000320622 -5.97867e-07 4.2848 -3.66375e-07 9.58071Z"
              fill={style.backgroundColor || "#6229BF"}
            />
          </svg>
        </button>
      </form>
    </FormContainer>
  );
};
