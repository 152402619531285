import styled from "styled-components";

export const Ballon = styled.div<{ 
  me: boolean; 
  bgColor: {  
    visitant: string;
    attendant: string;
  };
  color: string;
}>`
  float: ${({ me }) => me ? "right" : "left"};
  background-color: ${({ me, bgColor }) => me ? bgColor.visitant : bgColor.attendant };
  width: 100%;
  margin-top: 10px;
  max-width: 200px;
  display: block;
  width: fit-content;
  padding: 10px;
  color: ${({ color }) => color};
  border-radius: 25px;
  line-break: auto;
  margin-right: 15px;
  word-break: break-word;

  a {
    color: ${({ me, color }) => me ? color : "#fff"};
    text-decoration: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
`;

export const BallonArea = styled.div`
  width: 100%;
`;

export const AccessibilityChatArea = styled.span`
	width:0px;
	height:0px;
	padding:0;
	margin:-1px;
	overflow:hidden;
  clip:rect(0,0,0,0);
	border:0
`;