import styled, { css } from "styled-components";
import iconBackApp from "../../assets/images/back-app.svg";
import iconSend from "../../assets/images/btn-send.svg";
import iconComment from "../../assets/images/icon-comment.svg";
import iconUser from "../../assets/images/user-video.svg";
import iconVideo from "../../assets/images/video.svg";

export const QueueContainer = styled.div`
  ${(props: { pageAssets?: any }) =>
    props.pageAssets && props.pageAssets.backgroundColor
      ? css`
          background: linear-gradient(
            180deg,
            ${props.pageAssets.backgroundColor} -18.72%,
            ${props.pageAssets.contrastColor} 75.27%
          );
        `
      : css`
          background: linear-gradient(180deg, #2c1050 -18.72%, #0a070f 75.27%);
        `}

  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  h1 {
    color: ${(props: { pageAssets?: any }) => props.pageAssets.textColor || "#fff"};
  }
  img {
    margin-top: 16px;
    width: 160px;
  }
  img:last-of-type {
    margin-bottom: 16px;
    margin-top: 0px;
  }

  @media (max-width: 768px) {
    img {
      width: 90px;
    }
  }

  .btn-enter {
    width: 168px;
    height: 53px;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    font-family: "Poppins";
    padding-right: 15px;
    cursor: pointer;

    svg {
      position: absolute;
      right: 23px;
      top: 19px;
    }

    @media (max-width: 890px) {
      width: 128px;

      svg {
        right: 11px;
        top: 17px;
        width: 15px;
      }
    }

    @media (max-width: 768px) {
      width: 168px;
      svg {
        position: absolute;
        right: 23px;
        top: 19px;
        width: initial;
      }
    }

    @media (max-width: 550px) {
      position: absolute;
      top: 67px;
      width: 40%;
      left: 30%;
      svg {
        right: 11px;
        top: 17px;
        width: 15px;
      }
    }

    @media (max-width: 360px) {
      position: absolute;
      top: 67px;
      width: 40%;
      left: 30%;
      svg {
        right: 14px;
        top: 17px;
      }
    }

    &:hover {
      background-color: #6709a7;
      color: #fff;
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
`;

export const FinalizeContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    color: #fff;
    text-align: center;
  }
`;

export const VideoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 55vw;
  max-width: 700px;
  video {
    width: 100%;

    border-radius: 22px;
  }
  button {
    border: none;
    height: 56px;
    margin-top: 0px;
    width: 200px;
    border-radius: 50px;
    outline: none;

    &.btn-send {
      background: #fff url(${iconSend}) no-repeat right 20px bottom 18px;
      width: auto;
      padding: 0 80px 0 63px;
      text-align: center;
      margin-top: 53px;
    }
  }
  @media (max-width: 768px) {
    width: 90vw;
    video {
      width: 100%;
      height: auto;
    }
    button {
      justify-self: flex-end;
    }
  }

  .rating-nps {
    display: flex !important;
    margin-top: 24px;

    > span {
      cursor: pointer;
      position: relative;
      width: 56px;
      height: 56px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      padding-top: 0px;
      margin: 4px;

      span {
        display: flex !important;
        top: initial !important;
        justify-content: center;
      }
    }
  }
  .confirmRated {
    color: #000;
    width: auto;
    margin-top: 25px;
  }

  .commentArea {
    width: 100%;
    height: 274px;
    border-radius: 10px;
    padding: 10px;
  }

  .input-file {
    text-align: center;
    label {
      font-size: 36px;
      color: #fff;
      margin-bottom: 50px;
      display: block;
    }
    input {
      color: #fff;
      font-size: 24px;
      display: block;
      margin-bottom: 16px;
    }
  }
`;

export const LocalVideo = styled.div`
  position: absolute;
  width: 26%;
  top: 15px;
  left: 15px;
  display: flex;
  opacity: 0.9;
  border-radius: 10px;
  video {
    border-radius: 10px;
    transform: scaleX(-1);
  }
`;

export const LocalVideoLoading = styled.div`
  position: absolute;
  width: 26%;
  top: 15px;
  left: 15px;
  background: #b2b2b4 url(${iconUser}) no-repeat center center;
  display: flex;
  opacity: 0.9;
  border-radius: 10px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 52px;
  margin-bottom: 30px;
  position: relative;

  button {
    margin: 0 23px;
    width: auto;

    &.comment-btn {
      background: #fff url(${iconComment}) no-repeat right 20px bottom 17px;
      padding-right: 58px;
    }
    &.back-app {
      background: #fff url(${iconBackApp}) no-repeat right 20px bottom 19px;
      padding-right: 47px;
    }
    &.video-btn {
      background: #fff url(${iconVideo}) no-repeat right 20px bottom 19px;
      padding-right: 60px;
    }
  }
`;

export const EnterInRoomButton = styled.button`
  width: 210px;
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
`;
