import styled, { css } from "styled-components";

export const VideoContainer = styled.div<{ isFullScreen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.5s linear;
  position: relative;
  padding: 0 15px;  
  overflow: hidden;

  div[id*="-visitant"] {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
  }

  ${({isFullScreen}) => isFullScreen ? 
    css`
      height: 90%;
    ` : 
    css`
      height: 70%;
    `};
`;

export const VideoContent = styled.div<{ openChat: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 55vw;
  max-width: 700px;
  
  .video-content {
    width: 100%;
    border-radius: 22px;
  }

  @media (max-width: 768px) {
    width: 90vw;
    overflow: hidden;    

    .video-content {
      width: 100%;
      min-width: 280px;
      height: auto;      
      min-height: 180px;
      object-fit: cover;
    }
  }

  @media (min-height: 700px) {
    ${({ openChat }) => openChat && css`
      position: absolute;
      bottom: 37%;
      margin-right: 20px;      
    `};
  }

  @media (max-height: 700px) {
    ${({ openChat }) => openChat && css`
      position: absolute;
      bottom: 37%;
      margin-right: 20px;
    `};
  }

  @media (max-width: 280px) {
    width: 90vw;    

    .video-content {
      width: 95%;
      min-width: 250px;
      height: auto;
      min-height: 250px;
      object-fit: cover;
    }
  }
`;

export const RoomHeader = styled.div<{ openChat: boolean; color: string }>`
  text-align: center;
  z-index: 999;
  color: ${({ color }) => color};

  display: ${({ openChat }) => openChat ? 'none' :  'flex'};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 10px;
  position: relative;
  top: 20px;

  img {
    width: 90px;
    max-width: 90px;
  }
  & > div {
    span {
      font-size: 9px;
      letter-spacing: 0.5px;
    }
    p {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      line-height: 14px;
    }
  }

`;

export const PlayButton = styled.button<{ isPlaying: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    display: ${props => props.isPlaying ? 'none' : 'flex'};
    position: absolute;
    background: none;
    border: none;
    z-index: 999;
`;

export const LocalVideoArea = styled.div<{ openChat: boolean }>`
  position: absolute;
  width: 20%;
  height: 20%;
  border-radius: 8px;
  z-index: 200;
  top: 10px;
  left: 10px;

  video {
    object-fit: cover;
    transform: scaleX(-1);
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .icon-avatar {
    width: 100%;
    height: fit-content;
    background-color: #ccc;
    z-index: 1500;
    border-radius: 10px;
    top: 0;
    position: absolute;
  }

  ${({openChat}) => openChat && css`
    right: 160px;
    width: 60px;
    max-width: 60px;
    min-width: 60px;
    height: 100px;
    max-height: 100px;
    left: unset;
    top: unset;
    max-height: unset;

    @media (max-width: 375px) {
      width: 60px;
      max-width: 60px;
      min-width: 60px;
      right: 130px;      
    }

    @media (max-width: 280px) {
      width: 50px;
      max-width: 50px;
      min-width: 50px;
      right: 120px;
    }
  `}
`;

export const GuestVideoArea = styled.div<{ openChat: boolean }>`
  position: absolute;
  border-radius: 8px;
  z-index: 200;
  top: 10px;
  right: 10px;
  width: 100%;
  max-width: 20%;
  max-height: 20%;
  height: 20%;

  min-height: 40px;
  min-width: 40px;

  video {
    transform: scaleX(-1);
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;

    :disabled {
      background-color: #fff;
    }
  }

  .icon-avatar {
    width: 100%;
    height: fit-content;
    background-color: #ccc;
    z-index: 1500;
    border-radius: 10px;
    top: 0;
    position: absolute;
  }

  ${({openChat}) => openChat && css`
    right: 230px;
    width: 60px;
    max-width: 60px;
    min-width: 60px;
    height: 100px;
    max-height: 100px;
    top: unset;
    max-height: unset;

    @media (max-width: 375px) {
      width: 60px;
      min-width: 60px;
      max-width: 60px;
      right: 200px;
    }

    @media (max-width: 280px) {
      width: 50px;
      min-width: 50px;
      max-width: 50px;
      right: 180px;
    }
  `}
`;

export const AttendantVideoArea = styled.div<{ openChat?: boolean }>`
  width: 100%;
  height: 80vh;
  border-radius: 10px;
  overflow: hidden;
  min-height: 40px;
  min-width: 40px;

  video {
    object-fit: cover;
    background-color: #000;
    width: 100%;
    height: 100%;
    transform: scaleX(-1);
    border-radius: 15px;
  }

  div {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .icon-avatar {
      width: 100%;
      height: 100%;
      min-height: 40vh;
      background-color: #f0f0f0;
      z-index: 300;
      border-radius: 22px;
      top: 0;

      h5 {
        text-align: center;
        font-size: 24px;
        margin-top: 70px;
        position: absolute;
      }
    }
  }

  ${({ openChat }) => openChat && css`
    width: 150px !important;
    max-width: 150px;
    min-width: 150px;
    margin-bottom: 0px;
    height: 100px;
    max-height: 100px;
    align-self: flex-end;

    @media (max-width: 375px) {
      width: 120px !important;
      max-width: 120px;
      min-width: 120px;
    }

    
    @media (max-width: 280px) {
      width: 110px !important;
      max-width: 110px;
      min-width: 110px;      
    }
  `};
`;

export const VideoDisabledIconContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;

  min-width: ${({ props }: { props: any }) => props.width + "px"};
  min-height: ${({ props }: { props: any }) => props.height + "px"};
  img {
    width: auto;
  }
`;

export const AccessibilityChatArea = styled.p`
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`;
