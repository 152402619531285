import { io } from "socket.io-client";
import { getProtocolId, getVId } from "../sessionstorage";
import { getDeviceInfos } from "../utils";

let url = window.location.hostname.indexOf("stage") > -1;
let socketUrl = url
  ? process.env.REACT_APP_SOCKET_URL_STAGE
  : process.env.REACT_APP_SOCKET_URL;

export const socket = io(socketUrl ?? "");

socket.on("disconnect", () => {});

export const connectedInSocket = (guestId?: string) => {
  socket.on("connect", () => {
    if (getVId()) updateSocketId();

    if (guestId) addGuestSocketId(guestId);
  });
};

export const addGuestSocketId = (guestId: string) => {
  socket.emit("UPDATE_SOCKET_ID_IN_GUEST", {
    guestId: guestId,
  });
};

export const fileRequestedByAttendant = (cb: Function) => {
  socket.on("REQUEST_FILE_TO_VISITANT", () => {
    cb();
  });
};

export const pauseRoomByAttendant = (cb: Function) => {
  socket.on(
    "PAUSE_ROOM_TO_VISITANT",
    (userName, userImg, roomIsPaused, roleUser) => {
      cb(userName, userImg, roomIsPaused, roleUser);
    }
  );
};

export const updateSocketId = async () => {
  let getIp = await fetch("https://api.ipify.org/?format=json");
  let { ip } = await getIp.json();

  socket.emit("UPDATE_SOCKET_ID_IN_VISITANT", {
    visitantId: getVId(),
    device: {
      ...getDeviceInfos(),
    },
    clientIp: ip,
  });
};

export const addVisitant = (nameQueue: string, cb: Function) => {
  socket.emit(
    "ADD_VISITANT",
    {
      visitantId: getVId(),
      nameQueue,
      device: {
        ...getDeviceInfos(),
      },
    },
    cb
  );
};

export const getVisitantPosition = (nameQueue: string, cb: Function) => {
  socket.emit("GET_VISITANT_POSITION", { nameQueue }, cb);
};

export const getVisitantsQueue = (cb: Function) => {
  socket.emit("GET_VISITANTS_QUEUE", cb);
};

export const matchVisitant = (cb: Function) => {
  socket.on("MATCH_VISITANT", (roomId, timeAttendant) => {
    cb(roomId, timeAttendant);
  });
};

export const roomFinalizedByAttendant = (cb: Function) => {
  socket.on("FINALIZE_ROOM_TO_VISITANT", () => {
    cb();
  });
};

export const getChatMessages = (cb: Function) => {
  socket.emit("GET_CHAT_MESSAGES", getProtocolId(), (messages: Array<any>) => {
    cb(messages);
  });
};

export const chatMessages = (cb: Function) => {
  socket.on("CHAT_MESSAGES", (messages: Array<any>) => {
    cb(messages);
  });
};

export const closeSocketConnection = () => {
  socket.close();
};

export const visitantGetOutRoom = (cb: Function) => {
  socket.emit("VISITANT_EXIT_ROOM", getVId(), getProtocolId(), () => {
    cb();
  });
};

export const addMessageIntoChat = (
  person: "visitant" | "attendant",
  protocolId: string,
  msg: string,
  personId: string,
  messageType: string,
  cb: Function,
  filename?: string
) => {
  socket.emit(
    "ADD_CHAT_MESSAGE",
    {
      person,
      protocolId,
      msg,
      personId,
      messageType,
      filename,
    },
    cb
  );
};

export const enterInRoom = (
  protocolId: string,
  visitantId: string,
  cb: Function
) => {
  return socket.emit(
    "VISITANT_ENTER_IN_ROOM",
    {
      protocolId,
      visitantId,
    },
    cb
  );
};

export const backInRoom = (visitantId: string, cb: Function) => {
  return socket.emit(
    "VISITANT_BACK_IN_ROOM",
    {
      visitantId,
    },
    cb
  );
};
export const visitantGoToQueueAgain = (cb: Function) => {
  socket.on("GO_TO_QUEUE_AGAIN", () => {
    cb();
  });
};
