import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToggleAudioButton } from "../../components/ToggleAudioButton/index";
import { ToggleVideoButton } from "../../components/ToggleVideoButton/index";
import { Countdown } from "../../components/Countdown";

import { useRoomConnect } from "../../hooks/useRoomConnect";
import { request } from "../../services/request";
import { getProtocolId, getVId, setProtocolId } from "../../sessionstorage";
import {
  addVisitant,
  connectedInSocket,
  enterInRoom,
  matchVisitant,
  socket,
  updateSocketId,
  getVisitantPosition,
  backInRoom,
  visitantGoToQueueAgain,
  getVisitantsQueue,
} from "../../socket";

import { verifyIsMobileOrDesktop, sleep, isNubank, isMercadoLivre } from "../../utils";
import {
  ButtonContainer,
  EnterInRoomButton,
  QueueContainer,
  VideoContent,
  CenterDiv,
  AccessibilityArea,
  ToggleContainer,
  QueuePositionP,
  AttendantTime,
} from "./styled";
import useTimeout from "../../hooks/useTimeout";
import Video from "../../components/Video";
import { useLocation } from "react-router-dom";

interface IVideoProps {
  apresentationVideoUrl: string;
  url: string;
  fileName: string;
  description: string;
}

interface IQueueVideos {
  openCamPolite: IVideoProps;
  openCamTimeout: IVideoProps;
  onQueue: IVideoProps;
  onMatch: IVideoProps;
  onMatchWaiting: IVideoProps;
  onMatchRefused: IVideoProps;
}

interface IQueueAssets {
  apresentationVideoUrl: string;
  backgroundColor: string;
  logo: string;
  textColor: string;
  buttonColor: string;
  descriptionVideo: string;
  videoMobile: string;
  descriptionVideoMobile: string;
  mobileVideos: IQueueVideos;
  showAudioButton: boolean;
}

interface ILocation {
  nameQueue: string;
}

export const QueueMobile: React.FC = () => {
  const { state } = useLocation<ILocation>();
  const { connectRoom, videoTrack, audioTrack } = useRoomConnect();
  const protocolIdAlreadyExist = getProtocolId();
  const [queueAssets, setQueueAssets] = useState<IQueueAssets>();
  const [queueLength, setQueueLength] = useState<number>();
  const [loadingCamera, setLoadingCamera] = useState<boolean>(false);
  const [goToRoom, setGoToRoom] = useState<boolean>(false);
  const [roomProtocol, setRoomProtocol] = useState<string>("");
  const [loaderButton, setLoaderButton] = useState<boolean>(false);
  const localVideoRef = useRef<any>();
  const [visitantPosition, setVisitantPosition] = useState<number>(0);
  const [videoDisabled, setVideoDisabled] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [timerCowndown, setTimeCowndown] = useState("");
  const [urlVideo, setUrlVideo] = useState<string | undefined>("");
  const [textVideo, setTextVideo] = useState<string | undefined>("");
  const [goToQueue, setGoToQueue] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState("Continuar");

  const { createTimeout, cancelTimeout } = useTimeout();

  const clientData = async () => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      path: `visitant/${getVId()}`,
    });

    if (!response.error) {
      setQueueAssets(response);
    }
  };

  const history = useHistory();

  const saveIP = () =>
    request({
      showSuccessMessage: false,
      method: "POST",
      path: "visitant/save-ip",
      data: {
        visitantId: getVId(),
      },
    });

  const handleEnterInRoom = async () => {
    let vId = getVId();

    if (goToQueue) {
      setGoToQueue(false);
      setGoToRoom(false);
      setRoomProtocol("");
      addVisitant(state?.nameQueue, getVisitantPositionInSocket);
      return;
    }

    if (protocolIdAlreadyExist) {
      setProtocolId(protocolIdAlreadyExist);
      try {
        setLoaderButton(true);
        await connectRoom({
          roomId: protocolIdAlreadyExist,
          type: "visitant",
        });

        if (vId) {
          backInRoom(vId, () => {});
        }

        setLoaderButton(false);
        history.replace(`/sala`, { audioEnabled });
        return;
      } catch (e) {
        history.push(`/`);
        return;
      }
    }

    if (vId) {
      setLoaderButton(true);
      enterInRoom(roomProtocol, vId, async (response: boolean) => {
        if (!response) {
          setLoaderButton(false);
          return;
        }
        try {
          await connectRoom({
            roomId: roomProtocol,
            type: "visitant",
          });
          setProtocolId(roomProtocol);
          setLoaderButton(false);
          history.replace(`/sala`, { audioEnabled });
        } catch {
          setLoaderButton(false);
          history.push(`/`);
        }
      });
    }
  };

  const getVisitantPositionInSocket = () => {
    setLoadingCamera(false);
    getVisitantPosition(state?.nameQueue, async (index: number) => {
      if (index) {
        setVisitantPosition(index);
        await sleep(1000);
        getVisitantPositionInSocket();
      }
    });
  };

  useEffect(() => {
    if (visitantPosition > 0 && !goToRoom) {
      setUrlVideo(() => queueAssets?.mobileVideos?.onQueue?.url);
      setTextVideo(() => queueAssets?.mobileVideos?.onQueue?.description);
    }
  }, [visitantPosition, goToRoom, queueAssets]);

  useEffect(() => {
    if (goToRoom) {
      setUrlVideo(() => queueAssets?.mobileVideos?.onMatch?.url);
      setTextVideo(() => queueAssets?.mobileVideos?.onMatch?.description);
      createTimeout(() => {
        setUrlVideo(queueAssets?.mobileVideos?.onMatchWaiting?.url);
        setTextVideo(queueAssets?.mobileVideos?.onMatchWaiting?.description);
      }, 20000);
    }

    return () => {
      cancelTimeout();
    };
  }, [goToRoom, queueAssets, cancelTimeout, createTimeout]);

  useEffect(() => {
    if (queueAssets) {
      setUrlVideo(() => queueAssets?.mobileVideos?.openCamPolite?.url);
      setTextVideo(() => queueAssets?.mobileVideos?.openCamPolite?.description);
      createTimeout(() => {
        setUrlVideo(queueAssets?.mobileVideos?.openCamTimeout?.url);
        setTextVideo(queueAssets?.mobileVideos?.openCamTimeout?.description);
      }, 60000);
    }

    return () => {
      cancelTimeout();
    };
  }, [queueAssets, cancelTimeout, createTimeout]);

  useEffect(() => {
    if (socket.id) {
      updateSocketId();
    } else {
      connectedInSocket();
    }
    clientData();
    socket.removeListener("MATCH_VISITANT");
    socket.removeListener("GO_TO_QUEUE_AGAIN");

    matchVisitant((room: string, timeAttendant: string) => {
      setButtonText("Continuar");
      setGoToQueue(false);
      setGoToRoom(true);
      setRoomProtocol(room);
      setTimeCowndown(timeAttendant);
    });

    visitantGoToQueueAgain(() => {
      setButtonText("Voltar a Fila");
      setUrlVideo(queueAssets?.mobileVideos.onMatchRefused.url);
      setTextVideo(queueAssets?.mobileVideos.onMatchRefused.description);

      setGoToQueue(true);
    });

    let protocolId = getProtocolId();
    if (protocolId) {
      setGoToRoom(true);
    }
  }, []); // eslint-disable-line

  const handleCancelCameraTimeout = (videoEnabled: boolean) => {
    if (!goToRoom && videoEnabled) {
      cancelTimeout();
    }
  };

  useEffect(() => {
    if (videoTrack && videoTrack.kind) {
      videoTrack.attach(localVideoRef.current);
      let localVideoRefIntoEffect = localVideoRef.current;

      videoTrack.on("enabled", () => setVideoDisabled(false));
      videoTrack.on("disabled", () => setVideoDisabled(true));
      setVideoDisabled(!videoTrack.isEnabled);

      if (!protocolIdAlreadyExist) {
        addVisitant(state?.nameQueue, getVisitantPositionInSocket);
      }

      setLoadingCamera(true);

      getVisitantsQueue((queue: any[]) => setQueueLength(queue.length));

      return () => {
        videoTrack.detach(localVideoRefIntoEffect);
      };
    }
  }, [videoTrack]); //eslint-disable-line

  useEffect(() => {
    if (audioTrack) {
      setAudioEnabled(audioTrack.isEnabled);
    }
  }, [audioTrack]);

  useEffect(() => {
    saveIP();
  }, []);

  return (
    <Fragment>
      {queueAssets && (
        <QueueContainer pageAssets={queueAssets}>
          <img
            src={queueAssets.logo}
            alt=""
            className="logo-client"
            aria-hidden="true"
          />
          {isNubank() && <AttendantTime aria-hidden={true}>Horário de atendimento: 08h às 20h</AttendantTime>}
          <VideoContent>
            <Video
              context="mobile"
              localVideoRef={localVideoRef}
              hasBackgroundVideo
              url={urlVideo}
              description={textVideo}
              videoDisabled={videoDisabled}
              client={{ logo: queueAssets.logo }}
            />
            {isMercadoLivre() && 
              <AttendantTime aria-hidden={true} bold>Atendimento de segunda à sexta das 08h00 às 18h00</AttendantTime>
            }
            <ButtonContainer>
              <ToggleContainer>
                {queueAssets?.showAudioButton && (
                  <ToggleAudioButton
                    data-cy="btnAudio"
                    type={
                      verifyIsMobileOrDesktop() === "desktop" ? "swipe" : "circle"
                    }
                    onClickCustom={(audioEnable) => setAudioEnabled(audioEnable)}
                  />
                )}


                <ToggleVideoButton
                  onClickCustom={(videoEnabled) =>
                    handleCancelCameraTimeout(videoEnabled)
                  }
                  data-cy="btnVideo"
                  type={
                    verifyIsMobileOrDesktop() === "desktop" ? "swipe" : "circle"
                  }
                />
              </ToggleContainer>

              {goToRoom ? (
                <CenterDiv>
                  <EnterInRoomButton
                    data-cy="btnEnter"
                    className="btn-enter"
                    disabled={loaderButton}
                    onClick={handleEnterInRoom}
                    autoFocus={true}
                    aria-live="assertive"
                    aria-label={
                      loaderButton
                        ? "Aguarde"
                        : `Clique aqui para ${buttonText}`
                    }
                    pageAssets={{
                      ...queueAssets,
                      mt:
                        !protocolIdAlreadyExist && !goToQueue ? " 50px" : "0px",
                      mb: !protocolIdAlreadyExist && !goToQueue ? "5px" : "0px",
                      borderColor: queueAssets.textColor || "#fff",
                      backgroundColor:
                        queueAssets.buttonColor || queueAssets.backgroundColor,
                    }}
                  >
                    {loaderButton ? (
                      <>
                        <span
                          color={queueAssets.textColor || "#FFF"}
                          aria-hidden={true}
                        >
                          Aguarde
                        </span>
                        <div id="spinner" aria-hidden={true}></div>
                      </>
                    ) : (
                      <>
                        <span
                          style={{ marginRight: goToQueue ? "unset" : "10px" }}
                          color={queueAssets.textColor || "#FFF"}
                          aria-hidden={true}
                        >
                          {buttonText}
                        </span>
                      </>
                    )}
                  </EnterInRoomButton>
                </CenterDiv>
              ) : (
                videoTrack?.kind && (
                  <QueuePositionP
                    style={{
                      color: queueAssets?.textColor,
                      textAlign: "center",
                      marginTop: loadingCamera ? "15px" : "unset",
                    }}
                    aria-live="assertive"
                    className="queue-position-text"
                  >
                    {queueLength !== undefined &&
                    queueLength > 0 &&
                    visitantPosition > 0
                      ? `Você está em ${visitantPosition}º lugar`
                      : "Aguardando fila..."}
                    {loadingCamera && "Carregando..."}
                  </QueuePositionP>
                )
              )}

              <AccessibilityArea
                aria-live="assertive"
                tabIndex={0}
                role="alert"
              >
                {audioEnabled ? "Áudio habilitado" : "Áudio desabilitado"}
              </AccessibilityArea>
              <AccessibilityArea
                aria-live="assertive"
                tabIndex={0}
                role="alert"
              >
                {videoTrack.isEnabled && !videoDisabled
                  ? "Vídeo habilitado"
                  : "Vídeo desabilitado"}
              </AccessibilityArea>
            </ButtonContainer>
          </VideoContent>

          <div />
        </QueueContainer>
      )}
    </Fragment>
  );
};
