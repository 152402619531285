import { Ballon as BallonStyled, BallonArea } from "./styled";
import { getVId } from "../../../sessionstorage";
import { FileIcon } from "../../../assets/icons";

interface IMessages {
  type: "attendant" | "visitant";
  msg: string;
  _id: string;
  messageType: "MESSAGE" | "ATTACHMENT";
  bgColor: {
    visitant: string;
    attendant: string;
  };
  txtColor?: string;
  isAllowDigit: boolean;
  index: number;
  totalMsg: number;
  attendantName: string;
  filename?: string;
  personId?: string;
}

export const Ballon = ({
  bgColor,
  txtColor,
  type,
  msg,
  _id,
  messageType,
  isAllowDigit,
  index,
  totalMsg,
  attendantName,
  filename,
  personId,
}: IMessages) => {
  return (
    <BallonArea
      data-cy="chatMsg"
      tabIndex={0}
      // aria-hidden={totalMsg === index ? true : false}
    >
      {isAllowDigit && (
        <BallonStyled
          bgColor={bgColor}
          color={txtColor || "#000"}
          me={getVId() === personId ? true : false}
          aria-label={
            type === "visitant" ? "Eu: " + msg : "Atendente: " + msg
          }
        >
          {messageType === "ATTACHMENT" ? (
            <a
              href={msg}
              title="file"
              target="_blank"
              rel="noreferrer"
              tabIndex={-1}
            >
              {" "}
              <FileIcon bgColor={bgColor.visitant} />
              {filename}
            </a>
          ) : (
            <div color={txtColor || "#000"} aria-hidden={true}>
              {msg}
            </div>
          )}
        </BallonStyled>
      )}

      {!isAllowDigit && (
        <BallonStyled
          color={txtColor || "#000"}
          bgColor={bgColor}
          me={type === "visitant" ? true : false}
          aria-hidden={true}
        >
          {messageType === "ATTACHMENT" ? (
            <a
              href={msg}
              title="file"
              target="_blank"
              rel="noreferrer"
              tabIndex={-1}
            >
              {" "}
              <FileIcon bgColor={bgColor.visitant} />
              {filename}
            </a>
          ) : (
            msg
          )}
        </BallonStyled>
      )}
    </BallonArea>
  );
};
