import styled, { css } from "styled-components";
import iconUser from "../../assets/images/user-video.svg";

export const BrowserNotSupportedContainer = styled.div`
  ${(props: { pageAssets?: any }) =>
    props.pageAssets && props.pageAssets.backgroundColor
      ? css`
          background: linear-gradient(
            180deg,
            ${props.pageAssets.backgroundColor} -18.72%,
            ${props.pageAssets.contrastColor || "#0a070f"} 75.27%
          );
        `
      : css`
          background: linear-gradient(180deg, #2c1050 -18.72%, #0a070f 75.27%);
        `}

  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  img {
    max-height: 80px;
    max-width: 200px;
    margin: 10% 0px -20%;
  }

  @media (min-width: 320px) {
    height: 100vh;
  }

  @media (width: 320px) and (height: 480px) {
    height: initial;
  }
`;

export const VideoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 90vw;
  height: 70%;
  max-width: 700px;
  position: relative;
`;

export const LocalVideo = styled.div`
  position: absolute;
  width: 26%;
  top: 15px;
  left: 15px;
  display: flex;
  opacity: 0.9;
  border-radius: 10px;
  video {
    border-radius: 10px;
    transform: scaleX(-1);
  }
`;

export const LocalVideoLoading = styled.div`
  position: absolute;
  width: 26%;
  top: 15px;
  left: 15px;
  background: #b2b2b4 url(${iconUser}) no-repeat center center;
  display: flex;
  opacity: 0.9;
  border-radius: 10px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  position: relative;
  height: auto;
  align-content: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  @media (max-width: 768px) {
    .btn-enter {
      grid-row: row 2;
      grid-column: span 2;
      margin: 0px auto;
      margin-top: 8px;
    }
  }
`;

export const EnterInRoomButton = styled.button`
  width: 168px;
  height: 53px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50px;
  outline: none;
  background-color: #fff;
  span {
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
    //https: ; //assets.inclusao.pessoalize.com/all/videos/fila-desktop.gif
  }
  @media (max-width: 768px) {
    width: 40%;
    span {
      font-size: 12px;
    }
  }
  &:hover {
    background-color: #6709a7;
    color: #fff;
    svg {
      path {
        fill: #fff;
      }
    }
  }
  #spinner {
    min-width: 15px;
    min-height: 15px;
    border: 3px solid #810ad1;
    border-right: 3px solid orange;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
    max-width: 15px;
    margin-left: 6px;
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
