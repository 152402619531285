import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GuestPassSelectionScreen } from "../../components/GuestPassSelectionScreen";
import { useRoomConnect } from "../../hooks/useRoomConnect";
import { request } from "../../services/request";
import { setVisitantDevice, setProtocolId } from "../../sessionstorage";

interface IGuestPageAssets {
  apresentationVideoUrl: string;
  backgroundColor: string;
  logo: string;
  textColor: string;
  contrastColor: string;
  buttonColor: string;
  guests: Array<string>;
};

export const GuestInvited: React.FC = () => {
  const { protocolId }: { protocolId: string } = useParams();
  const { connectRoom } = useRoomConnect();
  const [guestAssets, setGuestAssets] = useState<IGuestPageAssets>();
  const [verifyed, setVerifyed] = useState<boolean>(false);

  const getRoomByProtocolId = async () => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      path: `rooms/get-guest-assets/${protocolId}`,
    });

    if (!response.error) {
      setVisitantDevice(response.visitantDevice);
      setGuestAssets(response);
    }
  };

  const history = useHistory();
  const handleEnterInRoom = async () => {
    await connectRoom({
      roomId: protocolId,
      type: "guest",
    });
    history.push(`/convidado/sala/${protocolId}`);
  };

  useEffect(() => {
    if (verifyed) {
      handleEnterInRoom();
    }
  }, [verifyed]); //eslint-disable-line

  useEffect(() => {
    getRoomByProtocolId();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setProtocolId(protocolId);
  }, [protocolId]);

  return (
    <Fragment>
      {guestAssets && !verifyed && (
        <GuestPassSelectionScreen
          guests={guestAssets.guests}
          setVerifyed={setVerifyed}
          protocolId={protocolId}
          pageAssets={guestAssets}
        ></GuestPassSelectionScreen>
      )}
    </Fragment>
  );
};
