import styled, { css } from "styled-components";

export const BackgroundWhenTrackIsZero = styled.div`
  width: 100%;
  height: 100%;
  background-color: #00000052;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;

  min-width:  ${({ props }: { props: any }) => props.width+"px"};
  min-height: ${({ props }: { props: any }) => props.height+"px"};
  img {
    ${({ props }: { props: any }) => props.img ?
    css `
    border-radius: 50%;
    width: calc(`+props.width+`px/`+props.sizeImg+`);
    height: calc(`+props.width+`px/`+props.sizeImg+`);
    `
    : css `
    width: auto;
    max-width: calc(`+props.width+`px/3)
    `}
  }


  h1 {
    color: #fff;
    margin-top: 20px;
    font-size: 20px};
  }
`;
