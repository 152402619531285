import CamOffIcon from "./images/camOff.png";
import CamOnIcon from "./images/camOn.png";
import MicOnIcon from "./images/micOn.png";
import MicOffIcon from "./images/micOff.png";

export const IconBackToAppButton = ({ bgColor }: { bgColor: string }) => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0768 8.88801C17.7728 5.81998 15.012 3.57589 11.9289 3.57589H7.36342V0.890287C7.36342 0.684033 7.24525 0.496041 7.05941 0.405804C6.87249 0.316642 6.65227 0.340275 6.49113 0.470259L1.11993 4.76722C0.992091 4.87035 0.917969 5.02397 0.917969 5.18725C0.917969 5.35054 0.992091 5.50415 1.11885 5.60728L6.49006 9.90425C6.58781 9.98159 6.70706 10.0213 6.8263 10.0213C6.90579 10.0213 6.98528 10.0042 7.05941 9.9687C7.24525 9.87846 7.36342 9.69047 7.36342 9.48422V6.79861H12.0428C13.4479 6.79861 14.7284 7.8084 14.8691 9.20599C15.0314 10.8131 13.7713 12.1698 12.1975 12.1698H7.90054C7.60405 12.1698 7.36342 12.4105 7.36342 12.7069V14.8554C7.36342 15.1519 7.60405 15.3925 7.90054 15.3925H12.1975C15.649 15.3925 18.4238 12.4094 18.0768 8.88801Z"
        fill={bgColor}
      />
    </svg>
  );
};

export const IconCommentButton = ({ bgColor }: { bgColor: string }) => {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5109 18.248C22.4895 18.2359 20.5065 17.1107 19.0985 15.555C20.0944 13.973 20.6184 12.1571 20.6184 10.2716C20.6184 7.61704 19.5846 5.12129 17.7076 3.24422C15.8305 1.36731 13.3347 0.333496 10.6803 0.333496C8.02573 0.333496 5.52999 1.36731 3.65308 3.24422C1.776 5.12129 0.742188 7.61704 0.742188 10.2716C0.742188 12.926 1.776 15.4218 3.65308 17.2989C5.52999 19.1759 8.02573 20.2096 10.6803 20.2096C12.3746 20.2096 14.0338 19.7793 15.5042 18.962C18.4019 20.573 22.2069 19.4919 22.3738 19.4432C22.6259 19.3695 22.8093 19.152 22.8392 18.8911C22.8692 18.6301 22.7399 18.3769 22.5109 18.248ZM15.8664 17.6572C15.6558 17.5196 15.3847 17.5155 15.1698 17.6466C13.8212 18.4694 12.2687 18.9045 10.6803 18.9045C5.92017 18.9045 2.04731 15.0318 2.04731 10.2716C2.04731 5.51131 5.92017 1.63862 10.6803 1.63862C15.4405 1.63862 19.3132 5.51131 19.3132 10.2716C19.3132 12.058 18.7721 13.7723 17.748 15.2292C17.5806 15.4675 17.5912 15.788 17.7743 16.0145C18.5846 17.0176 19.63 17.8722 20.4625 18.4701C19.1638 18.6117 17.2963 18.5905 15.8664 17.6572Z"
        fill={bgColor}
      />
      <path
        d="M15.1023 12.5195H6.25412C5.89361 12.5195 5.60156 12.8117 5.60156 13.1721C5.60156 13.5324 5.89361 13.8247 6.25412 13.8247H15.1023C15.4626 13.8247 15.7549 13.5324 15.7549 13.1721C15.7549 12.8117 15.4626 12.5195 15.1023 12.5195Z"
        fill={bgColor}
      />
      <path
        d="M15.1023 9.61865H6.25412C5.89361 9.61865 5.60156 9.91087 5.60156 10.2714C5.60156 10.6317 5.89361 10.9239 6.25412 10.9239H15.1023C15.4626 10.9239 15.7549 10.6317 15.7549 10.2714C15.7549 9.91087 15.4626 9.61865 15.1023 9.61865Z"
        fill={bgColor}
      />
      <path
        d="M15.1023 6.71826H6.25412C5.89361 6.71826 5.60156 7.01048 5.60156 7.37082C5.60156 7.73133 5.89361 8.02338 6.25412 8.02338H15.1023C15.4626 8.02338 15.7549 7.73133 15.7549 7.37082C15.7549 7.01048 15.4626 6.71826 15.1023 6.71826Z"
        fill={bgColor}
      />
    </svg>
  );
};

export const IconVideoButton = ({ bgColor }: { bgColor: string }) => {
  return (
    <svg
      width="27"
      height="16"
      viewBox="0 0 27 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.947687 1.88235C0.948624 1.23294 1.47464 0.706925 2.12405 0.705988H16.9233C17.5727 0.706925 18.0989 1.23316 18.0997 1.88267V13.62C18.0989 14.2694 17.5729 14.7955 16.9237 14.7967C16.9235 14.7967 16.9232 14.7967 16.923 14.7967H2.12437C2.12415 14.7967 2.12393 14.7967 2.12372 14.7967C1.47445 14.7956 0.948624 14.2696 0.947687 13.6203V1.88235Z"
        stroke={bgColor}
        strokeWidth="1.21569"
      />
      <path
        d="M20.8228 10.006C20.6277 9.89948 20.5063 9.69487 20.5063 9.47252V6.06711C20.5063 5.84476 20.6277 5.64015 20.8228 5.5336L24.5215 3.5143C24.9265 3.29315 25.4206 3.58631 25.4206 4.04781V11.4918C25.4206 11.9533 24.9265 12.2465 24.5215 12.0253L20.8228 10.006Z"
        stroke={bgColor}
        strokeWidth="1.21569"
      />
    </svg>
  );
};

export const SendIconButton = ({ bgColor }: { bgColor: string }) => {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={bgColor}
        d="M22.9257 0.794751C23.0348 0.286914 22.5369 -0.141288 22.0513 0.0440318L0.984278 8.0862C0.730731 8.18301 0.563026 8.42602 0.562501 8.6974C0.561977 8.96883 0.728721 9.21249 0.981918 9.31027L6.90003 11.5959V18.7951C6.90003 19.0988 7.10856 19.3627 7.40402 19.433C7.69752 19.5028 8.00356 19.3631 8.14115 19.0902L10.5888 14.233L16.562 18.666C16.9254 18.9356 17.4476 18.7641 17.5799 18.3308C23.1569 0.0454748 22.9158 0.840687 22.9257 0.794751ZM17.7254 3.09892L7.46272 10.4076L3.04705 8.70225L17.7254 3.09892ZM8.21126 11.4843L17.1568 5.11361C9.45928 13.234 9.8613 12.8066 9.82773 12.8518C9.77786 12.9189 9.91449 12.6574 8.21126 16.0373V11.4843ZM16.5969 17.059L11.339 13.157L20.8459 3.12777L16.5969 17.059Z"
        fill={bgColor}
      />
    </svg>
  );
};

export const ChatIconButton = ({ bgColor }: { bgColor: string }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3278 3.98779H3.89541C3.59952 3.98779 3.35938 4.22794 3.35938 4.52382C3.35938 4.81971 3.59952 5.05986 3.89541 5.05986H10.3278C10.6237 5.05986 10.8638 4.81971 10.8638 4.52382C10.8638 4.22794 10.6237 3.98779 10.3278 3.98779Z"
        fill={bgColor}
      />
      <path
        d="M8.18366 6.13281H3.89541C3.59952 6.13281 3.35938 6.37295 3.35938 6.66884C3.35938 6.96473 3.59952 7.20488 3.89541 7.20488H8.18366C8.47955 7.20488 8.71969 6.96473 8.71969 6.66884C8.71969 6.37295 8.47955 6.13281 8.18366 6.13281Z"
        fill={bgColor}
      />
      <path
        d="M11.9372 0.772461H2.28866C1.10617 0.772461 0.144531 1.7341 0.144531 2.91659V13.6372C0.144531 13.8452 0.264602 14.035 0.453285 14.1229C0.525114 14.1561 0.603374 14.1732 0.680563 14.1732C0.80385 14.1732 0.926065 14.1304 1.02362 14.0489L4.09079 11.4931H11.9372C13.1197 11.4931 14.0813 10.5315 14.0813 9.34896V2.91659C14.0813 1.7341 13.1197 0.772461 11.9372 0.772461ZM13.0093 9.34896C13.0093 9.93967 12.529 10.421 11.9372 10.421H3.89675C3.77132 10.421 3.65018 10.465 3.55369 10.5454L1.21659 12.4933V2.91659C1.21659 2.32588 1.69688 1.84452 2.28866 1.84452H11.9372C12.529 1.84452 13.0093 2.32588 13.0093 2.91659V9.34896Z"
        fill={bgColor}
      />
      <path
        d="M15.1542 5.06055C14.8583 5.06055 14.6182 5.30069 14.6182 5.59658C14.6182 5.89247 14.8583 6.13261 15.1542 6.13261C15.746 6.13261 16.2263 6.61397 16.2263 7.20467V16.2733L14.4166 14.826C14.3223 14.7509 14.2033 14.7091 14.0822 14.7091H6.57772C5.98594 14.7091 5.50566 14.2278 5.50566 13.637V13.101C5.50566 12.8051 5.26551 12.565 4.96963 12.565C4.67374 12.565 4.43359 12.8051 4.43359 13.101V13.637C4.43359 14.8195 5.39523 15.7812 6.57772 15.7812H13.8935L16.4268 17.8084C16.5243 17.8856 16.6433 17.9253 16.7623 17.9253C16.8406 17.9253 16.9199 17.9081 16.995 17.8728C17.1804 17.7827 17.2983 17.5951 17.2983 17.3893V7.20467C17.2983 6.02219 16.3367 5.06055 15.1542 5.06055Z"
        fill={bgColor}
      />
    </svg>
  );
};

export const ChatCloseIconButton = () => {
  return (
    <svg
      version="1.1"
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 18 18"
      width="18px"
      fill="#d02a3e"
    >
      <path d="M10.3,4H3.9C3.6,4,3.4,4.2,3.4,4.5c0,0.3,0.2,0.5,0.5,0.5h6.4c0.3,0,0.5-0.2,0.5-0.5C10.9,4.2,10.6,4,10.3,4z" />
      <path d="M8.2,6.1H3.9c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h4.3c0.3,0,0.5-0.2,0.5-0.5S8.5,6.1,8.2,6.1z" />
      <path
        d="M11.9,0.8H2.3c-1.2,0-2.1,1-2.1,2.1v10.7c0,0.2,0.1,0.4,0.3,0.5c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1
	l3.1-2.6h7.8c1.2,0,2.1-1,2.1-2.1V2.9C14.1,1.7,13.1,0.8,11.9,0.8z M13,9.3c0,0.6-0.5,1.1-1.1,1.1h-8c-0.1,0-0.2,0-0.3,0.1l-2.3,1.9
	V2.9c0-0.6,0.5-1.1,1.1-1.1h9.6c0.6,0,1.1,0.5,1.1,1.1V9.3z"
      />
      <path
        d="M15.2,5.1c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5c0.6,0,1.1,0.5,1.1,1.1v9.1l-1.8-1.4
	c-0.1-0.1-0.2-0.1-0.3-0.1H6.6c-0.6,0-1.1-0.5-1.1-1.1v-0.5c0-0.3-0.2-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5v0.5c0,1.2,1,2.1,2.1,2.1
	h7.3l2.5,2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2-0.1c0.2-0.1,0.3-0.3,0.3-0.5V7.2C17.3,6,16.3,5.1,15.2,5.1z"
      />
    </svg>
  );
};

export const CamOnIconButton = () => {
  return <img src={CamOnIcon} aria-hidden={true} alt="" />;
};

export const CamOffIconButton = () => {
  return <img src={CamOffIcon}  aria-hidden={true} alt="" />;
};

export const MicOnIconButton = () => {
  return <img src={MicOnIcon}  aria-hidden={true} alt=""/>;
};

export const MicOffIconButton = () => {
  return <img src={MicOffIcon} aria-hidden={true} alt="" />;
};

export const MicSwapIconButton = () => {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1704 10.1252V7.896L5.43359 12.6332C5.91965 12.9644 6.50621 13.1584 7.13724 13.1584C8.80978 13.1584 10.1704 11.7978 10.1704 10.1252Z"
        fill="#D02A3E"
      />
      <path
        d="M12.0134 8.31152H11.4092C11.2152 8.31152 11.0578 8.46897 11.0578 8.66297V9.89814C11.0578 12.0595 9.29929 13.8182 7.13771 13.8182C6.31989 13.8182 5.55988 13.566 4.93078 13.136L4.00911 14.0577C4.71851 14.5996 5.56532 14.9674 6.48436 15.0831V15.9582H3.787C3.593 15.9582 3.43555 16.1156 3.43555 16.3096V16.9134C3.43555 17.1074 3.593 17.2648 3.787 17.2648H10.4888C10.6828 17.2648 10.8402 17.1074 10.8402 16.9134V16.3096C10.8402 16.1156 10.6828 15.9582 10.4888 15.9582H7.79122V15.0831C10.3557 14.7601 12.3646 12.4825 12.3646 9.89814V8.66297C12.3648 8.4688 12.2074 8.31152 12.0134 8.31152Z"
        fill="#D02A3E"
      />
      <path
        d="M13.7517 1.63034C13.3746 1.25306 12.7622 1.25306 12.3851 1.63034L10.1709 3.84447V3.31167C10.1709 1.63895 8.81031 0.27832 7.13759 0.27832C5.46487 0.27832 4.10423 1.63895 4.10423 3.31167V9.91082L3.30434 10.7107C3.24863 10.4484 3.21753 10.1769 3.21753 9.89817V8.663C3.21753 8.469 3.06008 8.31155 2.86608 8.31155H2.26212C2.06812 8.31155 1.91067 8.469 1.91067 8.663V9.89817C1.91067 10.5431 2.03825 11.1681 2.26598 11.7491L0.523326 13.4916C0.14587 13.869 0.14587 14.4811 0.523326 14.8585C0.711878 15.0471 0.959298 15.1414 1.20672 15.1414C1.45414 15.1414 1.70138 15.0471 1.89011 14.8585L13.7515 2.99713C14.1293 2.61967 14.1293 2.0078 13.7517 1.63034Z"
        fill="#D02A3E"
      />
    </svg>
  );
};

export const PersonIconButton = ({ bgColor }: { bgColor: string }) => {
  return (
    <>
      <svg
        width="16px"
        viewBox="0 0 17 17"
        fill="#ccc"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.2259 11.1076C13.3183 10.2 12.2379 9.52806 11.0592 9.12132C12.3216 8.25182 13.151 6.79665 13.151 5.1512C13.151 2.49473 10.9898 0.333496 8.33333 0.333496C5.67686 0.333496 3.51562 2.49473 3.51562 5.1512C3.51562 6.79665 4.34502 8.25182 5.60749 9.12132C4.42878 9.52806 3.34844 10.2 2.44079 11.1076C0.866829 12.6816 0 14.7743 0 17.0002H1.30208C1.30208 13.1231 4.45628 9.96891 8.33333 9.96891C12.2104 9.96891 15.3646 13.1231 15.3646 17.0002H16.6667C16.6667 14.7743 15.7998 12.6816 14.2259 11.1076ZM8.33333 8.66683C6.39482 8.66683 4.81771 7.08975 4.81771 5.1512C4.81771 3.21266 6.39482 1.63558 8.33333 1.63558C10.2718 1.63558 11.849 3.21266 11.849 5.1512C11.849 7.08975 10.2718 8.66683 8.33333 8.66683Z"
          fill={bgColor}
        />
      </svg>
    </>
  );
};

export const FileIcon = ({ bgColor }: { bgColor?: string }) => {
  return (
    <>
      <svg
        width="12"
        height="23"
        viewBox="0 0 12 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.55503 0.162109C2.53763 0.162109 0.0820312 2.8247 0.0820312 6.0983V17.3368C0.0820312 17.738 0.381321 18.0626 0.75128 18.0626C1.12099 18.0626 1.42028 17.738 1.42028 17.3368V6.0983C1.42028 3.62554 3.27473 1.61365 5.55503 1.61365C7.83556 1.61365 9.68997 3.62554 9.68997 6.0983V17.6849C9.68997 19.2934 8.48309 20.6025 6.99975 20.6025C6.98873 20.6025 6.97881 20.6089 6.96782 20.6096C6.95607 20.6089 6.94688 20.6025 6.93516 20.6025C5.45194 20.6025 4.24506 19.2934 4.24506 17.6849V10.7346C4.24506 9.95074 4.83242 9.31364 5.55503 9.31364C6.2778 9.31364 6.86519 9.95074 6.86519 10.7346V17.3368C6.86519 17.738 7.16501 18.0626 7.53423 18.0626C7.90337 18.0626 8.20344 17.738 8.20344 17.3368V10.7346C8.20344 9.1506 7.01547 7.86215 5.55503 7.86215C4.09479 7.86215 2.90681 9.15064 2.90681 10.7346V17.6849C2.90681 20.0936 4.71353 22.054 6.93512 22.054C6.94684 22.054 6.95602 22.0476 6.96778 22.0469C6.97881 22.0476 6.98873 22.054 6.99971 22.054C9.22077 22.054 11.028 20.0937 11.028 17.6849V6.0983C11.028 2.8247 8.57246 0.162109 5.55503 0.162109Z"
          fill={bgColor ? bgColor : "white"}
        />
      </svg>
    </>
  );
};
