import styled from "styled-components";

export const Ballon = styled.div<{ 
  me: boolean; 
  bgColor: {  
    visitant: string;
    attendant: string;
  };
  color: string;
}>`
  float: ${({ me }) => me ? "right" : "left"};
  background-color: ${({ me, bgColor }) => me ? bgColor.visitant : bgColor.attendant };

  width: 100%;
  margin-top: 10px;
  max-width: 100px;
  display: block;
  max-width: 200px;
  padding: 10px;
  color: ${({ color }) => color};
  border-radius: ${({ me }) => me ? "26px 25px 0px 26px" : "1px 20px 20px 14px;"};
  word-break: break-word;

  a {
    color: ${(props: { me: boolean; color: string }) =>
      props.me ? props.color : "#fff"};
    text-decoration: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  svg,
  img {
    margin-right: 5px;
  }
`;

export const BallonArea = styled.div`
  width: 100%;
`;

export const AccessibilityChatArea = styled.span`
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`;
