import { IPages } from "../NewVisitant";
import { BrowserNotSupportedContainer } from "./styled";
import Video from "../../components/Video";

export const BrowserNotSupported: React.FC<IPages> = ({
  video,
  logo,
  backgroundColor,
  contrastColor,
  description,
  context,
}: IPages) => {
  return (
    <BrowserNotSupportedContainer
      pageAssets={{ backgroundColor, contrastColor }}
    >
      {context !== "desktop" && <img src={logo} alt="" aria-hidden={true} />}

      <Video
        context={context}
        hasBackgroundVideo
        url={video}
        description={description}
        client={{ logo: logo }}
      ></Video>
    </BrowserNotSupportedContainer>
  );
};
