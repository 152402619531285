import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { request } from "../../services/request";
import { clearSS, setVId } from "../../sessionstorage";
import { OutOffAttendanceHour } from "../OutOffAttendanceHour";
import { BrowserNotSupported } from "../BrowserNotSupported";
import { PreQueueForm } from "../PreQueueForm";
import { getDeviceInfos } from "../../utils";
import {setSSAudioEnable, setSSVideoEnable} from "../../sessionstorage"
import NotFound from "../NotFound";

export interface IPages {
  backgroundColor: string;
  video: string;
  logo: string;
  contrastColor: string;
  description: string;
  context: "mobile" | "desktop";
}

export interface IResponseNewVisitantOutOffAttendanceHour {
  video: string;
  logo: string;
  backgroundColor: string;
  contrastColor: string;
  videoMobile: string;
  descriptionVideo: string;
  descriptionVideoMobile: string;
}

export interface IResponseBrowserNotSupported {
  video: string;
  logo: string;
  backgroundColor: string;
  contrastColor: string;
  videoMobile: string;
  descriptionVideo: string;
  descriptionVideoMobile: string;
}

export interface IResponseNewVisitantInAttendanceHour {
  pageOptions: {
    active: boolean;
    logo: string;
    textColor: string;
    backgroundColor: string;
    contrastColor: string;
    message: string;
    formFields: Array<{
      title: string;
      key: string;
      required: string;
    }>;
  };
  vId: string;
  nextPage: string;
}

interface IResponseNewVisitant
  extends IResponseNewVisitantOutOffAttendanceHour,
    IResponseNewVisitantInAttendanceHour,
    IResponseBrowserNotSupported {
  existQueue: boolean;
}

export const NewVisitant: React.FC = () => {
  const history = useHistory();
  const [page, setPage] = useState<string>();
  const [outOffAttendanceHourPage, setOutOffAttendanceHourPage] =
    useState<IPages>({
      backgroundColor: "",
      contrastColor: "",
      video: "",
      logo: "",
      description: "",
      context: "desktop",
    });

  const [browserNotSupportedPage, setBrowserNotSupportedPage] =
    useState<IPages>({
      backgroundColor: "",
      contrastColor: "",
      video: "",
      logo: "",
      description: "",
      context: "desktop",
    });

  const [preFormPage, setPreFormPage] = useState<{
    active: boolean;
    logo: string;
    textColor: string;
    backgroundColor: string;
    contrastColor: string;
    message: string;
    formFields: Array<{
      title: string;
      key: string;
      required: string;
    }>;
  }>({
    active: false,
    logo: "",
    textColor: "",
    backgroundColor: "",
    contrastColor: "",
    message: "",
    formFields: [],
  });

  const clientData = async () => {
    let locationSearch = history.location.search.replace("?", "");
    let queryParams: any = {};
    const originLink = window.location.href;
    let nameQueue = window.location.pathname
      .toLocaleLowerCase()
      .replace("/", "");

    if (!nameQueue) {
      nameQueue = "/";
    }

    if (locationSearch !== "") {
      for (const param of locationSearch.split("&")) {
        let paramSplit = param.split("=");
        queryParams[paramSplit[0]] = paramSplit[1];
      }
    }

    const clientName = window.location.host.split(".")[0];

    const device = await getDeviceInfos();
    setSSAudioEnable('false')
    setSSVideoEnable('false')


    const response: IResponseNewVisitant = await request({
      showLoading: true,
      showSuccessMessage: false,
      showErrorMessage: false,
      method: "POST",
      path: `visitant`,
      data: {
        clientName,
        queryParams,
        device: {
          ...getDeviceInfos(),
        },
        originLink,
        nameQueue,
      },
    });

    if (!response.existQueue) {
      nameQueue = "/";
    }
    setPage(response.nextPage);
    let urlVideo = response.video;
    let descVideo = response.descriptionVideo;
    let context: "mobile" | "desktop" = "desktop";
    if (device.type === "mobile") {
      urlVideo = response.videoMobile;
      descVideo = response.descriptionVideo;
      context = "mobile";
    }

    const data = {
      backgroundColor: response.backgroundColor,
      video: urlVideo,
      logo: response.logo,
      contrastColor: response.contrastColor,
      description: descVideo,
      context: context,
    };

    if (response.nextPage === "outOffAttendanceHour") {
      setOutOffAttendanceHourPage(data);
    }
    if (response.nextPage === "notSupportedBrowser") {
      setBrowserNotSupportedPage(data);
    }

    if (response.nextPage === "preForm") {
      setPreFormPage(response.pageOptions);
      setVId(response.vId);
    }

    if (response.nextPage === "queue") {
      setVId(response.vId);

      history.push("/fila", { nameQueue });
    }
  };

  useEffect(() => {
    clearSS();
    clientData();
  }, []); // eslint-disable-line

  return (
    <Fragment>
      {page === "outOffPermission" && (
        <NotFound />
      )}
      {page === "outOffAttendanceHour" && (
        <OutOffAttendanceHour
          backgroundColor={outOffAttendanceHourPage.backgroundColor}
          video={outOffAttendanceHourPage.video}
          logo={outOffAttendanceHourPage.logo}
          contrastColor={outOffAttendanceHourPage.contrastColor}
          description={outOffAttendanceHourPage.description}
          context={outOffAttendanceHourPage.context}
        />
      )}

      {page === "notSupportedBrowser" && (
        <BrowserNotSupported
          backgroundColor={browserNotSupportedPage.backgroundColor}
          logo={browserNotSupportedPage.logo}
          video={browserNotSupportedPage.video}
          contrastColor={browserNotSupportedPage.contrastColor}
          description={browserNotSupportedPage.description}
          context={browserNotSupportedPage.context}
        />
      )}

      {page === "preForm" && (
        <PreQueueForm
          backgroundColor={preFormPage?.backgroundColor}
          formFields={preFormPage?.formFields}
          logo={preFormPage?.logo}
          message={preFormPage?.message}
          textColor={preFormPage?.textColor}
          contrastColor={preFormPage?.contrastColor}
        />
      )}
    </Fragment>
  );
};
