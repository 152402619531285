import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ToggleAudioButton } from "../../components/ToggleAudioButton";
import { ToggleVideoButton } from "../../components/ToggleVideoButton";
import { useRoomConnect } from "../../hooks/useRoomConnect";
import { request } from "../../services/request";
import { pauseRoomByAttendant, roomFinalizedByAttendant } from "../../socket";
import {
  ChatIconButton,
  ChatCloseIconButton,
} from "../../assets/icons";
import Video from "../../components/Video";
import {
  RoomContainer,
  SwitchButtonContainer,
  AreaButtons,
  OpenChatButton,
  ChatIconArea,
  ChatContainer,
  LogoClient,
} from "./styles";
import { EndCallButton } from "../../components/EndCallButton";
import { Participant as ParticipantTwilio } from "twilio-video";
import { WaitingScreen } from "../../components/WaitingScreen";
import { ChatBoxMobile } from "../../components/ChatMobile";

type IClient = {
  logo: string;
  textColor: string;
  buttonColor: string;
  backgroundColor: string;
  contrastColor: string;
  attendantName: string;
  attendantImg: string;
  showAudioButton: boolean;
};
export const GuestRoomMobile: React.FC = () => {
  const [openChat, setOpenChat] = useState<boolean>(false);
  const [openManual, setOpenManual] = useState<boolean>(false);
  const { participants, room, videoTrack, audioTrack } = useRoomConnect(); // eslint-disable-line
  const [client, setClient] = useState<IClient>();
  const [roomIsPaused, setRoomIsPaused] = useState<{
    userImg: string;
    userName: string;
    roomIsPaused: boolean;
    roleUser:string
  }>();

  const [participantIsAttendant, setParticipantIsAttendant] =
    useState<ParticipantTwilio>();
  const [participantIsVisitant, setParticipantIsVisitant] =
    useState<ParticipantTwilio>();

  const localVideoRef = useRef<any>();
  const { protocolId }: { protocolId: string } =
    useParams<{ protocolId: string }>();
  const history = useHistory();
  const chatRef = useRef<any>();
  const [videoDisabled, setVideoDisabled] = useState(false);
  const [finalizeCall, setFinalizeCall] = useState<boolean>(false);

  const clientData = async () => {
    const req = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      path: `rooms/get-assets/${protocolId}`,
    });
    if (!req.error) {
      setClient(req);
      if (!room.name) {
        history.push(`/convidado/${protocolId}`);
      }
    }
  };

  useEffect(() => {
    roomFinalizedByAttendant(() => {
      window.location.href = `/convidado-agradecimento/${protocolId}`;
    });

    clientData();

    pauseRoomByAttendant(
      (userName: string, userImg: string, roomIsPaused: boolean, roleUser:string) => {
        setRoomIsPaused({
          userImg,
          roomIsPaused,
          userName,
          roleUser
        });
      }
    );
  }, []); // eslint-disable-line

  useEffect(() => {
    if (videoTrack && videoTrack.kind) {
      videoTrack.attach(localVideoRef.current);
      let localVideoRefIntoEffect = localVideoRef.current;
      videoTrack.on("enabled", () => setVideoDisabled(false));
      videoTrack.on("disabled", () => setVideoDisabled(true));
      setVideoDisabled(!videoTrack.isEnabled);
      return () => {
        videoTrack.detach(localVideoRefIntoEffect);
      };
    }
  }, [videoTrack]);

  useEffect(() => {
    participants.forEach((p) => {
      if (p.identity.includes("visitant")) setParticipantIsVisitant(p);
      else if (p.identity.includes("attendant")) setParticipantIsAttendant(p);
    });
  }, [participants]);

  useEffect(() => {
    if (openChat && chatRef.current) {
      chatRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [openChat]);


  return (
    <Fragment>
      {roomIsPaused?.roomIsPaused && (
        <WaitingScreen
          avatar={roomIsPaused.userImg}
          name={roomIsPaused.userName}
          role={roomIsPaused.roleUser}
          textColor={client?.textColor}
          backgroundColor={client?.backgroundColor}
          contrastColor={client?.contrastColor}
        />
      )}
      <RoomContainer openChat={openChat} pageAssets={client}>
        {
          !openChat ?
            <LogoClient>
              {client && <img src={client.logo} alt="" aria-hidden={true} />}
            </LogoClient> : null
        }
        
        <Video      
          isGuest     
          hasAttendant          
          hasVisitant     
          context="mobile"
          localVideoRef={localVideoRef}
          participantIsAttendant={participantIsAttendant}
          participantIsVisitant={participantIsVisitant}
          videoDisabled={videoDisabled}
          finalizeCall={finalizeCall}
          client={client}
          openChat={openChat}
        />

        <AreaButtons>
          <SwitchButtonContainer>
            <EndCallButton type="guest" protocolId={protocolId} onFinalizeCall={setFinalizeCall} btnColor={client?.buttonColor || client?.backgroundColor || "#000"} textColor={client?.textColor || "#FFF"}/>
            <OpenChatButton
              onClick={() => {
                setOpenChat(!openChat);
                setOpenManual(() => true)
              }}
              style={{backgroundColor: client?.buttonColor || client?.backgroundColor}}
            >
              <ChatIconArea>
                {!openChat && (
                  <ChatIconButton
                    bgColor={client ? client.backgroundColor : "#000"}
                  />
                )}
                {openChat && <ChatCloseIconButton />}
              </ChatIconArea>
            </OpenChatButton>

            {client?.showAudioButton && (            
              <ToggleAudioButton
                type="circle"
                pauseRoom={roomIsPaused?.roomIsPaused}
                bgColor={client?.buttonColor || "#fff"}
              />
            )}

            <ToggleVideoButton
              type="circle"
              pauseRoom={roomIsPaused?.roomIsPaused}
              bgColor={client?.buttonColor || "#fff"}
            />
          </SwitchButtonContainer>
        </AreaButtons>
      </RoomContainer>

      {client && (
        <ChatContainer ref={chatRef}>
          <ChatBoxMobile
            className={openChat ? "chatOpen" : ""}
            toggleChat={openChat}
            pageAssets={client}
            setToggleChat={setOpenChat}
            openManual={openManual}
            setOpenManual={setOpenManual}
          />
        </ChatContainer>
      )}
    </Fragment>
  );
};
