import { useCallback, useEffect, useState } from "react";
import { request } from "../../services/request";
import { getProtocolId, getVId } from "../../sessionstorage";
import {
  addMessageIntoChat,
  chatMessages,
  fileRequestedByAttendant,
  getChatMessages,
} from "../../socket";
import {
  ChatStyled,
  ChatBar,
  ChatMessagesArea,
  FileuploadArea,
  FileIconArea,
  ToogleChat,
  AccessibilityChatArea,
} from "./styled";
import { Ballon } from "./messageBox";
import { SendIconButton, FileIcon } from "../../assets/icons";

interface ChatProps {
  toggleChat: boolean;
  setToggleChat: Function;
  className: string;
  pageAssets: any;
  openManual: boolean;
  setOpenManual: Function;
}

interface IMessages {
  type: "attendant" | "visitant";
  msg: string;
  _id: string;
  messageType: "MESSAGE" | "ATTACHMENT";
  filename?: string;
}

export const ChatBoxMobile = ({
  pageAssets,
  toggleChat,
  setToggleChat,
  openManual,
  setOpenManual,
  ...props
}: ChatProps) => {
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<Array<IMessages>>([]);
  const [showFileUpload, setShowFileUpload] = useState<boolean>(false);

  const setMessagesInFront = (chatMessages: Array<any>) => {
    setMessages(chatMessages);
    const chatDiv = document.getElementById("chat") as HTMLDivElement;
    if (chatDiv) chatDiv.scrollTop = chatDiv.scrollHeight;
  };

  const handleSendFile = useCallback(async (file: File) => {
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        try {
          const response = await request({
            showLoading: true,
            timeout: 99999,
            showSuccessMessage: false,
            method: "POST",
            path: `rooms/room/send-file/${getProtocolId()}`,
            data: { file: reader.result, filename: file.name },
          });

          if (!response.error) {
            addMessageIntoChat(
              "visitant",
              getProtocolId() ?? "",
              response,
              getVId() ?? "",
              "ATTACHMENT",
              (chatMessages: Array<any>) => {
                setMessagesInFront(chatMessages);
              },
              file.name
            );
            setShowFileUpload(false);
          }
        } catch (e) {}
      };
    }
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (message === "") return;
    addMessageIntoChat(
      "visitant",
      getProtocolId() ?? "",
      message,
      getVId() ?? "",
      "MESSAGE",
      (chatMessages: Array<any>) => {
        setMessagesInFront(chatMessages);
      }
    );
    setMessage("");
  };

  useEffect(() => {
    const handleEnterKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit(event);
      }
    };

    document.addEventListener("keydown", handleEnterKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [message]);

  useEffect(() => {
    chatMessages((chatMessages: Array<any>) => {
      setMessagesInFront(chatMessages);
      setToggleChat(true);
      setOpenManual(() => false);
    });

    getChatMessages((chatMessages: Array<any>) => {
      setMessagesInFront(chatMessages);
    });

    fileRequestedByAttendant(() => {
      setShowFileUpload(true);
      setToggleChat(true);
      setOpenManual(() => false);
    });
  }, []); //eslint-disable-line

  useEffect(() => {
    if (toggleChat) {
      const chatDiv = document.getElementById(
        "room-container"
      ) as HTMLDivElement;
      if (chatDiv) chatDiv.scrollTop = chatDiv.scrollHeight;
    }
  }, [toggleChat]);

  return (
    <ChatStyled toggleChat={toggleChat}>
      <ToogleChat
        onClick={() => {
          setToggleChat(!toggleChat);
          setOpenManual(() => false);
        }}
        tabIndex={0}
        aria-label="Minimizar chat"
      />

      {messages ? (
        <AccessibilityChatArea
          aria-live="assertive"
          tabIndex={0}
          aria-label={messages[messages.length - 1]?.msg}
          role="dialog"
        >
          {messages[messages.length - 1]?.messageType === "MESSAGE"
            ? messages[messages.length - 1]?.type === "visitant"
              ? "Eu: " + messages[messages.length - 1]?.msg
              : pageAssets?.attendantName +
                ": " +
                messages[messages.length - 1]?.msg
            : "Anexo enviado"}
        </AccessibilityChatArea>
      ) : null}

      <div aria-live="assertive">
        <span
          style={{ color: "#1a1422" }}
          aria-label={"Teste: " + messages[messages.length - 1]?.msg}
        >
          {"livre: " + messages[messages.length - 1]?.msg}
        </span>
      </div>

      <ChatMessagesArea id="chat" showFileUpload={showFileUpload}>
        {messages.map((msg, index) => {
          return (
            <Ballon
              txtColor={pageAssets?.textColor}
              bgColor={{
                visitant:
                  pageAssets?.chatBalloonColor || pageAssets?.backgroundColor,
                attendant:
                  pageAssets?.attendantChatBalloonColor ||
                  pageAssets?.backgroundColor,
              }}
              key={msg._id}
              type={msg.type}
              msg={msg.msg}
              _id={msg._id}
              messageType={msg.messageType}
              index={index}
              totalMsg={messages.length - 1}
              attendantName={pageAssets?.attendantName}
              filename={msg?.filename}
            />
          );
        })}
      </ChatMessagesArea>

      {showFileUpload && (
        <FileuploadArea
          htmlFor="file"
          aria-live="assertive"
          tabIndex={0}
          role="alert"
        >
          <FileIconArea bgColor={pageAssets?.backgroundColor}>
            <FileIcon bgColor={pageAssets?.textColor || "#FFF"} />
          </FileIconArea>
          <span color={pageAssets?.textColor || "#FFF"}>
            Adicionar um anexo
          </span>
          <input
            type="file"
            name="file"
            id="file"
            onChange={(e: any) => {
              handleSendFile(e.target.files[0]);
            }}
            aria-hidden={true}
            autoFocus={showFileUpload}
          />
        </FileuploadArea>
      )}
      {pageAssets && toggleChat && (
        <ChatBar bgColor={pageAssets?.backgroundColor}>
          <input
            type="text"
            value={message}
            placeholder="Digite sua mensagem..."
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            autoFocus={toggleChat && openManual}
            tabIndex={0}
          />
          <button
            onTouchEnd={(e) => handleSubmit(e)}
            style={{
              backgroundColor:
                pageAssets?.buttonColor ||
                pageAssets?.backgroundColor ||
                "#FFF",
            }}
            onClick={handleSubmit}
            aria-label="enviar mensagem"
          >
            <SendIconButton bgColor={pageAssets?.iconColor || "#FFF"} />
          </button>
        </ChatBar>
      )}
    </ChatStyled>
  );
};
