import styled, { css } from "styled-components";
import iconUser from "../../assets/images/user-video.svg";

export const QueueContainer = styled.div`
  ${(props: { pageAssets?: any }) =>
    props.pageAssets && props.pageAssets.backgroundColor
      ? css`
          background: linear-gradient(
            180deg,
            ${props.pageAssets.backgroundColor} -18.72%,
            ${props.pageAssets.contrastColor || '#0a070f'} 75.27%
          );
        `
      : css`
          background: linear-gradient(180deg, #2c1050 -18.72%, #0a070f 75.27%);
        `}

  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 768px) and (min-height: 720px) {
    justify-content: space-evenly;
    height: 100%;

    .logo-client {
      width: 80px;
      margin: 10% 0px -20% 0px
    }
  }
  @media (max-height: 768px) {
    justify-content: flex-start;    
    overflow-y: scroll;        
  }
`;


export const VideoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 55vw;
  max-width: 700px;
  position: relative;  
`;

export const LocalVideo = styled.div`
  position: absolute;
  width: 26%;
  opacity: 0.9;
  left: 15px;
  border-radius: 10px;
  top: 15px;
  video {    
    height: 100%;
    width: 100%;
    border-radius: 10px;
    transform: scaleX(-1);
    object-fit: fill;    
  }

  @media (max-width: 768px) {    
    width: 40%;
    top: 10px;
    left: 25px;    
  }
`;

export const LocalVideoLoading = styled.div`
  position: absolute;
  width: 26%;
  top: 15px;
  left: 15px;
  background: #b2b2b4 url(${iconUser}) no-repeat center center;
  display: flex;
  opacity: 0.9;
  border-radius: 10px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
  position: relative;
  height: auto;
  align-content: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  .queue-position-text {      
    margin: 0;
  }
  
  @media (min-height: 1000px) {
    margin-top: -50px;
  }

  @media (max-width: 768px) and (min-height: 720px) {
    align-items: flex-start;
    margin-top: 25px;

    .btn-enter {
      grid-row: row 2;
      grid-column: span 2;
      margin: 0px auto;
      margin-top: 0px;
    }
    .queue-position-text {      
      font-size: 10px;
    }
  }
  @media (max-width: 768px) and (max-height: 700px) {
    align-items: flex-start;
    margin-top: 16px;

    .btn-enter {
      margin-top: 0px;
    }
    .queue-position-text {
      font-size: 10px;
    }
  }
  @media (max-width: 280px) {
    margin-top: 10px;
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const EnterInRoomButton = styled.button`
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  outline: none;
  background-color: ${(props: { pageAssets?: any }) => props.pageAssets?.backgroundColor};
  margin-top: 0px;
  margin-bottom: 0px;
  border: 1px solid ${(props: { pageAssets?: any }) => props.pageAssets?.borderColor};

  span {
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
    color: ${(props: { pageAssets?: any }) => props.pageAssets?.textColor};
    //https: ; //assets.inclusao.pessoalize.com/all/videos/fila-desktop.gif
  }
  @media (max-width: 768px) {
    display: block;
    width: 40%;
    min-width: 100px;
    span {
      font-size: 12px;
    }
  }
  @media (max-width: 280px) {
    width: 60%; 
  }
  &:hover {
    background-color:  ${(props: { pageAssets?: any }) => props.pageAssets?.backgroundColor};
    svg {
      path {
        fill: #fff;
      }
    }
  }
  #spinner {
    min-width: 15px;
    min-height: 15px;
    border: 3px solid ${(props: { pageAssets?: any }) => props.pageAssets?.backgroundColor};
    border-right: 3px solid orange;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
    max-width: 15px;
    margin-left: 6px;
  }

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &:disabled {
    background: #fff;
    color: black;
  }
`;

export const VideoDisabledIconContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;

  min-width: ${({ props }: { props: any }) => props.width + "px"};
  min-height: ${({ props }: { props: any }) => props.height + "px"};
  img {
    width: auto;
  }
`;

export const AccessibilityArea = styled.p`
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`;


export const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) and (min-height: 720px) {
    margin-top: 0px;

    .wait-text {
      font-size: 10px;
      margin-top: 5px;
    }
  }
  @media (max-width: 768px) and (max-height: 700px) {
    margin-top: 0px;

    .wait-text {
      font-size: 10px;
      margin-top: 5px;
    }
  }
`;

export const AttendantTime = styled.p<{ bold?: boolean }>`
  color: white;
  font-size: 14px;
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
  text-align: center;
`;

export const QueuePositionP = styled.div`
  background: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: bold;
`;