import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { request } from "../../services/request";
import { QueueContainer } from "./styled";

interface IGuestPageAssets {
  apresentationVideoUrl: string;
  backgroundColor: string;
  logo: string;
  textColor: string;
  contrastColor: string;
  buttonColor: string;
  guests: Array<string>;
};

export const GuestNps: React.FC = () => {
  const [pageAssets, setPageAssets] = useState<IGuestPageAssets>();
  const params: any = useParams();

  const clientData = async () => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      path: `rooms/get-guest-assets/${params.protocolId}`,
    });

    if (!response.error) {
      setPageAssets(response);
    }
  };

  useEffect(() => {
    clientData();
  }, []); // eslint-disable-line

  return (
    <Fragment>
      {pageAssets && (
        <QueueContainer pageAssets={pageAssets}>
          <img src={pageAssets.logo} alt="logo" className="logo-client" />
          <h1>Obrigado</h1>

          <div />
        </QueueContainer>
      )}
    </Fragment>
  );
};
