import React from 'react';
import { NotFoundWrapper } from './styles';

const NotFound: React.FC = () => {
  return (
    <NotFoundWrapper>
      <h1>404</h1>
      <h2>Page not found</h2>
    </NotFoundWrapper>
  );
}

export default NotFound;