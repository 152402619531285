import styled from "styled-components";

export const VideoContainer = styled.div<{ isFullScreen?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.5s linear;
  position: relative;
  width: 60%;
  height: auto;

  div[id*="-visitant"] {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
  }
`;

export const VideoContent = styled.div<{ openChat?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: auto;
  width: 100%;
  position: relative;
  .video-content {
    width: 100%;
    height: auto;
    object-fit:cover;
    border-radius: 22px;
  }

  @media (max-height: 600px) {
    .video-content {
      max-height: 300px;
      width: auto;
    } 
  }
`;

export const PlayButton = styled.button<{ isPlaying: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    display: ${props => props.isPlaying ? 'none' : 'flex'};
    position: absolute;
    background: none;
    border: none;
    z-index: 999;
`;

export const RoomHeader = styled.div<{ openChat?: boolean; color: string }>`
  text-align: center;
  z-index: 9;
  color: ${({ color }: { color: string }) => color};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100px;
  margin: 10px 0px;

  img {
    max-height: 90px;
  }
  & > div {
    margin-bottom: 30px;
    span {
      font-size: 14px;
      letter-spacing: 0.5px;
    }
    p {
      margin: 0;
      font-size: 21px;
      font-weight: bold;
      line-height: 14px;
    }
  }

  @media (max-height: 600px) {
    height: 80px;

    img {
      max-height: 60px;
    } 
  }
`;

export const LocalVideoArea = styled.div<{ openChat?: boolean; }>`
  position: absolute;
  width: 20%;
  height: 20%;
  border-radius: 8px;
  z-index: 200;
  top: 15px;
  left: 16px;

  video {
    transform: scaleX(-1);
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .icon-avatar {
    width: 100%;
    height: fit-content;
    background-color: #ccc;
    z-index: 1500;
    border-radius: 10px;
    top: 0;
    position: absolute;
  }
`;

export const GuestVideoArea = styled.div<{ openChat?: boolean; }>`
  position: absolute;
  border-radius: 8px;
  z-index: -1;
  top: 15px;
  right: 16px;
  width: 100%;
  height: 100%;
  max-width: 20%;
  max-height: 20%;

  video {
    transform: scaleX(-1);
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;

    :disabled {
      background-color: #fff;
    }
  }

  .icon-avatar {
    width: 100%;
    height: fit-content;
    background-color: #ccc;
    z-index: 1500;
    border-radius: 10px;
    top: 0;
    position: absolute;
  }
`;

export const AttendantVideoArea = styled.div<{ openChat?: boolean }>`
  border-radius: 10px;
  margin-bottom: 15px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-width: 600px;
  min-height: 400px;
  max-height: 600px;
  video {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
    transform: scaleX(-1);
    border-radius: 15px;
  }
`;

export const VideoDisabledIconContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;

  min-width: ${({ props }: { props: any }) => props.width + "px"};
  min-height: ${({ props }: { props: any }) => props.height + "px"};
  img {
    width: auto;
  }
`;

export const AccessibilityChatArea = styled.p`
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
`;
