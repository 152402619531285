import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToggleAudioButton } from "../../components/ToggleAudioButton/index";
import { ToggleVideoButton } from "../../components/ToggleVideoButton/index";
import { Countdown } from "../../components/Countdown";

import { useRoomConnect } from "../../hooks/useRoomConnect";
import { request } from "../../services/request";
import { getProtocolId, getVId, setProtocolId } from "../../sessionstorage";
import {
  addVisitant,
  connectedInSocket,
  enterInRoom,
  matchVisitant,
  socket,
  updateSocketId,
  getVisitantPosition,
  backInRoom,
  visitantGoToQueueAgain,
  getVisitantsQueue,
} from "../../socket";
import useTimeout from "../../hooks/useTimeout";
import { verifyIsMobileOrDesktop, sleep, isNubank, isMercadoLivre } from "../../utils";
import {
  ButtonContainer,
  EnterInRoomButton,
  QueueContainer,
  VideoContent,
  CenterDiv,
  AccessibilityArea,
  ToggleContainer,
  QueuePositionP,
  AttendantTime,
} from "./styled";
import Video from "../../components/Video";
import { useLocation } from "react-router-dom";

interface IVideoProps {
  apresentationVideoUrl: string;
  url: string;
  fileName: string;
  description: string;
}

interface IQueueVideos {
  openCamPolite: IVideoProps;
  openCamTimeout: IVideoProps;
  onQueue: IVideoProps;
  onMatch: IVideoProps;
  onMatchWaiting: IVideoProps;
  onMatchRefused: IVideoProps;
}

interface IQueueAssets {
  apresentationVideoUrl: string;
  backgroundColor: string;
  logo: string;
  textColor: string;
  buttonColor: string;
  descriptionVideo: string;
  videoMobile: string;
  descriptionVideoMobile: string;
  desktopVideos: IQueueVideos;
  showAudioButton: boolean;
}

interface ILocation {
  nameQueue: string;
}

export const Queue: React.FC = () => {
  const { state } = useLocation<ILocation>();
  const { connectRoom, videoTrack, audioTrack } = useRoomConnect();
  const protocolIdAlreadyExist = getProtocolId();
  const [queueAssets, setQueueAssets] = useState<IQueueAssets>();
  const [queueLength, setQueueLength] = useState<number>();
  const [goToRoom, setGoToRoom] = useState<boolean>(false);
  const [roomProtocol, setRoomProtocol] = useState<string>("");
  const [loaderButton, setLoaderButton] = useState<boolean>(false);
  const localVideoRef = useRef<any>();
  const [visitantPosition, setVisitantPosition] = useState<number>(0);
  const [videoDisabled, setVideoDisabled] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [timerCowndown, setTimeCowndown] = useState("");
  const [urlVideo, setUrlVideo] = useState<string | undefined>("");
  const [textVideo, setTextVideo] = useState<string | undefined>("");
  const [goToQueue, setGoToQueue] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState("Continuar");
  const [loadingCamera, setLoadingCamera] = useState<boolean>(false);
  const { createTimeout, cancelTimeout } = useTimeout();
  const history = useHistory();

  const clientData = async () => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      path: `visitant/${getVId()}`,
    });

    if (!response.error) {
      setQueueAssets(response);
      document.title = response.tabTitle || "Em libras";
      let link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/webp';
      link.rel = 'shortcut icon';
      link.href = response?.favicon || 
      "https://media-exp1.licdn.com/dms/image/C4D0BAQEMEcYEveOkgw/company-logo_200_200/0/1642007802644?e=2147483647&v=beta&t=HngPWnAjnmn_rL0IcCRHQ_ZJMQU1SMocqtvo9Eh1xzs";
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  };

  const saveIP = () => request({
    showSuccessMessage: false,
    method: "POST",
    path: "visitant/save-ip",
    data: {
      visitantId: getVId(),
    }
  });

  const handleEnterInRoom = async () => {
    let vId = getVId();

    if (goToQueue) {
      setGoToQueue(false);
      setGoToRoom(false);
      setRoomProtocol("");
      addVisitant(state?.nameQueue, getVisitantPositionInSocket);
      return;
    }

    if (protocolIdAlreadyExist) {
      setProtocolId(protocolIdAlreadyExist);
      try {
        setLoaderButton(true);
        await connectRoom({
          roomId: protocolIdAlreadyExist,
          type: "visitant",
        });

        if (vId) {
          backInRoom(vId, () => {});
        }

        setLoaderButton(false);
        history.replace(`/sala`, { audioEnabled });

        return;
      } catch (e) {
        history.push(`/`);
        return;
      }
    }

    if (vId) {
      setLoaderButton(true);
      enterInRoom(roomProtocol, vId, async (response: boolean) => {
        if (!response) {
          setLoaderButton(false);
          return;
        }
        try {
          await connectRoom({
            roomId: roomProtocol,
            type: "visitant",
          });
          setProtocolId(roomProtocol);
          setLoaderButton(false);
          history.replace(`/sala`, { audioEnabled });
        } catch {
          setLoaderButton(false);
          history.push(`/`);
        }
      });
    }
  };

  const getVisitantPositionInSocket = () => {
    setLoadingCamera(false);
    getVisitantPosition(state?.nameQueue, async (index: number) => {
      if (index) {
        setVisitantPosition(index);
        await sleep(1000);
        getVisitantPositionInSocket();
      }
    });
  };

  useEffect(() => {
    if (visitantPosition > 0 && !goToRoom) {
      setUrlVideo(() => queueAssets?.desktopVideos?.onQueue?.url);
      setTextVideo(() => queueAssets?.desktopVideos?.onQueue?.description);
    }
  }, [visitantPosition, goToRoom, queueAssets]);

  useEffect(() => {
    if (goToRoom) {
      setUrlVideo(() => queueAssets?.desktopVideos?.onMatch?.url);
      setTextVideo(() => queueAssets?.desktopVideos?.onMatch?.description);
      createTimeout(() => {
        setUrlVideo(queueAssets?.desktopVideos?.onMatchWaiting?.url);
        setTextVideo(queueAssets?.desktopVideos?.onMatchWaiting?.description);
      }, 20000);
    }

    return () => {
      cancelTimeout();
    };
  }, [goToRoom,queueAssets, cancelTimeout, createTimeout]);

  useEffect(() => {
    if (queueAssets) {
      setUrlVideo(() => queueAssets?.desktopVideos?.openCamPolite?.url);
      setTextVideo(
        () => queueAssets?.desktopVideos?.openCamPolite?.description
      );

      createTimeout(() => {
        setUrlVideo(queueAssets?.desktopVideos?.openCamTimeout?.url);
        setTextVideo(queueAssets?.desktopVideos?.openCamTimeout?.description);
      }, 60000);
    }
    return () => {
      cancelTimeout();
    };
  }, [queueAssets, cancelTimeout, createTimeout]);

  useEffect(() => {
    if (socket.id) {
      updateSocketId();
    } else {
      connectedInSocket();
    }
    clientData();
    socket.removeListener("MATCH_VISITANT");
    socket.removeListener("GO_TO_QUEUE_AGAIN");

    matchVisitant((room: string, timeAttendant: string) => {
      setButtonText("Continuar");
      setGoToQueue(false);
      setGoToRoom(true);
      setRoomProtocol(room);
      setTimeCowndown(timeAttendant);
    });

    visitantGoToQueueAgain(() => {
      setButtonText("Voltar a Fila");
      setUrlVideo(queueAssets?.desktopVideos.onMatchRefused.url);
      setTextVideo(queueAssets?.desktopVideos.onMatchRefused.description);
      setGoToQueue(true);
    });

    let protocolId = getProtocolId();
    if (protocolId) {
      setGoToRoom(true);
    }
  }, []); // eslint-disable-line

  const handleCancelCameraTimeout = (videoEnabled: boolean) => {
    if (!goToRoom && videoEnabled) {
      cancelTimeout();
    }
  };

  useEffect(() => {
    if (videoTrack && videoTrack.kind) {
      videoTrack.attach(localVideoRef.current);
      let localVideoRefIntoEffect = localVideoRef.current;

      videoTrack.on("enabled", () => setVideoDisabled(false));
      videoTrack.on("disabled", () => setVideoDisabled(true));
      setVideoDisabled(!videoTrack.isEnabled);      

      if (!protocolIdAlreadyExist) {
        addVisitant(state?.nameQueue, getVisitantPositionInSocket);
      }      

      setLoadingCamera(true);

      getVisitantsQueue((queue: any[]) => setQueueLength(queue.length));

      return () => {
        videoTrack.detach(localVideoRefIntoEffect);
      };
    }
  }, [videoTrack]); //eslint-disable-line

  useEffect(() => {
    if (audioTrack) {
      setAudioEnabled(audioTrack.isEnabled);
    }
  }, [audioTrack]);

  useEffect(() => {
    saveIP();
  }, []);

  return (
    <Fragment>
      {queueAssets && (
        <QueueContainer pageAssets={queueAssets}>
          {isNubank() && <AttendantTime aria-hidden={true}>Horário de atendimento: 08h às 20h</AttendantTime>}

          <Video
            context="desktop"
            localVideoRef={localVideoRef}
            hasBackgroundVideo
            url={urlVideo}
            description={textVideo}
            videoDisabled={videoDisabled}
            client={{ logo: queueAssets.logo }}
          />
          {isMercadoLivre() && 
            <AttendantTime aria-hidden={true} bold>Atendimento de segunda à sexta das 08h00 às 18h00</AttendantTime>
          }
          <VideoContent>
            <ButtonContainer>
              <ToggleContainer>
              {queueAssets?.showAudioButton && (
                <ToggleAudioButton
                  data-cy="btnAudio"
                  type={
                    verifyIsMobileOrDesktop() === "desktop" ? "swipe" : "circle"
                  }
                  onClickCustom={(audioEnable) => setAudioEnabled(audioEnable)}
                />
              )}

                <ToggleVideoButton
                  onClickCustom={(videoEnabled) =>
                    handleCancelCameraTimeout(videoEnabled)
                  }
                  data-cy="btnVideo"
                  type={
                    verifyIsMobileOrDesktop() === "desktop" ? "swipe" : "circle"
                  }
                />
              </ToggleContainer>

              {goToRoom ? (
                <CenterDiv>
                  <EnterInRoomButton
                    data-cy="btnEnter"
                    className="btn-enter"
                    disabled={loaderButton}
                    onClick={handleEnterInRoom}
                    autoFocus={true}
                    aria-live="assertive"
                    aria-label={
                      loaderButton
                        ? "Aguarde"
                        : `Clique aqui para ${buttonText}`
                    }
                    pageAssets={{
                      ...queueAssets,
                      mt:
                        !protocolIdAlreadyExist && !goToQueue ? " 50px" : "0px",
                      mb: !protocolIdAlreadyExist && !goToQueue ? "5px" : "0px",
                      borderColor: queueAssets.textColor || "#fff",
                      backgroundColor:
                        queueAssets.buttonColor || queueAssets.backgroundColor,
                    }}
                  >
                    {loaderButton ? (
                      <>
                        <span
                          color={queueAssets.textColor || "#FFF"}
                          aria-hidden={true}
                        >
                          Aguarde
                        </span>
                        <div id="spinner" aria-hidden={true}></div>
                      </>
                    ) : (
                      <>
                        <span
                          color={queueAssets.textColor || "#FFF"}
                          aria-hidden={true}
                        >
                          {buttonText}
                        </span>
                      </>
                    )}
                  </EnterInRoomButton>
                </CenterDiv>
              ) : (
                videoTrack?.kind && (
                  <QueuePositionP
                    style={{
                      color: queueAssets?.textColor,
                      textAlign: "center",
                      marginTop: loadingCamera ? '12px' : 'unset',
                      backgroundColor: queueAssets?.buttonColor,
                    }}
                    aria-live="polite"
                    className="queue-position-text"
                  >
                    {queueLength !== undefined && queueLength > 0 && visitantPosition > 0
                      ? `Você está em ${visitantPosition}º lugar`
                      : "Carregando..."}
                    {loadingCamera && "Carregando..."}
                  </QueuePositionP>
                )
              )}
              <AccessibilityArea aria-live="assertive" aria-hidden tabIndex={0}>
                {audioEnabled ? "Áudio habilitado" : "Áudio desabilitado"}
              </AccessibilityArea>
              <AccessibilityArea aria-live="assertive" tabIndex={0} aria-hidden>
                {videoTrack.isEnabled && !videoDisabled
                  ? "Vídeo habilitado"
                  : "Vídeo desabilitado"}
              </AccessibilityArea>
            </ButtonContainer>
          </VideoContent>

          <div />
        </QueueContainer>
      )}
    </Fragment>
  );
};
