import styled, { css } from "styled-components";

export const NpsContainer = styled.div`
  ${(props: { pageAssets?: any; commentText?: boolean }) =>
    props.pageAssets && props.pageAssets.backgroundColor
      ? css`
          background: linear-gradient(
            180deg,
            ${props.pageAssets.backgroundColor} -18.72%,
            ${props.pageAssets.contrastColor || "#0a070f"} 75.27%
          );
        `
      : css`
          background: linear-gradient(180deg, #2c1050 -18.72%, #0a070f 75.27%);
        `}

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props: { commentText?: boolean }) =>
    props.commentText ? "space-evenly" : "flex-start"};
  height: 100%;
  width: 100%;
  .rating-nps {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 999px) {
    .rating-nps svg {
      width: 3em;
      margin-left: 5px;
    }
  }

  @media (min-width: 1000px) {
    .rating-nps span {
      margin-left: 5px;
    }
  }

  @media (max-height: 768px) {
    justify-content: flex-start;
    overflow-y: scroll;
  }
`;

export const VideoInputArea = styled.div`
  width: 80%;
  background-color: #fff;
  border-radius: 25px;
  height: 200px;

  input {
    width: 100%;
  }
`;

export const VideoContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  video {
    max-width: 700px;
    border-radius: 30px;
    max-height: 450px;
  }

  img {
    width: 100%;
    border-radius: 15px;
  }

  @media (max-width: 1000px) {
    video {
      max-width: 95%;
    }

    img {
      max-height: 800px;
      width: 80%;
    }
  }

  @media (max-width: 320px) {
    img {
      max-height: 260px;
    }
  }
`;

export const ClientLogo = styled.img<{ showComment?: boolean }>`
  max-height: 90px;

  @media (max-width: 768px) {
    margin: ${({ showComment }) =>
      showComment ? "10% 0px 0px 0px" : "10% 0px -10% 0px"};
  }
  @media (max-width: 320px) {
    margin: 10px 0px;
  }
  @media (max-height: 768px) {
    margin: 10px 0px;
  }
`;

export const NpsRating = styled.div`
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: -20%;
  }
`;

export const ButtonContainer = styled.div<{
  bgColor: string;
  commentText: boolean;
}>`
  width: 80%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  position: relative;
  margin: ${({ commentText }) => (commentText ? "40px" : "20px")};

  button {
    cursor: pointer;
    font-family: "Poppins";
    font-weight: 600;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    border: none;
    height: 56px;
    margin-top: 0px;
    width: 200px;
    border-radius: 50px;
    outline: none;
    margin: 0px 20px 0px 0px;
  }

  button:last-child {
    margin: 0px 0px 0px 0px;
  }

  label:hover {
    opacity: 0.9;
  }

  button:hover {
    opacity: 0.9;
  }

  @media (max-width: 320px) {
    width: 30%;

    button {
      padding: 0px 5px;
    }
  }
`;

export const CommentArea = styled.textarea`
  width: 70%;
  max-width: 600px;
  height: 300px;
  border: none;
  border-radius: 30px;
  padding: 20px;

  @media (max-width: 320px) {
    width: 70%;
    height: 140px;
    border-radius: 20px;
  }
`;

export const ConfirmRatedButton = styled.button<{
  txtColor: string;
  bgColor: string;
}>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ txtColor }) => txtColor};
`;

export const BackToAppButton = styled.button<{
  txtColor: string;
  bgColor: string;
}>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ txtColor }) => txtColor};
`;

export const MakeACommentButton = styled.button<{
  txtColor: string;
  bgColor: string;
}>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ txtColor }) => txtColor};
  font-size: 13px;
`;

export const MakeATextCommentButton = styled.button<{
  txtColor: string;
  bgColor: string;
}>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ txtColor }) => txtColor};
  font-size: 13px;
`;

export const MakeAVideoCommentButton = styled.label<{
  txtColor: string;
  bgColor: string;
}>`
  cursor: pointer;
  font-family: "Poppins";
  font-weight: 600;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
  width: 200px;
  border: none;
  margin-top: 0px;
  border-radius: 50px;
  outline: none;
  margin: 0px 20px 0px 0px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ txtColor }) => txtColor};
  font-size: 13px;

  input {
    display: none;
  }

  @media (max-width: 320px) {
    width: 30%;

    button {
      padding: 0px 5px;
    }
  }
`;

export const SendCommentButton = styled.button<{
  txtColor: string;
  bgColor: string;
}>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ txtColor }) => txtColor};
`;

export const AcessebilityText = styled.div`
  position: fixed;
  top: -100px;
`;
