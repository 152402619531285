import { WaitingScreen as WaitingScreenStyle, FakeImg } from "./styles";
import { useLayoutEffect, useState } from "react";
export const WaitingScreen = ({
  name,
  avatar,
  role,
  textColor,
  backgroundColor,
  contrastColor,
}: {
  name: string;
  avatar: string;
  role: string;
  textColor: string | undefined;
  backgroundColor: string | undefined;
  contrastColor: string | undefined;
}) => {
  const [url, setUrl] = useState<string | undefined>(undefined);

  useLayoutEffect(() => {
    const S3_URL = process.env.REACT_APP_PUBLIC_STATC_FILES_URL;
    if (S3_URL && avatar) {
      setUrl(S3_URL + avatar);
    } else {
      setUrl(undefined);
    }
  }, [avatar]);
  return (
    <WaitingScreenStyle  pageAssets={{backgroundColor, contrastColor, textColor}}>
      {url ? (
        <img src={url} alt="" onError={() => setUrl(undefined) }/>
      ) : (
        <FakeImg>
          <span>{name.substr(0, 1)}</span>
        </FakeImg>
      )}

      <h4>{name}</h4>
      <h4>{role ? role : '-'}</h4>      
      <div className="waiting">
          <h1>Atendente pausou o vídeo, aguarde um momento</h1>
          <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
          </div>
      </div>
    </WaitingScreenStyle>
  );
};
