import styled from "styled-components";

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: none;
  font-weight: bold;
  margin-top: 16px;

  & h1 {
    margin: 0;
  }

  & h2 {
    margin: 0;
  }
`;
