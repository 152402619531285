import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import { request } from "../../services/request";
import { getProtocolId } from "../../sessionstorage";
import {
  IconBackToAppButton,
  IconCommentButton,
  IconVideoButton,
  SendIconButton,
} from "../../assets/icons";
import {
  BackToAppButton,
  ConfirmRatedButton,
  MakeACommentButton,
  SendCommentButton,
  MakeAVideoCommentButton,
  MakeATextCommentButton,
  ButtonContainer,
  NpsContainer,
  ClientLogo,
  CommentArea,
  AcessebilityText,
} from "./styled";

import { RatingNps } from "../../components/RatingNps";
import Video from "../../components/Video";
interface IVideoProps {
  apresentationVideoUrl: string;
  url: string;
  fileName: string;
  description: string;
}

interface INPSVideos {
  thanks: IVideoProps;
}

interface INpsAssets {
  logo: string;
  textColor: string;
  iconColor: string;
  buttonColor: string;
  backgroundColor: string;
  contrastColor: string;
  apresentationVideoUrl: string;
  npsIconTypes: [NpsIconType];
  iconSortType: IconSortType;
  linkToRedirect: string;
  descriptionVideo: string;
  videoMobile: string;
  descriptionVideoMobile: string;
  mobileVideos: INPSVideos;
  desktopVideos: INPSVideos;
}

enum NpsIconType {
  FACES = "FACES",
  STARS = "STARS",
  NUMBERS = "NUMBERS",
}
enum IconSortType {
  RANDOM = "RANDOM",
  BALANCE = "BALANCE",
}

export const NpsMobilePage: React.FC = () => {
  const [npsRating, setRatingValue] = useState<number>();
  const [npsNumberRating, setNpsNumberRating] = useState<number>(5);
  const [npsVoted, setNpsVoted] = useState<boolean>(false);
  const [comments, setComments] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<boolean>(false);
  const [commentTextSended, setCommentTextSended] = useState<boolean>(false);
  const [commentTextData, setCommentTextData] = useState<string>("");
  const [commentVideo, setCommentVideo] = useState<boolean>(false);
  const [commentVideoSended, setCommentVideoSended] = useState<boolean>(false);
  const [npsIconTypeChosed, setNpsIconTypeChossed] = useState<NpsIconType>();

  const [npsAssets, setNpsAssets] = useState<INpsAssets>();
  const [showBackButtonAppRedirect, setShowBackButtonAppRedirect] =
    useState<boolean>(true);
  const [backToAppColorIcon, setBackToAppColorIcon] = useState<string>("");
  const [textColorIcon, setTextColorIcon] = useState<string>("");
  const [makeACommentIcon, setMakeACommentIcon] = useState<string>("");
  const [videoColorIcon, setVideoColorIcon] = useState<string>("");
  const [sendColorIcon, setSendColorIcon] = useState<string>("");

  const [urlVideo, setUrlVideo] = useState<string | undefined>("");
  const [textVideo, setTextVideo] = useState<string | undefined>("");

  const handleSendNps = useCallback(async () => {
    const data = {
      ratting: npsRating,
      iconType: npsIconTypeChosed,
    };
    await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "POST",
      path: `rooms/nps/ratting/${getProtocolId()}`,
      data: data,
    });
  }, [npsRating, npsIconTypeChosed]);

  const handleSendCommentVideo = async (commentVideoData: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(commentVideoData);

    reader.onload = async () => {
      await request({
        showLoading: true,
        showSuccessMessage: false,
        method: "POST",
        path: `rooms/nps/comment-video/${getProtocolId()}`,
        data: { npsCommentVideo: reader.result },
      });
      setCommentVideoSended(true);
      setCommentVideo(true);
    };
  };

  const handleSendCommentText = useCallback(async () => {
    await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "POST",
      path: `rooms/nps/comment-text/${getProtocolId()}`,
      data: { npsCommentText: commentTextData },
    });
    setCommentTextSended(true);
  }, [commentTextData]);

  const clientData = async () => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      path: `rooms/get-assets/nps/${getProtocolId()}`,
    });

    if (!response.error) {
      setNpsAssets(response);

      setBackToAppColorIcon(response.iconColor);
      setTextColorIcon(response.iconColor);
      setVideoColorIcon(response.iconColor);
      setSendColorIcon(response.iconColor);
      setMakeACommentIcon(response.iconColor);

      setNpsIconTypeChossed(response.npsIconType);
      // setNpsNumberRating(response.npsNumericRating);
      response.nps && setNpsVoted(true);
      response.npsCommentText && setCommentTextSended(true);
      response.npsCommentText && setCommentText(true);
      response.npsCommentVideo && setCommentVideoSended(true);
      response.npsCommentVideo && setCommentVideo(true);
    }
  };

  const backApp = useCallback(async () => {
    await request({
      showLoading: true,
      showSuccessMessage: false,
      showErrorMessage: false,
      method: "POST",
      path: `report/back-app`,
      data: { protocolId: getProtocolId() },
    });

    const link = npsAssets?.linkToRedirect ? npsAssets.linkToRedirect : "";
    window.location.replace(link);
  }, [npsAssets]);

  const updateNpsRating = async () => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      path: `rooms/get-assets/nps/${getProtocolId()}`,
    });

    if (!response.error) {
      response.npsNumericRating && response.npsIconType === "NUMBERS" 
      ?
        setNpsNumberRating(response.npsNumericRating)
      :
        setNpsNumberRating(5)
    }
  };

  useEffect(() => {
    updateNpsRating();
  }, [npsNumberRating]);

  useEffect(() => {
    if (!npsAssets) {
      clientData();
    }
  }, [npsAssets]); // eslint-disable-line

  useEffect(() => {
    setUrlVideo(() => npsAssets?.videoMobile);

    setTextVideo(() => npsAssets?.descriptionVideoMobile);
  }, [npsAssets]);

  useEffect(() => {
    if (npsVoted && npsAssets) {
      setUrlVideo(() => npsAssets?.mobileVideos?.thanks?.url);
      setTextVideo(() => npsAssets?.mobileVideos?.thanks?.description);
    }
  }, [npsVoted, npsAssets]);

  return (
    <Fragment>
      {npsAssets && (
        <NpsContainer pageAssets={npsAssets}>
          <ClientLogo
            showComment={commentText && !commentTextSended}
            src={npsAssets.logo}
            alt="logo"
            className="logo-client"
            aria-hidden={true}
          />
          {!commentText && !commentVideo && (
            <Video
              context="mobile"
              hasBackgroundVideo
              url={urlVideo}
              description={textVideo}
            />
          )}

          {commentText && !commentTextSended && (
            <Fragment>
              <CommentArea
                data-cy="inputTextComment"
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setCommentTextData(e.target.value);
                }}
                placeholder="Escreva seu comentário"
              ></CommentArea>
            </Fragment>
          )}

          {(commentVideoSended || commentTextSended) && (
            <Video
              context="mobile"
              hasBackgroundVideo
              url={urlVideo}
              description={textVideo}
            />
          )}

          {!npsVoted && !!npsIconTypeChosed && (
            <>
              <AcessebilityText tabIndex={1} aria-live="polite">
                Realize uma avaliação do nosso atendimento
              </AcessebilityText>
              <RatingNps
                change={(e: number) => setRatingValue(e)}
                type={npsIconTypeChosed}
                bgColor={npsAssets.buttonColor}
                txtColor={npsAssets.iconColor}
                npsNumeric={npsNumberRating}
              />
            </>
          )}

          <ButtonContainer bgColor={npsAssets?.backgroundColor}>
            {commentText && !commentTextSended && (
              <SendCommentButton
                data-cy="sendComments"
                onClick={async () => {
                  setSendColorIcon(npsAssets.backgroundColor);
                  await handleSendCommentText();
                  setShowBackButtonAppRedirect(true);
                }}
                bgColor={npsAssets.buttonColor || npsAssets.backgroundColor}
                txtColor={npsAssets.iconColor || "#fff"}
              >
                Enviar
                <SendIconButton bgColor={npsAssets.iconColor} />
              </SendCommentButton>
            )}

            {!npsVoted && (
              <ConfirmRatedButton
                data-cy="confirmRating"
                onClick={async () => {
                  if (!npsRating) return;
                  setSendColorIcon(npsAssets.backgroundColor);
                  setNpsVoted(true);
                  await handleSendNps();
                }}
                bgColor={npsAssets.buttonColor || npsAssets.backgroundColor}
                txtColor={npsAssets.iconColor || "#fff"}
              >
                Confirmar Avaliação
              </ConfirmRatedButton>
            )}

            {npsVoted && !comments && !commentText && !commentVideo && (
              <MakeACommentButton
                data-cy="btnComments"
                onClick={() => {
                  setComments(true);
                }}
                bgColor={npsAssets.buttonColor || npsAssets.backgroundColor}
                txtColor={npsAssets.iconColor || "#fff"}
              >
                <span>Fazer comentário</span>
                <IconCommentButton bgColor={makeACommentIcon} />
              </MakeACommentButton>
            )}

            {comments && !commentText && !commentVideo && (
              <Fragment>
                <MakeATextCommentButton
                  data-cy="btnText"
                  onClick={() => {
                    setTextColorIcon(npsAssets.backgroundColor);
                    setCommentText(true);
                    setShowBackButtonAppRedirect(false);
                  }}
                  onMouseEnter={() => setTextColorIcon("#fff")}
                  onMouseLeave={() =>
                    setTextColorIcon(
                      npsAssets.buttonColor || npsAssets.backgroundColor
                    )
                  }
                  bgColor={npsAssets.buttonColor || npsAssets.backgroundColor}
                  txtColor={npsAssets.iconColor || "#fff"}
                >
                  <span>Avaliação em texto</span>
                  <IconCommentButton bgColor={textColorIcon} />
                </MakeATextCommentButton>
                <MakeAVideoCommentButton
                  htmlFor="video-file"
                  bgColor={npsAssets.buttonColor || npsAssets.backgroundColor}
                  txtColor={npsAssets.iconColor || "#fff"}
                  onClick={() => {
                    setVideoColorIcon(npsAssets.backgroundColor);
                  }}
                >
                  <span>Avaliação em vídeo</span>
                  <IconVideoButton bgColor={videoColorIcon} />
                  <input
                    type="file"
                    onChange={(e: any) => {
                      handleSendCommentVideo(e.target.files[0]);
                    }}
                    id="video-file"
                  />
                </MakeAVideoCommentButton>
              </Fragment>
            )}

            {showBackButtonAppRedirect &&
              npsAssets &&
              npsAssets.linkToRedirect && (
                <BackToAppButton
                  data-cy="backApp"
                  onClick={async () => {
                    backApp();
                  }}
                  bgColor={npsAssets.buttonColor || npsAssets.backgroundColor}
                  txtColor={npsAssets.iconColor || "#fff"}
                >
                  <span>Voltar para o app</span>
                  <IconBackToAppButton bgColor={backToAppColorIcon} />
                </BackToAppButton>
              )}
          </ButtonContainer>

          <div />
          <div />
        </NpsContainer>
      )}
    </Fragment>
  );
};
