import styled from "styled-components";
export const FormContainer = styled.div`
  margin-top: 58px;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
    color: #fff;
    font-weight: bold;
    line-height: 27px;
    font-size: 22px;
    margin-bottom: 25px;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 479px;
    width: 100%;
    input {
      border: none;
      width: 100%;
      height: 41px;
      background: #ffffff;
      box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.25);
      border-radius: 20.5399px;
      padding-left: 5px;
      outline: none;
      margin-top: 18px;
      padding-left: 15px;
      font-family: "Poppins";

      &::placeholder {
        color: #a6a6a6;
      }
    }

    button {
      border: none;
      margin-top: 18px;
      max-width: 200px;
      width: 80%;
      height: 41px;
      background: #ffffff;
      box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.25);
      border-radius: 50px;
      cursor: pointer;
      outline: none;
      transition: color 0.5s, transform 0.2s, background-color 0.2s;
      margin-top: 50px;

      &:hover {
        background: ${(props: { pageAssets?: any }) => props.pageAssets.backgroundColor};;
        color: #fff;
      }
    }

    .btn-enter {
      width: 168px;
      height: 60px;
      font-size: 16px;
      font-weight: bold;
      position: relative;
      font-family: "Poppins";
      padding-right: 15px;
      transition: all 0.3s;

      svg {
        position: absolute;
        right: 23px;
        top: 19px;
      }

      &:hover {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    .error-field {
      font-size: 12px;
      width: 100%;
      font-weight: 600;
      color: red;

      @media (max-width: 768px) {
        width: 90%;
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 0px;
    width: 90%;
    justify-self: flex-start;
    h1 {
      font-size: 18px;
      margin-top: 0px;
    }
    form {
      justify-content: flex-start;
      input {
        width: 90%;
      }
    }
  }
`;
