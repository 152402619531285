import styled, { css } from "styled-components";

export const RatingContainer = styled.div`
  display: flex;
  padding: 0;
  padding-top: 15px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const RatingItem = styled.button`
  border-style: none;
  background-color: transparent;  
`;
