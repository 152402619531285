import React, { useCallback, useEffect, useState } from "react";

import { RatingContainer, RatingItem } from "./styled";
import { getIconRating } from "../../assets/rating";

import { Iicones, NpsIconType, ListLabels } from "./data";

interface IRating {
  change: (e: number) => void;
  type: string | undefined;
  bgColor: string;
  txtColor: string;
  npsNumeric: number;
}
export const RatingNps: React.FC<IRating> = ({
  change,
  type,
  bgColor,
  txtColor,
  npsNumeric,
}) => {

  const [maxIcon] = useState(npsNumeric);
  const [iconValues, setIconValue] = useState<Array<Iicones>>([]);

  const [typeFeedback, setTypeFeedback] = useState<string>("");
  const [hoverValue, setHoverValue] = useState<number>(0);
  const [selectedValue, setSelectedValue] = useState<number>(0);

  const iconRating = getIconRating(bgColor, txtColor);
  const hoverEffect = useCallback(
    (value) => {
      if (!value) {
        setHoverValue(selectedValue ? selectedValue : 0);
        return;
      }

      setHoverValue(value);
    },
    [selectedValue]
  );

  const handleChange = useCallback(
    (value) => {
      if (!value) {
        setHoverValue(selectedValue ? selectedValue : 0);
        return;
      }

      setSelectedValue(value);
      change(value);
    },
    [selectedValue, change]
  );

  useEffect(() => {
    if (!iconValues.length) {
      const listvalue = [];
      for (let index = 0; index < maxIcon; index++) {
        const value = index + 1;
        let iconeFull = iconRating.starFull;
        let iconeEmpty = iconRating.starEmpty;
        let label = ListLabels.STARS[index];

        if (type === NpsIconType.NUMBERS) {
          iconeFull = iconRating.numbersFull[index];
          iconeEmpty = iconRating.numbersEmpty[index];
          label = ListLabels.NUMBERS[index];
        }

        if (type === NpsIconType.FACES) {
          iconeFull = iconRating.smilesFull[index];
          iconeEmpty = iconRating.smilesEmpty[index];
          label = ListLabels.FACES[index];
        }

        listvalue.push({ value, iconeFull, iconeEmpty, label });
      }
      setIconValue(listvalue);
    }
  }, [typeFeedback, iconRating, maxIcon, iconValues, type]);

  useEffect(() => {
    setTypeFeedback(type ? type : "STAR");
  }, [type]);
  return (
    <RatingContainer
      onMouseLeave={() => hoverEffect(undefined)}
      onBlur={() => hoverEffect(undefined)}
    >
      {iconValues.map((el, index) => {
        return (
          <RatingItem
            key={"itensRating" + index}
            onMouseEnter={() => hoverEffect(el.value)}
            onFocus={() => hoverEffect(el.value)}
            onClick={() => handleChange(el.value)}
            aria-label={el.label}
          >
            {el.value <= hoverValue ? el.iconeFull : el.iconeEmpty}
          </RatingItem>
        );
      })}
    </RatingContainer>
  );
};
