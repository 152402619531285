import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;

  & p {
    color: white;
    font-weight: 600;
    margin-left: 8px;
    margin-right: 8px;
  }
`;

interface StyleProps {
  videoEnable?: boolean;
}

export const SwipeButton = styled.div`
  padding: 0 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-appearance: none;
  flex-direction: row-reverse;
  appearance: none; // change the behavior of input-checkbox to div
  min-width: 70px;
  width: 19%;
  min-width: 102px;
  padding-right: 8%;
  height: 45px;
  background-color: #d02a3e;
  border-radius: 50px;
  outline: none; // remove switch's retangle outline
  transition: all linear 0.3s;
  ${(props: { videoEnable: boolean }) =>
    props.videoEnable &&
    css`
      background-color: #48c664;
      flex-direction: row;
      padding-left: 8%;
      padding-right: 4px;
    `}
  @media (max-width: 768px) {
    width: auto;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: #fff;
    border-radius: 50%;
    transition: 200ms;
    box-shadow: -5px 0px 6px rgba(0, 0, 0, 0.25);
    path {
      fill: ${(videoEnable: StyleProps) =>
        videoEnable.videoEnable === false ? "#d02a3e" : "#48c664"};
    }
  }
  & > span {
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 700;
    transition: all 3s;
    cursor: pointer;
  }
`;

export const CircleButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
  appearance: none; // change the behavior of input-checkbox to div
  width: fit-content;
  height: 48px;
  width: 48px;
  border-radius: 50px;
  outline: none; // remove switch's retangle outline
  transition: all linear 1s;
  border: none;
  background-color: #fff;
  @media (max-width: 768px) {
    width: 48px;
    img {
      width: 40px !important;
      object-fit: scale-down;
    }
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    transition: 200ms;
    box-shadow: -5px 0px 6px rgba(0, 0, 0, 0.25);
  }
  &:hover {
    opacity: 0.8;
  }
  img {
    width: 25px;
  }
`;
