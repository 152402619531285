export enum NpsIconType {
  FACES = "FACES",
  STARS = "STARS",
  NUMBERS = "NUMBERS",
}

export interface Iicones {
  value: number;
  iconeFull: JSX.Element;
  iconeEmpty: JSX.Element;
  label: string;
}

export const ListLabels = {
  FACES: [
    "Não satisfeito",
    "Pouco satisfeito",
    "Neutro",
    "Satisfeito",
    "Muito Satisfeito",
  ],
  STARS: [
    "Uma estrela",
    "Duas estrelas",
    "Três estrelas",
    "Quarto estrelas",
    "Cinco estrelas",
  ],
  NUMBERS: [
    "Um ponto",
    "Dois pontos",
    "Três pontos",
    "Quarto pontos",
    "Cinco pontos",
    "Seis pontos",
    "Sete pontos",
    "Oito pontos",
    "Nove pontos",
    "Dez pontos",
  ],
};
