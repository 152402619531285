import { useRef } from 'react';

interface UseTimeoutReturnProps {
    timer: React.MutableRefObject<NodeJS.Timeout | null>;
    createTimeout:  (callback: () => void, timeout: number, ) => void;
    cancelTimeout: () => void | false;
}

const useTimeout = (): UseTimeoutReturnProps => {
    const timer = useRef<NodeJS.Timeout | null>(null);

    const createTimeout = (callback: () => void, timeout: number) => {
        if(timer.current) {
            clearTimeout(timer.current);
        }

        timer.current = setTimeout(() => {                    
            callback();                      
            if (timer.current) clearTimeout(timer.current);
        }, timeout);
    };

    const cancelTimeout = () => timer.current ? clearTimeout(timer.current) : false;

    return {
        timer,
        createTimeout,
        cancelTimeout,
    };
};

export default useTimeout;