export const getVId = () => {
  return window.sessionStorage.getItem(process.env.REACT_APP_VISITANT_ID ?? "");
};

export const setVId = (value: string) => {
  window.sessionStorage.setItem(process.env.REACT_APP_VISITANT_ID ?? "", value);
};

export const getProtocolId = () => {
  return window.sessionStorage.getItem(process.env.REACT_APP_PROTOCOL_ID ?? "");
};

export const setProtocolId = (value: string) => {
  window.sessionStorage.setItem(process.env.REACT_APP_PROTOCOL_ID ?? "", value);
};

export const getNpsType = () => {
  return window.sessionStorage.getItem(process.env.REACT_APP_NPS_TYPE ?? "");
};

export const setNpsType = (value: string) => {
  window.sessionStorage.setItem(process.env.REACT_APP_NPS_TYPE ?? "", value);
};

export const getSSAudioEnable = () => {
  return window.sessionStorage.getItem(
    process.env.REACT_APP_AUDIO_ENABLE ?? ""
  );
};

export const removeSSAudioEnable = () => {
  window.sessionStorage.removeItem(process.env.REACT_APP_AUDIO_ENABLE ?? "");
};

export const setSSAudioEnable = (value: string) => {
  window.sessionStorage.setItem(
    process.env.REACT_APP_AUDIO_ENABLE ?? "",
    value
  );
};

export const setSSVideoEnable = (value: string) => {
  window.sessionStorage.setItem(
    process.env.REACT_APP_VIDEO_ENABLE ?? "",
    value
  );
};

export const getSSVideoEnable = () => {
  return window.sessionStorage.getItem(
    process.env.REACT_APP_VIDEO_ENABLE ?? ""
  );
};

export const removeSSVideoEnable = () => {
  window.sessionStorage.removeItem(process.env.REACT_APP_VIDEO_ENABLE ?? "");
};

export const getGuestSSAudioEnable = () => {
  return window.sessionStorage.getItem(
    process.env.REACT_APP_GUEST_AUDIO_ENABLE ?? ""
  );
};

export const removeGuestSSAudioEnable = () => {
  window.sessionStorage.removeItem(
    process.env.REACT_APP_GUEST_AUDIO_ENABLE ?? ""
  );
};

export const setGuestSSAudioEnable = (value: string) => {
  window.sessionStorage.setItem(
    process.env.REACT_APP_GUEST_AUDIO_ENABLE ?? "",
    value
  );
};

export const setGuestSSVideoEnable = (value: string) => {
  window.sessionStorage.setItem(
    process.env.REACT_APP_GUEST_VIDEO_ENABLE ?? "",
    value
  );
};

export const getGuestSSVideoEnable = () => {
  return window.sessionStorage.getItem(
    process.env.REACT_APP_GUEST_VIDEO_ENABLE ?? ""
  );
};

export const removeGuestSSVideoEnable = () => {
  window.sessionStorage.removeItem(
    process.env.REACT_APP_GUEST_VIDEO_ENABLE ?? ""
  );
};

export const clearSS = () => {
  window.sessionStorage.clear();
};

export const setVisitantDevice = (value: Object) => {
  window.sessionStorage.setItem(
    process.env.REACT_APP_VISITANT_DEVICE ?? "",
    JSON.stringify(value)
  );
};

export const getVisitantDevice = () => {
  const device = window.sessionStorage.getItem(
    process.env.REACT_APP_VISITANT_DEVICE ?? ""
  );

  if (device && device !== "") return device;

  return "{}";
};
