import { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  Participant as ParticipantTwilio,
  VideoTrack,
  AudioTrack,
} from "twilio-video";
import { BackgroundWhenTrackIsZero } from "./styles";

export const Participant = ({
  participant,
  enableDisableVideoUpdate,
  clientLogo,
  isGuest,
  areaRef,
  isAttendant,
  finalizeCall,
  showLogo = true,
}: {
  participant: ParticipantTwilio;
  enableDisableVideoUpdate?: number;
  clientLogo?: string;
  isGuest?: Boolean;
  areaRef?: any;
  isAttendant?: Boolean;
  finalizeCall?: Boolean;
  showLogo?: Boolean;
}) => {
  const [videoTracks, setVideoTracks] = useState<Array<VideoTrack>>([]);
  const [audioTracks, setAudioTracks] = useState<Array<AudioTrack>>([]);
  const [videoEnabled, setVideoEnabled] = useState<boolean>(false);

  const videoRef = useRef<any>();
  const audioRef = useRef<any>();

  const [url, setUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (videoRef.current && videoEnabled) {      
      videoRef.current.addEventListener('suspend', () => {
        videoRef.current.play();
      });
    }

    return () => {
      if (videoRef) videoRef.current.removeEventListener('suspend', () => false);
    }
  }, [videoEnabled]);


  const trackpubsToTracks = (trackMap: any) => {
    return Array.from(trackMap.values())
      .map((publication: any) => publication.track)
      .filter((track) => track !== null);
  };

  const trackSubscribed = (track: any) => {
    if (track.kind === "video") {
      setVideoTracks((videoTracks) => [...videoTracks, track]);
      setVideoEnabled(true);
    } else if (track.kind === "audio") {
      setAudioTracks((audioTracks) => [...audioTracks, track]);
    }
  };

  const trackUnsubscribed = (track: any) => {
    if (track.kind === "video") {
      setVideoTracks([]);
      setVideoEnabled(false);
    } else if (track.kind === "audio") {
      setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
    }
  };

  useEffect(() => {
    if (!participant) return;

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);
    participant.videoTracks.clear();

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant, enableDisableVideoUpdate]);

  useEffect(() => {
    const videoTrack = videoTracks[videoTracks.length - 1];

    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      videoTrack.on("enabled", () => setVideoEnabled(true));
      videoTrack.on("disabled", () => setVideoEnabled(false));
      setVideoEnabled(videoTrack.isEnabled);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[audioTracks.length - 1];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  useLayoutEffect(() => {
    const S3_URL = process.env.REACT_APP_PUBLIC_STATC_FILES_URL;
    var pattern = /^https:\/\//i;

    if (clientLogo) {
      const urlLogo = pattern.test(clientLogo) ? clientLogo : S3_URL + clientLogo
      setUrl(urlLogo);
    } else {
      setUrl(undefined);
    }
  }, [clientLogo]);

  return (
    <>
      {!videoEnabled ? (
        <BackgroundWhenTrackIsZero
          props={{
            width: areaRef?.current?.clientWidth || 190,
            height: areaRef?.current?.clientHeight || 107,
            img: isAttendant,
            sizeImg: isGuest ? 3 : 5,
          }}
        >
          {showLogo && (
            <>
              {!finalizeCall ? (
                <img src={url}  alt="logo do cliente" />
              ) : (
                <></>
              )}
            </>
          )}
          {!finalizeCall ? (
            !isGuest ? (
              <h1>Vídeo desabilitado</h1>
            ) : (
              <></>              
            )            
          ) : (
            <></>
          )}
        </BackgroundWhenTrackIsZero>
      ) : (
        <></>
      )}

      <video
        ref={videoRef}
        autoPlay={true}
        style={{ display: videoEnabled ? "block" : "none" }}
      />

      <audio ref={audioRef} autoPlay={true} />
    </>
  );
};
