import styled from "styled-components";

export const FinalizeCallButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background: #d02a3e;
  color: #fff;
  border: none;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  height: 55px;
  min-width: 48px !important;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 700;
  cursor: pointer;

  img {
    width: 20px !important;
    margin-bottom: 0 !important;
  }
  &:hover {
    opacity: 0.8;
    transition: all 3s;
  }
  @media (max-width: 768px) {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background: #fff;
  }
`;
