import { IPages } from "../NewVisitant";
import { QueueContainer,  } from "./styled";

import Video from "../../components/Video";

export const OutOffAttendanceHour: React.FC<IPages> = ({
  video,
  logo,
  backgroundColor,
  contrastColor,
  description,
  context,
}: IPages) => {
  return (
    <QueueContainer pageAssets={{ backgroundColor, contrastColor }}>
      {context !== "desktop" && <img src={logo} alt="" aria-hidden={true} />}

      <Video
        context={context}
        hasBackgroundVideo
        url={video}
        description={description}
        client={{ logo: logo }}
      ></Video>
    </QueueContainer>
  );
};
