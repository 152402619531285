import React from "react";
import { Switch } from "react-router-dom";
import { GuestInvited } from "../pages/GuestInvited";
import { GuestNps } from "../pages/GuestNps";
import { GuestRoom } from "../pages/GuestRoom";
import { GuestRoomMobile } from "../pages/GuestRoomMobile";
import { NewVisitant } from "../pages/NewVisitant";
import { NpsPage } from "../pages/Nps";
import { NpsMobilePage } from "../pages/NpsMobile";
import { Queue } from "../pages/Queue";
import { QueueMobile } from "../pages/QueueMobile";
import { Room } from "../pages/Room";
import { RoomMobile } from "../pages/RoomMobile";
import { verifyIsMobileOrDesktop } from "../utils";
import Route from "./Route";

const Routes: React.FC = () => (
  <Switch>
    <Route
      path="/fila"
      exact
      component={verifyIsMobileOrDesktop() === "desktop" ? Queue : QueueMobile}
    />
    <Route
      path="/sala"
      exact
      component={verifyIsMobileOrDesktop() === "desktop" ? Room : RoomMobile}
    />
    <Route
      path="/nps"
      exact
      component={
        verifyIsMobileOrDesktop() === "desktop" ? NpsPage : NpsMobilePage
      }
    />
    <Route path="/convidado/:protocolId" exact component={GuestInvited} />
    <Route
      path="/convidado/sala/:protocolId"
      exact
      component={
        verifyIsMobileOrDesktop() === "desktop" ? GuestRoom : GuestRoomMobile
      }
    />
    <Route
      path="/convidado-agradecimento/:protocolId"
      exact
      component={GuestNps}
    />
    <Route path="/*/" exact component={NewVisitant} />
  </Switch>
);

export default Routes;
