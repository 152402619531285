import styled from "styled-components";
import iconSelect from "../../assets/images/select-icon.svg";

export const InviteParticipantArea = styled.div<{ bgColor: string; contrast: string; }>`
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  z-index: 999;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  background: ${({ bgColor, contrast }) => `linear-gradient(180deg, ${bgColor} -18.72%, ${contrast} 75.27%);`} 
`;

export const InviteParticipantPopup = styled.div`
  width: 55%;
  min-width: 300px;
  max-width: 525px;

  @media (max-width: 768px) {
    width: 90%;
    min-width: 300px;
    height: auto;
  }
`;

export const InviteParticipantHeaderPopup = styled.div<{ bgColor: string }>`
  width: 100%;
  background-color: ${({ bgColor }) => bgColor ||"#6229bf"};
  text-align: center;
  border-radius: 24px 24px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);

  img {
    max-height: 25px;
  }

  h1 {
    font-weight: 600;
  }
`;

export const InviteParticipantBodyPopup = styled.div<{ color: string; buttonColor: string; }>`
  width: 100%;
  height: auto;
  border-radius: 0 0 25px 25px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-between;
  padding: 0 65px;
  box-sizing: border-box;

  h3, p, button {
    color: ${({ color }) => color} !important;
  }

  @media (max-width: 768px) {
    padding: 0 35px;
  }

  input {
    width: 100%;
    border-radius: 40px;
    border: 0;
    padding: 13px;
    background: #eaeaea;
    box-sizing: border-box;
    outline: none;
    padding-left: 23px;
  }

  button {
    width: 168px;
    padding: 20px;
    border: 0;
    background-color: ${({ buttonColor }) => buttonColor || "#6229BF"};
    margin: 25px auto 38px;
    color: #fff;
    cursor: pointer;
    font-family: "Poppins";
    font-weight: bold;
    border-radius: 50px;
    position: relative;
    padding-right: 44px;
    letter-spacing: 0.5px;

    :hover {
      filter: brightness(120%);
    }

    svg {
      position: absolute;
      right: 30px;
      path {
        fill: #fff;
      }
    }
  }

  h3 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 0;
  }

  p {
    margin-top: 5px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 30px;
  }

  #select-name {
    width: 100%;
    border-radius: 40px;
    border: 0;
    padding: 9.5px;
    background: rgb(234 234 234 / 75%);    
    box-sizing: border-box;
    outline: none;
    box-shadow: 0px 5px 14px rgb(0 0 0 / 25%);
    padding-left: 20px;
    font-size: 12px;
    margin-bottom: 28px;

    &:after {
      content: "";
      display: block;
      background: url(${iconSelect}) no-repeat center;
      background-color: ${({ buttonColor }) => buttonColor};
      -webkit-mask-image: url(${iconSelect});
      mask-image: url(${iconSelect});
      position: absolute;
      height: 37px;
      border-radius: 50px;
      width: 37px;
      padding: 10px;
      box-sizing: border-box;
      top: 2px;
      right: 3px;
      cursor: pointer;
      pointer-events: none;

      &:hover {
        filter: brightness(1.1);
      }
    }

    .react-select__control-is-focused:hover {
      border: 0 !important;
      box-shadow: none !important;
    }

    .react-select__menu {
      width: 90%;
      box-sizing: border-box;
      border: 0;
      border-radius: 0;

      .react-select__menu-list {
        border: 0;
        background: 0;
        border-radius: 0;
        width: 100%;
        box-sizing: border-box;

        .react-select__option {
          background: #eee;
          padding: 10px;
          width: 100%;
          display: block;
          border: 0;
          border-radius: 0;
          box-sizing: border-box;
          border-bottom: 1px solid #ccc;
        }
      }
    }

    div {
      background: none;
      border: 0;
      min-height: auto;
      padding: 0;
    }
  }
`;
