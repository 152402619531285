import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ChatBox } from "../../components/Chat";
import { ToggleAudioButton } from "../../components/ToggleAudioButton";
import { ToggleVideoButton } from "../../components/ToggleVideoButton";
import { useRoomConnect } from "../../hooks/useRoomConnect";
import { request } from "../../services/request";
import { pauseRoomByAttendant, roomFinalizedByAttendant } from "../../socket";
import {
  ChatIconButton,
  ChatCloseIconButton,
} from "../../assets/icons";
import {
  RoomContainer,
  SwitchButtonContainer,

  AreaButtons,
  OpenChatButton,

  ChatIconArea,
} from "./styles";
import { EndCallButton } from "../../components/EndCallButton";
import { Participant as ParticipantTwilio } from "twilio-video";
import { WaitingScreen } from "../../components/WaitingScreen";
import Video from "../../components/Video";
import { setVId } from "../../sessionstorage";

type IClient = {
  logo: string;
  textColor: string;
  buttonColor: string;
  backgroundColor: string;
  contrastColor: string;
  attendantName: string;
  attendantImg: string;
  showChat: boolean;
  showAudioButton: boolean;
};

export const GuestRoom: React.FC = () => {
  const [openChat, setOpenChat] = useState<boolean>(false);
  const [openManual, setOpenManual] = useState<boolean>(false);
  const { participants, room, videoTrack, audioTrack } = useRoomConnect(); // eslint-disable-line
  const [client, setClient] = useState<IClient>({
    logo: '',
    textColor: '',
    buttonColor: '',
    backgroundColor: '',
    contrastColor: '',
    attendantName: '',
    attendantImg: '',
    showChat: false,
    showAudioButton: false,
  });
  const [roomIsPaused, setRoomIsPaused] = useState<{
    userImg: string;
    userName: string;
    roomIsPaused: boolean;
    roleUser: string;
  }>();
  const attendantAreaRef = useRef<HTMLDivElement>({} as HTMLDivElement);

  const [chatHeight, setChatHeight] = useState<number>(0);
  const [participantIsAttendant, setParticipantIsAttendant] =
    useState<ParticipantTwilio>();
  const [participantIsVisitant, setParticipantIsVisitant] =
    useState<ParticipantTwilio>();

  const localVideoRef = useRef<any>();
  const { protocolId }: { protocolId: string } =
    useParams<{ protocolId: string }>();
  const history = useHistory();

  const [videoDisabled, setVideoDisabled] = useState(true);
  const [finalizeCall, setFinalizeCall] = useState<boolean>(false);

  const clientData = async () => {
    const req = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      path: `rooms/get-assets/${protocolId}`,
    });
    if (!req.error) {
      setClient(req);
      if (!room.name) {
        history.push(`/convidado/${protocolId}`);
      }
    }
  };

  useEffect(() => {
    setVId("");
    roomFinalizedByAttendant(() => {
      window.location.href = `/convidado-agradecimento/${protocolId}`;
    });

    clientData();

    pauseRoomByAttendant(
      (userName: string, userImg: string, roomIsPaused: boolean, roleUser: string) => {
        setRoomIsPaused({
          userImg,
          roomIsPaused,
          userName,
          roleUser,
        });
      }
    );
  }, []); // eslint-disable-line

  useEffect(() => {
    if (videoTrack && videoTrack.kind) {
      videoTrack.attach(localVideoRef.current);
      let localVideoRefIntoEffect = localVideoRef.current;
      videoTrack.on("enabled", () => setVideoDisabled(false));
      videoTrack.on("disabled", () => setVideoDisabled(true));
      setVideoDisabled(!videoTrack.isEnabled);
      return () => {
        videoTrack.detach(localVideoRefIntoEffect);
      };
    }
  }, [videoTrack]);

  useEffect(() => {
    participants.forEach((p) => {
      if (p.identity.includes("visitant")) setParticipantIsVisitant(p);
      else if (p.identity.includes("attendant")) setParticipantIsAttendant(p);
    });
  }, [participants])


  const onFinalize = (status: boolean) => {
    setFinalizeCall(status);
    room.disconnect();
  }

  return (
    <Fragment>
      {roomIsPaused?.roomIsPaused && (
        <WaitingScreen
          avatar={roomIsPaused.userImg}
          name={roomIsPaused.userName}
          role={roomIsPaused.roleUser}
          textColor={client?.textColor}
          backgroundColor={client?.backgroundColor}
          contrastColor={client?.contrastColor}
        />
      )}
      <RoomContainer pageAssets={client}>        
          <Video           
            isGuest
            hasAttendant
            hasVisitant            
            context="desktop"
            localVideoRef={localVideoRef}
            participantIsAttendant={participantIsAttendant}
            participantIsVisitant={participantIsVisitant}            
            videoDisabled={videoDisabled}
            finalizeCall={finalizeCall}
            client={client}            
            openChat={openChat}
          />
          {client && (
            <ChatBox
              videoContainerHeigth={chatHeight}
              isAllowDigit={true}
              className={openChat ? "chatOpen" : ""}
              toggleChat={openChat}
              pageAssets={client}
              setToggleChat={setOpenChat}
              openManual={openManual}
              setOpenManual={setOpenManual}
            />
          )}

        <AreaButtons>
          <SwitchButtonContainer>
            <EndCallButton type="guest" protocolId={protocolId} onFinalizeCall={onFinalize} btnColor={client?.buttonColor || client?.backgroundColor || "#000"} textColor={client?.textColor || "#FFF"}/>
            <OpenChatButton
              onClick={() => {
                setTimeout(() => {
                  if (!openChat) {
                    setChatHeight(attendantAreaRef.current.clientHeight);
                  }
                }, 100);
                setOpenChat(!openChat);
                setOpenManual(() => true)
              }}
              style={{backgroundColor: client?.buttonColor || client?.backgroundColor}}
            >
              <ChatIconArea>
                {!openChat && (
                  <ChatIconButton
                    bgColor={client ? client.backgroundColor : "#000"}
                  />
                )}
                {openChat && <ChatCloseIconButton />}
              </ChatIconArea>
            </OpenChatButton>
            
            {client?.showAudioButton && (
              <ToggleAudioButton
                type="circle"
                pauseRoom={roomIsPaused?.roomIsPaused}
                bgColor={client?.buttonColor || "#fff"}
              />
            )}

            <ToggleVideoButton
              type="circle"
              pauseRoom={roomIsPaused?.roomIsPaused}
              bgColor={client?.buttonColor || "#fff"}
            />
          </SwitchButtonContainer>
        </AreaButtons>
      </RoomContainer>
    </Fragment>
  );
};
