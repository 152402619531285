import styled, { css } from "styled-components";

export const WaitingScreen = styled.div`
  ${(props: { pageAssets?: any }) =>
    props.pageAssets && props.pageAssets.backgroundColor
      ? css`
          background: linear-gradient(
            180deg,
            ${props.pageAssets.backgroundColor} -18.72%,
            ${props.pageAssets.contrastColor || "#0a070f"} 75.27%
          );
        `
      : css`
          background: linear-gradient(180deg, #1f0533 -18.72%, #0a070f 75.27%);
        `}
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 9999;
  color: ${(props: { pageAssets?: any }) =>
    props.pageAssets && props.pageAssets.textColor
      ? props.pageAssets.textColor
      : "#FFF"};
  img {
    border-radius: 50%;
    width: 166px;
    height: 166px;
    border: 3px solid
      ${(props: { pageAssets?: any }) =>
        props.pageAssets && props.pageAssets.textColor
          ? props.pageAssets.textColor
          : "#00ffe6"};
    margin-bottom: 2rem;
  }
  h4 {
    font-family: "Poppins";

    font-weight: bold;
    font-size: 2rem;
    line-height: 27px;
    margin-bottom: 1rem;
    text-align: center;
  }
  p {
    font-family: "Poppins";
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 4rem;
  }

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 58px;
    margin-left: 8px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 3px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 3px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 27px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 51px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  .waiting {
    display: flex;
    align-items: center;
    font-size: 24px;
  }

  .waiting h1 {
    color: #fff;
    font-size: 2rem;
    margin-left: 80px;
  }
  
  @media (max-width: 1024px) {
    img {
      width: 50%;
    }

    .waiting h1 {
      font-size: 12px;
      text-align: center;
    }

    .lds-ellipsis {
      right: 75px;
      top: 7px;
    }

    .lds-ellipsis div {
      width: 3px;
      height: 3px;
    }
  }
`;
export const FakeImg = styled.div`
  border-radius: 50%;
  width: 166px;
  height: 166px;
  border: 3px solid
    ${(props: { pageAssets?: any }) =>
      props.pageAssets && props.pageAssets.textColor
        ? props.pageAssets.textColor
        : "#00ffe6"};
  margin-bottom: 2rem;
  color: ${(props: { pageAssets?: any }) =>
    props.pageAssets && props.pageAssets.textColor
      ? props.pageAssets.textColor
      : "#FFF"};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
`;
