/* eslint-disable no-restricted-globals */
import React from "react";
import DeviceDetector from "device-detector-js";
import { isSupported } from "twilio-video";
import { parseUserAgent } from "detect-browser";

import WebrtcSupport from "webrtcsupport";

const isSupportedWebRtc = (): Boolean => {
  const parser = parseUserAgent(window.navigator.userAgent);

  if (parser?.name === "chromium-webview" || parser?.name === "miui") {
    return false;
  }
  if (
    WebrtcSupport.PeerConnection &&
    WebrtcSupport.SessionDescription &&
    WebrtcSupport.IceCandidate
  ) {
    return true;
  }

  return false;
};

export const verifyIsMobileOrDesktop = () => {
  if (window.outerWidth >= 800) return "desktop";

  return "mobile";
};

export const getDeviceInfos = () => {
  let deviceDetector = new DeviceDetector();
  return {
    ...deviceDetector.parse(decodeURIComponent(navigator.userAgent)),
    width: window.outerWidth,
    height: window.outerHeight,
    type: verifyIsMobileOrDesktop(),
    browserIsSupported: isSupportedWebRtc(),
  };
};

export async function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const useStateRef = <S>(
  defaultValue: S
): [S, Function, { current: S }] => {
  let [state, setState] = React.useState(defaultValue);
  let ref = React.useRef(state);

  let dispatch = React.useCallback(function (val) {
    ref.current = typeof val === "function" ? val(ref.current) : val;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};

export const isNubank = (): boolean => {
  return window.location.href.includes("nudevice") || 
         window.location.href.includes("nubank"); 
}

export const isMercadoLivre = (): boolean => {
  return window.location.href.includes("mercadopago")
}