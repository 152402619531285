import { Ballon as BallonStyled, BallonArea } from "./styled";
import { getVId } from "../../../sessionstorage";
import { FileIcon } from "../../../assets/icons";

interface IMessages {
  type: "attendant" | "visitant";
  msg: string;
  _id: string;
  messageType: "MESSAGE" | "ATTACHMENT";
  bgColor: {
    visitant: string;
    attendant: string;
  };
  txtColor?: string;
  index: number;
  totalMsg: number;
  attendantName: string;
  filename?: string;
}

export const Ballon = ({ bgColor, txtColor, type, msg, _id, messageType, index, totalMsg,attendantName, filename }: IMessages) => {
  return (
    <BallonArea 
    tabIndex={0}
    >
      <BallonStyled
        bgColor={bgColor}
        color={txtColor || '#000'}
        me={getVId() && type === "visitant" ? true : false}
        aria-label={
          type === "visitant" ? "Eu: " + msg : attendantName + ": " + msg
        }
      >
        {messageType === "ATTACHMENT" ? (
          <a href={msg} title="file" target="_blank" rel="noreferrer">
            <FileIcon
              bgColor={getVId() && type === "visitant" ? "#fff" : "#000"}
            />
            &nbsp;
            {filename}
          </a>
        ) : (
          <div color={txtColor || "#000"} aria-hidden={true}>{msg}</div>
        )}
      </BallonStyled>
    </BallonArea>
  );
};
