import { useRef, useMemo, useEffect, useState } from "react";
import { Participant as ParticipantTwilio } from "twilio-video";
import { MdPlayArrow } from "react-icons/md";
import * as MobileStyles from "./styles.mobile";
import * as DesktopStyles from "./styles.desktop";
import { CamOffIconButton } from "../../assets/icons";
import { Participant } from "../../components/Participant";

type IClient = {
  logo: string;
  textColor?: string;
  backgroundColor?: string;
  attendantName?: string;
  attendantImg?: string;
  showChat?: boolean;
};

type VideoProps = {
  localVideoRef?: React.MutableRefObject<any>;
  hasBackgroundVideo?: boolean;
  url?: string;
  description?: string;
  hasAttendant?: boolean;
  hasGuest?: boolean;
  hasVisitant?: boolean;
  isGuest?: boolean;
  participantIsAttendant?: ParticipantTwilio | undefined;
  participantIsGuest?: ParticipantTwilio | undefined;
  participantIsVisitant?: ParticipantTwilio | undefined;
  videoDisabled?: boolean;
  finalizeCall?: boolean;
  client?: IClient;
  openChat?: boolean;
  context: "mobile" | "desktop";
  isFullScreen?: boolean;
};

const Video: React.FC<VideoProps> = ({
  localVideoRef,
  hasAttendant,
  hasGuest,
  hasVisitant,
  isGuest,
  hasBackgroundVideo,
  url,
  description,
  participantIsAttendant,
  participantIsGuest,
  participantIsVisitant,
  videoDisabled,
  finalizeCall,
  client,
  openChat,
  context,
  isFullScreen,
}) => {
  const attendantAreaRef = useRef<HTMLDivElement>({} as HTMLDivElement);
  const guestAreaRef = useRef<HTMLDivElement>({} as HTMLDivElement);
  const attendantNameRef = useRef<HTMLDivElement>({} as HTMLDivElement);
  const backgroundVideoRef = useRef<HTMLVideoElement>({} as HTMLVideoElement);
  const [playVideo, setPlayVideo] = useState<boolean>(false);
  const CxtStyles = useMemo(
    () => (context === "mobile" ? MobileStyles : DesktopStyles),
    [context]
  );

  useEffect(() => {
    if (backgroundVideoRef.current && url) {
      backgroundVideoRef.current?.load();

      backgroundVideoRef.current.addEventListener("suspend", () => {
        backgroundVideoRef.current.play();
      });
    }

    return () => {
      if (backgroundVideoRef.current)
        backgroundVideoRef.current.removeEventListener("suspend", () => false);
    };
  }, [url]);

  return (
    <CxtStyles.VideoContainer isFullScreen={isFullScreen}>
      <CxtStyles.RoomHeader openChat={openChat} color={"#fff"}>
        {client && context === "desktop" && (
          <img src={client.logo} alt="" aria-hidden={true} />
        )}
        {client && client.attendantName && (
          <div
            tabIndex={0}
            ref={attendantNameRef}
            style={{ color: client?.textColor || "#fff" }}
          >
            <span>Atendente: </span>
            <p>{client.attendantName}</p>
          </div>
        )}
      </CxtStyles.RoomHeader>
      <CxtStyles.VideoContent openChat={openChat}>
        <CxtStyles.LocalVideoArea openChat={openChat}>
          {videoDisabled && localVideoRef ? (
            <CxtStyles.VideoDisabledIconContainer
              props={{
                width: localVideoRef?.current?.clientWidth,
                height: localVideoRef?.current?.clientHeight,
              }}
            >
              <CamOffIconButton />
            </CxtStyles.VideoDisabledIconContainer>
          ) : (
            <></>
          )}

          <video
            ref={localVideoRef}
            autoPlay={true}
            style={{ display: videoDisabled ? "none" : "block" }}
          />
        </CxtStyles.LocalVideoArea>
        {!isGuest && (
          <>
            {hasGuest && (
              <CxtStyles.GuestVideoArea
                openChat={openChat}
                id="part"
                ref={guestAreaRef}
              >
                {participantIsGuest && (
                  <Participant
                    key={participantIsGuest.sid}
                    participant={participantIsGuest}
                    clientLogo={client?.logo}
                    isGuest={true}
                    areaRef={guestAreaRef}
                    finalizeCall={finalizeCall}
                  />
                )}
              </CxtStyles.GuestVideoArea>
            )}
            {hasAttendant && (
              <CxtStyles.AttendantVideoArea
                openChat={openChat}
                ref={attendantAreaRef}
              >
                {participantIsAttendant && (
                  <Participant
                    key={participantIsAttendant.sid}
                    participant={participantIsAttendant}
                    clientLogo={client?.attendantImg}
                    areaRef={attendantAreaRef}
                    isAttendant={true}
                    finalizeCall={finalizeCall}
                  />
                )}
              </CxtStyles.AttendantVideoArea>
            )}
          </>
        )}
        {isGuest && (
          <>
            {hasAttendant && (
              <CxtStyles.GuestVideoArea
                openChat={openChat}
                id="part"
                ref={guestAreaRef}
              >
                {participantIsAttendant && (
                  <Participant
                    key={participantIsAttendant.sid}
                    participant={participantIsAttendant}
                    clientLogo={client?.attendantImg}
                    areaRef={guestAreaRef}
                    isGuest
                    finalizeCall={finalizeCall}
                  />
                )}
              </CxtStyles.GuestVideoArea>
            )}
            {hasVisitant && (
              <CxtStyles.AttendantVideoArea
                openChat={openChat}
                ref={attendantAreaRef}
              >
                {participantIsVisitant && (
                  <Participant
                    key={participantIsVisitant.sid}
                    participant={participantIsVisitant}
                    areaRef={attendantAreaRef}
                    isAttendant
                    clientLogo={client?.logo}
                    finalizeCall={finalizeCall}
                    showLogo={false}
                  />
                )}
              </CxtStyles.AttendantVideoArea>
            )}
          </>
        )}
        {hasBackgroundVideo && (
          <>
            <CxtStyles.AccessibilityChatArea
              aria-live="polite"
              tabIndex={1}
              role="alert"
            >
              Vídeo: {"\n"} {description}
            </CxtStyles.AccessibilityChatArea>
            {!playVideo && 
            <CxtStyles.PlayButton isPlaying={playVideo} onClick={() => {
              backgroundVideoRef.current.play();
              setPlayVideo(true)
            }}><MdPlayArrow color="#FFFFFF" size={40} /></CxtStyles.PlayButton>}
            <video
              autoPlay={false}
              muted={false}
              onEnded={() => setPlayVideo(false)}
              loop={false}
              playsInline
              data-wf-ignore="true"
              data-object-fit="cover"
              aria-hidden="true"
              className="video-content"
              ref={backgroundVideoRef}
            >
              <source
                src={`${url}.mp4`}
                type="video/mp4"
                aria-hidden="true"
              ></source>

              <source
                src={`${url}.mov`}
                type="video/quicktime"
                aria-hidden="true"
              ></source>
              <source
                src={`${url}.webm`}
                type="video/webm"
                aria-hidden="true"
              ></source>
            </video>
          </>
        )}
      </CxtStyles.VideoContent>
    </CxtStyles.VideoContainer>
  );
};

Video.defaultProps = {
  participantIsAttendant: undefined,
  participantIsGuest: undefined,
  participantIsVisitant: undefined,
  finalizeCall: false,
  client: {
    logo: "",
    textColor: "",
    backgroundColor: "",
    attendantName: "",
    attendantImg: "",
    showChat: false,
  },
  hasBackgroundVideo: false,
  url: "",
  description: "",
  openChat: false,
  hasAttendant: false,
  hasGuest: false,
  hasVisitant: false,
  isGuest: false,
  videoDisabled: false,
  localVideoRef: undefined,
  isFullScreen: false,
};

export default Video;
