import { SweetAlertResult } from "sweetalert2";
import { visitantGetOutRoom } from "../../socket";
import sweetAlert from "../../utils/sweetAlert";
import { FinalizeCallButton } from "./styles";

export const EndCallButton = (
  {
    type,
    protocolId,
    onFinalizeCall,
    btnColor,
    textColor,
    redirectFeedback,
  }: {
    type: "visitant" | "guest";
    protocolId?: string;
    onFinalizeCall: Function;
    btnColor: string;
    textColor: string;
    redirectFeedback?: string;
  }
) => {
  return (
    <FinalizeCallButton
      aria-label="Desligar chamada"
      data-cy="btnEndCall"
      style={{backgroundColor: btnColor || "#fff"}}
      onClick={async () => {        
        await sweetAlert.confirm(
          "Deseja encerrar a chamada?",
          "Confirmar",
          "Permanecer na chamada",
          btnColor,
          'Alerta',
          textColor,
        ).then((result: SweetAlertResult<any>) => {          
          if(result.isConfirmed) {
            onFinalizeCall(true);
            if (type === "visitant") {
              visitantGetOutRoom(() => {
                if (!!redirectFeedback?.length) window.location.href = redirectFeedback
                else window.location.href = "/nps";                
              });
            };
  
            if (type === "guest") {
              window.location.href = `/convidado-agradecimento/${protocolId}`;
            };
          }         
        });                
      }}
    >
      <svg
        version="1.1"
        id="Camada_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 22 10"
        width="25"
      >
        <path
          d="M2.1,9.1h3.6c1.3,0,2.1-1.3,1.5-2.4C6.8,6,7.2,5,7.9,4.6c1.6-1,5-1,6.6,0c0.8,0.4,1,1.4,0.6,2.2
	c-0.6,1.1,0.3,2.4,1.5,2.4h3.6c1-0.1,1.7-0.8,1.7-1.7V5c-0.1-2.5-4.8-4.5-10.7-4.5c-6,0-10.7,2-10.7,4.5v2.4
	C0.5,8.4,1.3,9.1,2.1,9.1z"
          fill="#d02a3e"
        />
      </svg>
    </FinalizeCallButton>
  );
};
