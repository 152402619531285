export const getIconRating = (bgColor: string, txtColor: string) => {
    return {
      starEmpty: (
        <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28.5391" cy="28" r="28" fill="white" fillOpacity="0.5" />
          <path
            d="M43.0473 23.9823L43.0474 23.9824C43.0923 24.1206 43.0548 24.2723 42.9507 24.3738L42.9507 24.3738L36.197 30.9566L36.0088 31.1401L36.0532 31.3992L37.6476 40.6941C37.6476 40.6942 37.6477 40.6942 37.6477 40.6942C37.6722 40.8374 37.6133 40.9823 37.4956 41.0678C37.378 41.1532 37.2222 41.1646 37.0933 41.0969C37.0933 41.0969 37.0933 41.0969 37.0933 41.0968L28.7462 36.7083L28.5135 36.586L28.2808 36.7083L19.9333 41.0969L19.9332 41.097C19.8774 41.1263 19.8166 41.1408 19.7557 41.1408C19.6763 41.1408 19.5976 41.1162 19.5311 41.0679C19.4134 40.9823 19.3546 40.8375 19.3792 40.6943L19.3792 40.6943L20.9731 31.3992L21.0175 31.1401L20.8293 30.9566L14.0764 24.3739L14.0763 24.3738C13.9722 24.2724 13.9347 24.1206 13.9797 23.9823L13.9797 23.9822C14.0246 23.844 14.1442 23.7432 14.2879 23.7223L14.288 23.7223L23.6205 22.3661L23.8807 22.3283L23.997 22.0926L28.171 13.6358L27.7226 13.4145L28.171 13.6358C28.2354 13.5054 28.3681 13.4229 28.5136 13.4229C28.659 13.4229 28.7917 13.5054 28.856 13.6357L28.856 13.6358L33.0299 22.0926L33.1462 22.3283L33.4063 22.3661L42.7391 23.7223L42.7392 23.7223C42.8829 23.7432 43.0024 23.8439 43.0473 23.9823Z"
            stroke={txtColor || "white"}
            strokeOpacity="0.3"
          />
        </svg>
      ),
      starFull: (
        <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28.3594" cy="28" r="28" fill={bgColor || "#6229BF"} />
          <path
            d="M42.8676 23.9823L42.8677 23.9824C42.9126 24.1206 42.8751 24.2723 42.771 24.3738L42.771 24.3738L36.0173 30.9566L35.8291 31.1401L35.8735 31.3992L37.468 40.6941C37.468 40.6942 37.468 40.6942 37.468 40.6942C37.4925 40.8374 37.4336 40.9823 37.3159 41.0678C37.1984 41.1532 37.0425 41.1646 36.9136 41.0969C36.9136 41.0969 36.9136 41.0969 36.9136 41.0968L28.5665 36.7083L28.3338 36.586L28.1011 36.7083L19.7536 41.0969L19.7536 41.097C19.6977 41.1263 19.6369 41.1408 19.576 41.1408C19.4966 41.1408 19.4179 41.1162 19.3514 41.0679C19.2337 40.9823 19.1749 40.8375 19.1995 40.6943L19.1995 40.6943L20.7934 31.3992L20.8378 31.1401L20.6496 30.9566L13.8967 24.3739L13.8966 24.3738C13.7925 24.2724 13.755 24.1206 13.8 23.9823L13.8 23.9822C13.8449 23.844 13.9645 23.7432 14.1082 23.7223L14.1083 23.7223L23.4409 22.3661L23.701 22.3283L23.8173 22.0926L27.9913 13.6358L27.543 13.4145L27.9913 13.6358C28.0557 13.5054 28.1884 13.4229 28.3339 13.4229C28.4793 13.4229 28.612 13.5054 28.6763 13.6357L28.6763 13.6358L32.8502 22.0926L32.9665 22.3283L33.2266 22.3661L42.5594 23.7223L42.5595 23.7223C42.7032 23.7432 42.8227 23.8439 42.8676 23.9823Z"
            fill={txtColor || "white"}
            stroke={txtColor || "white"}
          />
        </svg>
      ),
      smilesEmpty: [
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill="white" fillOpacity="0.2" />
          <path
            d="M28.1027 14.8467C20.519 14.8467 14.3711 20.9945 14.3711 28.5783C14.3711 36.1621 20.519 42.31 28.1027 42.31C35.6865 42.31 41.8344 36.1621 41.8344 28.5783C41.8263 20.9979 35.6832 14.8548 28.1027 14.8467ZM28.1027 40.3483C21.6023 40.3483 16.3327 35.0787 16.3327 28.5783C16.3327 22.0779 21.6023 16.8083 28.1027 16.8083C34.6031 16.8083 39.8727 22.0779 39.8727 28.5783C39.8657 35.0758 34.6003 40.3413 28.1027 40.3483Z"
            fill="#FAFAFA"
            fillOpacity="0.3"
          />
          <path
            d="M23.1999 28.5786C24.2833 28.5786 25.1616 27.7003 25.1616 26.6169C25.1616 25.5335 24.2833 24.6553 23.1999 24.6553C22.1165 24.6553 21.2383 25.5335 21.2383 26.6169C21.2383 27.7003 22.1165 28.5786 23.1999 28.5786Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            d="M33.0085 28.5786C34.0919 28.5786 34.9702 27.7003 34.9702 26.6169C34.9702 25.5335 34.0919 24.6553 33.0085 24.6553C31.9251 24.6553 31.0469 25.5335 31.0469 26.6169C31.0469 27.7003 31.9251 28.5786 33.0085 28.5786Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            d="M24.3519 34.075C24.6759 34.5091 25.2904 34.5984 25.7246 34.2744C25.7434 34.2604 25.7616 34.2457 25.7793 34.2304C27.1664 33.2173 29.0493 33.2173 30.4364 34.2304C30.8462 34.5846 31.4656 34.5395 31.8198 34.1297C32.1741 33.7199 32.1289 33.1004 31.7191 32.7462C31.7014 32.7309 31.6831 32.7162 31.6643 32.7022C29.5649 31.0975 26.6508 31.0975 24.5514 32.7022C24.1172 33.0262 24.0279 33.6408 24.3519 34.075Z"
            fill="white"
            fillOpacity="0.3"
          />
          <rect
            x="22.125"
            y="20.6514"
            width="5.38653"
            height="1.66665"
            rx="0.833325"
            transform="rotate(33.2392 22.125 20.6514)"
            fill="white"
            fillOpacity="0.3"
          />
          <rect
            width="5.38653"
            height="1.66665"
            rx="0.833325"
            transform="matrix(-0.83639 0.548135 0.548135 0.83639 33.5312 20.6514)"
            fill="white"
            fillOpacity="0.3"
          />
        </svg>,
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill="white" fillOpacity="0.1" />
          <path
            d="M28.341 14.8467C20.7572 14.8467 14.6094 20.9945 14.6094 28.5783C14.6094 36.1621 20.7572 42.31 28.341 42.31C35.9248 42.31 42.0727 36.1621 42.0727 28.5783C42.0646 20.9979 35.9215 14.8548 28.341 14.8467ZM28.341 40.3483C21.8406 40.3483 16.571 35.0787 16.571 28.5783C16.571 22.0779 21.8406 16.8083 28.341 16.8083C34.8414 16.8083 40.111 22.0779 40.111 28.5783C40.104 35.0758 34.8385 40.3413 28.341 40.3483Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            d="M23.4343 28.5781C24.5177 28.5781 25.396 27.6998 25.396 26.6164C25.396 25.533 24.5177 24.6548 23.4343 24.6548C22.3509 24.6548 21.4727 25.533 21.4727 26.6164C21.4727 27.6998 22.3509 28.5781 23.4343 28.5781Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            d="M33.2468 28.5781C34.3302 28.5781 35.2085 27.6998 35.2085 26.6164C35.2085 25.533 34.3302 24.6548 33.2468 24.6548C32.1634 24.6548 31.2852 25.533 31.2852 26.6164C31.2852 27.6998 32.1634 28.5781 33.2468 28.5781Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            d="M24.5863 34.075C24.9102 34.5091 25.5248 34.5984 25.959 34.2744C25.9777 34.2604 25.996 34.2457 26.0137 34.2304C27.4008 33.2173 29.2837 33.2173 30.6707 34.2304C31.0806 34.5846 31.7 34.5395 32.0542 34.1297C32.4084 33.7199 32.3633 33.1004 31.9535 32.7462C31.9358 32.7309 31.9175 32.7162 31.8987 32.7022C29.7993 31.0975 26.8852 31.0975 24.7857 32.7022C24.3516 33.0262 24.2622 33.6408 24.5863 34.075Z"
            fill="white"
            fillOpacity="0.3"
          />
        </svg>,
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill="white" fillOpacity="0.1" />
          <path
            d="M28.5754 14.8467C20.9916 14.8467 14.8438 20.9945 14.8438 28.5783C14.8438 36.1621 20.9916 42.31 28.5754 42.31C36.1592 42.31 42.307 36.1621 42.307 28.5783C42.299 20.9979 36.1558 14.8548 28.5754 14.8467ZM28.5754 40.3483C22.075 40.3483 16.8054 35.0787 16.8054 28.5783C16.8054 22.0779 22.075 16.8083 28.5754 16.8083C35.0758 16.8083 40.3454 22.0779 40.3454 28.5783C40.3384 35.0758 35.0729 40.3413 28.5754 40.3483Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            d="M23.6765 28.5786C24.7599 28.5786 25.6381 27.7003 25.6381 26.6169C25.6381 25.5335 24.7599 24.6553 23.6765 24.6553C22.5931 24.6553 21.7148 25.5335 21.7148 26.6169C21.7148 27.7003 22.5931 28.5786 23.6765 28.5786Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            d="M33.4812 28.5786C34.5646 28.5786 35.4428 27.7003 35.4428 26.6169C35.4428 25.5335 34.5646 24.6553 33.4812 24.6553C32.3978 24.6553 31.5195 25.5335 31.5195 26.6169C31.5195 27.7003 32.3978 28.5786 33.4812 28.5786Z"
            fill="white"
            fillOpacity="0.3"
          />
          <rect
            x="23.6953"
            y="31.8008"
            width="9.77002"
            height="2.18389"
            rx="1.09194"
            fill="white"
            fillOpacity="0.3"
          />
        </svg>,
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill="white" fillOpacity="0.1" />
          <path
            d="M28.1027 14.8467C20.519 14.8467 14.3711 20.9945 14.3711 28.5783C14.3711 36.1621 20.519 42.31 28.1027 42.31C35.6865 42.31 41.8344 36.1621 41.8344 28.5783C41.8263 20.9979 35.6832 14.8548 28.1027 14.8467ZM28.1027 40.3483C21.6023 40.3483 16.3327 35.0787 16.3327 28.5783C16.3327 22.0779 21.6023 16.8083 28.1027 16.8083C34.6031 16.8083 39.8727 22.0779 39.8727 28.5783C39.8657 35.0758 34.6003 40.3413 28.1027 40.3483Z"
            fill="#FAFAFA"
            fillOpacity="0.3"
          />
          <path
            d="M23.1999 28.5786C24.2833 28.5786 25.1616 27.7003 25.1616 26.6169C25.1616 25.5335 24.2833 24.6553 23.1999 24.6553C22.1165 24.6553 21.2383 25.5335 21.2383 26.6169C21.2383 27.7003 22.1165 28.5786 23.1999 28.5786Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            d="M33.0085 28.5786C34.0919 28.5786 34.9702 27.7003 34.9702 26.6169C34.9702 25.5335 34.0919 24.6553 33.0085 24.6553C31.9251 24.6553 31.0469 25.5335 31.0469 26.6169C31.0469 27.7003 31.9251 28.5786 33.0085 28.5786Z"
            fill="white"
            fillOpacity="0.3"
          />
  
          <path
            d="M31.8083 31.9075C31.4843 31.4733 30.8697 31.384 30.4356 31.708C30.4168 31.7221 30.3986 31.7367 30.3808 31.7521C28.9938 32.7651 27.1108 32.7651 25.7238 31.7521C25.314 31.3978 24.6945 31.4429 24.3404 31.8527C23.9861 32.2626 24.0312 32.882 24.441 33.2362C24.4587 33.2515 24.477 33.2662 24.4958 33.2802C26.5953 34.8849 29.5093 34.8849 31.6088 33.2802C32.043 32.9562 32.1323 32.3416 31.8083 31.9075Z"
            fill="white"
            fillOpacity="0.3"
          />
        </svg>,
        <svg
          width="56"
          height="57"
          viewBox="0 0 56 57"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse
            cx="28"
            cy="28.5"
            rx="28"
            ry="28.5"
            fill="white"
            fillOpacity="0.1"
          />
          <path
            d="M28.052 14.8467C20.4682 14.8467 14.3203 20.9945 14.3203 28.5783C14.3203 36.1621 20.4682 42.31 28.052 42.31C35.6357 42.31 41.7836 36.1621 41.7836 28.5783C41.7756 20.9979 35.6324 14.8548 28.052 14.8467ZM28.052 40.3483C21.5516 40.3483 16.282 35.0787 16.282 28.5783C16.282 22.0779 21.5516 16.8083 28.052 16.8083C34.5524 16.8083 39.822 22.0779 39.822 28.5783C39.8149 35.0758 34.5495 40.3413 28.052 40.3483Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            d="M31.8083 31.9075C31.4843 31.4733 30.8697 31.384 30.4356 31.708C30.4168 31.7221 30.3986 31.7367 30.3808 31.7521C28.9938 32.7651 27.1108 32.7651 25.7238 31.7521C25.314 31.3978 24.6945 31.4429 24.3404 31.8527C23.9861 32.2626 24.0312 32.882 24.441 33.2362C24.4587 33.2515 24.477 33.2662 24.4958 33.2802C26.5953 34.8849 29.5093 34.8849 31.6088 33.2802C32.043 32.9562 32.1323 32.3416 31.8083 31.9075Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            d="M25.0116 23.3813C24.6124 23.3813 24.2465 23.5078 23.9239 23.7573C23.6146 23.9965 23.4087 24.3011 23.2875 24.5227C23.1663 24.3011 22.9604 23.9965 22.6511 23.7573C22.3285 23.5078 21.9626 23.3813 21.5634 23.3813C20.4494 23.3813 19.6094 24.2925 19.6094 25.5008C19.6094 26.8061 20.6574 27.6993 22.244 29.0513C22.5134 29.2809 22.8188 29.5412 23.1362 29.8187C23.178 29.8554 23.2318 29.8755 23.2875 29.8755C23.3432 29.8755 23.397 29.8554 23.4388 29.8187C23.7563 29.5411 24.0616 29.2809 24.3312 29.0511C25.9176 27.6993 26.9656 26.8061 26.9656 25.5008C26.9656 24.2925 26.1256 23.3813 25.0116 23.3813Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            d="M34.5507 23.3813C34.1515 23.3813 33.7855 23.5078 33.4629 23.7573C33.1537 23.9965 32.9478 24.3011 32.8266 24.5227C32.7053 24.3011 32.4994 23.9965 32.1902 23.7573C31.8676 23.5078 31.5016 23.3813 31.1024 23.3813C29.9885 23.3813 29.1484 24.2925 29.1484 25.5008C29.1484 26.8061 30.1965 27.6993 31.783 29.0513C32.0524 29.2809 32.3578 29.5412 32.6752 29.8187C32.7171 29.8554 32.7708 29.8755 32.8266 29.8755C32.8823 29.8755 32.936 29.8554 32.9779 29.8187C33.2953 29.5411 33.6007 29.2809 33.8703 29.0511C35.4567 27.6993 36.5047 26.8061 36.5047 25.5008C36.5047 24.2925 35.6646 23.3813 34.5507 23.3813Z"
            fill="white"
            fillOpacity="0.3"
          />
        </svg>,
      ],
      smilesFull: [
        <svg
          width="56"
          height="57"
          viewBox="0 0 56 57"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse cx="28" cy="28.5" rx="28" ry="28.5" fill={bgColor || "#6229BF"} />
          <path
            d="M27.7316 15C20.1479 15 14 21.1479 14 28.7316C14 36.3154 20.1479 42.4633 27.7316 42.4633C35.3154 42.4633 41.4633 36.3154 41.4633 28.7316C41.4552 21.1513 35.3121 15.0081 27.7316 15ZM27.7316 40.5016C21.2312 40.5016 15.9616 35.232 15.9616 28.7316C15.9616 22.2312 21.2312 16.9616 27.7316 16.9616C34.232 16.9616 39.5016 22.2312 39.5016 28.7316C39.4946 35.2292 34.2292 40.4946 27.7316 40.5016Z"
            fill="white"
            fillOpacity="0.3"
          />
          <circle cx="28" cy="29" r="14" fill="white" />
          <path
            d="M23.1999 30.5786C24.2833 30.5786 25.1616 29.7003 25.1616 28.6169C25.1616 27.5335 24.2833 26.6553 23.1999 26.6553C22.1165 26.6553 21.2383 27.5335 21.2383 28.6169C21.2383 29.7003 22.1165 30.5786 23.1999 30.5786Z"
            fill={bgColor || "#6229BF"}
          />
          <path
            d="M33.0085 30.5786C34.0919 30.5786 34.9702 29.7003 34.9702 28.6169C34.9702 27.5335 34.0919 26.6553 33.0085 26.6553C31.9251 26.6553 31.0469 27.5335 31.0469 28.6169C31.0469 29.7003 31.9251 30.5786 33.0085 30.5786Z"
            fill={bgColor || "#6229BF"}
          />
          <path
            d="M24.3519 36.075C24.6759 36.5091 25.2904 36.5984 25.7246 36.2744C25.7434 36.2604 25.7616 36.2457 25.7793 36.2304C27.1664 35.2173 29.0493 35.2173 30.4364 36.2304C30.8462 36.5846 31.4656 36.5395 31.8198 36.1297C32.1741 35.7199 32.1289 35.1004 31.7191 34.7462C31.7014 34.7309 31.6831 34.7162 31.6643 34.7022C29.5649 33.0975 26.6508 33.0975 24.5514 34.7022C24.1172 35.0262 24.0279 35.6408 24.3519 36.075Z"
            fill={bgColor || "#6229BF"}
          />
          <rect
            x="22.125"
            y="22.6514"
            width="5.38653"
            height="1.66665"
            rx="0.833325"
            transform="rotate(33.2392 22.125 22.6514)"
            fill={bgColor || "#6229BF"}
          />
          <rect
            width="5.38653"
            height="1.66665"
            rx="0.833325"
            transform="matrix(-0.83639 0.548135 0.548135 0.83639 33.5312 22.6514)"
            fill={bgColor || "#6229BF"}
          />
        </svg>,
        <svg
          width="56"
          height="57"
          viewBox="0 0 56 57"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse cx="28" cy="28.5" rx="28" ry="28.5" fill={bgColor || "#6229BF"} />
          <path
            d="M28.341 14.8467C20.7572 14.8467 14.6094 20.9945 14.6094 28.5783C14.6094 36.1621 20.7572 42.31 28.341 42.31C35.9248 42.31 42.0727 36.1621 42.0727 28.5783C42.0646 20.9979 35.9215 14.8548 28.341 14.8467ZM28.341 40.3483C21.8406 40.3483 16.571 35.0787 16.571 28.5783C16.571 22.0779 21.8406 16.8083 28.341 16.8083C34.8414 16.8083 40.111 22.0779 40.111 28.5783C40.104 35.0758 34.8385 40.3413 28.341 40.3483Z"
            fill={bgColor || "#6229BF"}
          />
          <circle cx="28" cy="29" r="14" fill="white" />
          <path
            d="M23.4343 28.5781C24.5177 28.5781 25.396 27.6998 25.396 26.6164C25.396 25.533 24.5177 24.6548 23.4343 24.6548C22.3509 24.6548 21.4727 25.533 21.4727 26.6164C21.4727 27.6998 22.3509 28.5781 23.4343 28.5781Z"
            fill={bgColor || "#6229BF"}
          />
          <path
            d="M33.2468 28.5781C34.3302 28.5781 35.2085 27.6998 35.2085 26.6164C35.2085 25.533 34.3302 24.6548 33.2468 24.6548C32.1634 24.6548 31.2852 25.533 31.2852 26.6164C31.2852 27.6998 32.1634 28.5781 33.2468 28.5781Z"
            fill={bgColor || "#6229BF"}
          />
          <path
            d="M24.5863 34.075C24.9102 34.5091 25.5248 34.5984 25.959 34.2744C25.9777 34.2604 25.996 34.2457 26.0137 34.2304C27.4008 33.2173 29.2837 33.2173 30.6707 34.2304C31.0806 34.5846 31.7 34.5395 32.0542 34.1297C32.4084 33.7199 32.3633 33.1004 31.9535 32.7462C31.9358 32.7309 31.9175 32.7162 31.8987 32.7022C29.7993 31.0975 26.8852 31.0975 24.7857 32.7022C24.3516 33.0262 24.2622 33.6408 24.5863 34.075Z"
            fill={bgColor || "#6229BF"}
          />
        </svg>,
        <svg
          width="57"
          height="57"
          viewBox="0 0 57 57"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28.5" cy="28.5" r="28.5" fill={bgColor || "#6229BF"} />
          <circle cx="29" cy="29" r="14" fill="white" />
          <path
            d="M23.6765 29.5786C24.7599 29.5786 25.6381 28.7003 25.6381 27.6169C25.6381 26.5335 24.7599 25.6553 23.6765 25.6553C22.5931 25.6553 21.7148 26.5335 21.7148 27.6169C21.7148 28.7003 22.5931 29.5786 23.6765 29.5786Z"
            fill={bgColor || "#6229BF"}
          />
          <path
            d="M33.4812 29.5786C34.5646 29.5786 35.4428 28.7003 35.4428 27.6169C35.4428 26.5335 34.5646 25.6553 33.4812 25.6553C32.3978 25.6553 31.5195 26.5335 31.5195 27.6169C31.5195 28.7003 32.3978 29.5786 33.4812 29.5786Z"
            fill={bgColor || "#6229BF"}
          />
          <rect
            x="23.6953"
            y="32.8008"
            width="9.77002"
            height="2.18389"
            rx="1.09194"
            fill={bgColor || "#6229BF"}
          />
        </svg>,
        <svg
          width="57"
          height="57"
          viewBox="0 0 57 57"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28.5" cy="28.5" r="28.5" fill={bgColor || "#6229BF"} />
          <path
            d="M42 29C42 36.732 35.732 43 28 43C20.268 43 14 36.732 14 29C14 21.268 20.268 15 28 15C35.732 15 42 21.268 42 29Z"
            fill="white"
          />
          <path
            d="M22.7527 28.5786C23.8717 28.5786 24.7788 27.6715 24.7788 26.5525C24.7788 25.4335 23.8717 24.5264 22.7527 24.5264C21.6337 24.5264 20.7266 25.4335 20.7266 26.5525C20.7266 27.6715 21.6337 28.5786 22.7527 28.5786Z"
            fill={bgColor || "#6229BF"}
          />
          <path
            d="M32.8816 28.5791C34.0006 28.5791 34.9077 27.672 34.9077 26.553C34.9077 25.434 34.0006 24.5269 32.8816 24.5269C31.7626 24.5269 30.8555 25.434 30.8555 26.553C30.8555 27.672 31.7626 28.5791 32.8816 28.5791Z"
            fill={bgColor || "#6229BF"}
          />
          <path
            d="M31.6983 32.002C31.3637 31.5536 30.7289 31.4613 30.2805 31.796C30.2611 31.8105 30.2423 31.8256 30.224 31.8414C28.7913 32.8878 26.8465 32.8878 25.4138 31.8414C24.9905 31.4756 24.3508 31.5221 23.9849 31.9454C23.619 32.3687 23.6656 33.0085 24.0889 33.3744C24.1072 33.3902 24.1261 33.4053 24.1455 33.4198C26.314 35.0773 29.3238 35.0773 31.4923 33.4198C31.9408 33.0852 32.033 32.4504 31.6983 32.002Z"
            fill={bgColor || "#6229BF"}
          />
        </svg>,
        <svg
          width="56"
          height="57"
          viewBox="0 0 56 57"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse cx="28" cy="28.5" rx="28" ry="28.5" fill={bgColor || "#6229BF"} />
          <path
            d="M28.052 14.8467C20.4682 14.8467 14.3203 20.9945 14.3203 28.5783C14.3203 36.1621 20.4682 42.31 28.052 42.31C35.6357 42.31 41.7836 36.1621 41.7836 28.5783C41.7756 20.9979 35.6324 14.8548 28.052 14.8467ZM28.052 40.3483C21.5516 40.3483 16.282 35.0787 16.282 28.5783C16.282 22.0779 21.5516 16.8083 28.052 16.8083C34.5524 16.8083 39.822 22.0779 39.822 28.5783C39.8149 35.0758 34.5495 40.3413 28.052 40.3483Z"
            fill={bgColor || "#6229BF"}
          />
          <path
            d="M42 29C42 36.732 35.732 43 28 43C20.268 43 14 36.732 14 29C14 21.268 20.268 15 28 15C35.732 15 42 21.268 42 29Z"
            fill="white"
          />
          <path
            d="M31.8083 32.9075C31.4843 32.4733 30.8697 32.384 30.4356 32.708C30.4168 32.7221 30.3986 32.7367 30.3808 32.7521C28.9938 33.7651 27.1108 33.7651 25.7238 32.7521C25.314 32.3978 24.6945 32.4429 24.3404 32.8527C23.9861 33.2626 24.0312 33.882 24.441 34.2362C24.4587 34.2515 24.477 34.2662 24.4958 34.2802C26.5953 35.8849 29.5093 35.8849 31.6088 34.2802C32.043 33.9562 32.1323 33.3416 31.8083 32.9075Z"
            fill={bgColor || "#6229BF"}
          />
          <path
            d="M25.0116 24.3813C24.6124 24.3813 24.2465 24.5078 23.9239 24.7573C23.6146 24.9965 23.4087 25.3011 23.2875 25.5227C23.1663 25.3011 22.9604 24.9965 22.6511 24.7573C22.3285 24.5078 21.9626 24.3813 21.5634 24.3813C20.4494 24.3813 19.6094 25.2925 19.6094 26.5008C19.6094 27.8061 20.6574 28.6993 22.244 30.0513C22.5134 30.2809 22.8188 30.5412 23.1362 30.8187C23.178 30.8554 23.2318 30.8755 23.2875 30.8755C23.3432 30.8755 23.397 30.8554 23.4388 30.8187C23.7563 30.5411 24.0616 30.2809 24.3312 30.0511C25.9176 28.6993 26.9656 27.8061 26.9656 26.5008C26.9656 25.2925 26.1256 24.3813 25.0116 24.3813Z"
            fill={bgColor || "#6229BF"}
          />
          <path
            d="M34.5507 24.3813C34.1515 24.3813 33.7855 24.5078 33.4629 24.7573C33.1537 24.9965 32.9478 25.3011 32.8266 25.5227C32.7053 25.3011 32.4994 24.9965 32.1902 24.7573C31.8676 24.5078 31.5016 24.3813 31.1024 24.3813C29.9885 24.3813 29.1484 25.2925 29.1484 26.5008C29.1484 27.8061 30.1965 28.6993 31.783 30.0513C32.0524 30.2809 32.3578 30.5412 32.6752 30.8187C32.7171 30.8554 32.7708 30.8755 32.8266 30.8755C32.8823 30.8755 32.936 30.8554 32.9779 30.8187C33.2953 30.5411 33.6007 30.2809 33.8703 30.0511C35.4567 28.6993 36.5047 27.8061 36.5047 26.5008C36.5047 25.2925 35.6646 24.3813 34.5507 24.3813Z"
            fill={bgColor || "#6229BF"}
          />
        </svg>,
      ],
      numbersFull: [
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill={bgColor || "#6229BF"} />
          <path
            d="M24.7793 24.58V21.4H30.3193V36H26.7593V24.58H24.7793Z"
            fill="white"
          />
        </svg>,
        <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28.1797" cy="28" r="28" fill={bgColor || "#6229BF"} />
          <path
            d="M23.0654 33.26C23.5187 32.9 23.7254 32.7334 23.6854 32.76C24.9921 31.68 26.0187 30.7934 26.7654 30.1C27.5254 29.4067 28.1654 28.68 28.6854 27.92C29.2054 27.16 29.4654 26.42 29.4654 25.7C29.4654 25.1534 29.3387 24.7267 29.0854 24.42C28.8321 24.1134 28.4521 23.96 27.9454 23.96C27.4387 23.96 27.0387 24.1534 26.7454 24.54C26.4654 24.9134 26.3254 25.4467 26.3254 26.14H23.0254C23.0521 25.0067 23.2921 24.06 23.7454 23.3C24.2121 22.54 24.8187 21.98 25.5654 21.62C26.3254 21.26 27.1654 21.08 28.0854 21.08C29.6721 21.08 30.8654 21.4867 31.6654 22.3C32.4787 23.1134 32.8854 24.1734 32.8854 25.48C32.8854 26.9067 32.3987 28.2334 31.4254 29.46C30.4521 30.6734 29.2121 31.86 27.7054 33.02H33.1054V35.8H23.0654V33.26Z"
            fill="white"
          />
        </svg>,
        <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28.3594" cy="28" r="28" fill={bgColor || "#6229BF"} />
          <path
            d="M22.9652 25.52C23.0185 24.0933 23.4852 22.9933 24.3652 22.22C25.2452 21.4467 26.4385 21.06 27.9452 21.06C28.9452 21.06 29.7985 21.2333 30.5052 21.58C31.2252 21.9267 31.7652 22.4 32.1252 23C32.4985 23.6 32.6852 24.2733 32.6852 25.02C32.6852 25.9 32.4652 26.62 32.0252 27.18C31.5852 27.7267 31.0718 28.1 30.4852 28.3V28.38C31.2452 28.6333 31.8452 29.0533 32.2852 29.64C32.7252 30.2267 32.9452 30.98 32.9452 31.9C32.9452 32.7267 32.7518 33.46 32.3652 34.1C31.9918 34.7267 31.4385 35.22 30.7052 35.58C29.9852 35.94 29.1252 36.12 28.1252 36.12C26.5252 36.12 25.2452 35.7267 24.2852 34.94C23.3385 34.1533 22.8385 32.9667 22.7852 31.38H26.1052C26.1185 31.9667 26.2852 32.4333 26.6052 32.78C26.9252 33.1133 27.3918 33.28 28.0052 33.28C28.5252 33.28 28.9252 33.1333 29.2052 32.84C29.4985 32.5333 29.6452 32.1333 29.6452 31.64C29.6452 31 29.4385 30.54 29.0252 30.26C28.6252 29.9667 27.9785 29.82 27.0852 29.82H26.4452V27.04H27.0852C27.7652 27.04 28.3118 26.9267 28.7252 26.7C29.1518 26.46 29.3652 26.04 29.3652 25.44C29.3652 24.96 29.2318 24.5867 28.9652 24.32C28.6985 24.0533 28.3318 23.92 27.8652 23.92C27.3585 23.92 26.9785 24.0733 26.7252 24.38C26.4852 24.6867 26.3452 25.0667 26.3052 25.52H22.9652Z"
            fill="white"
          />
        </svg>,
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill={bgColor || "#6229BF"} />
          <path
            d="M22.2109 33.4V30.64L28.5309 21.6H32.3509V30.44H33.9909V33.4H32.3509V36H28.9309V33.4H22.2109ZM29.1709 25.36L25.8109 30.44H29.1709V25.36Z"
            fill="white"
          />
        </svg>,
        <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28.7188" cy="28" r="28" fill={bgColor || "#6229BF"} />
          <path
            d="M33.6758 24.38H27.4358V27.14C27.7024 26.8467 28.0758 26.6067 28.5558 26.42C29.0358 26.2333 29.5558 26.14 30.1158 26.14C31.1158 26.14 31.9424 26.3667 32.5958 26.82C33.2624 27.2733 33.7491 27.86 34.0558 28.58C34.3624 29.3 34.5158 30.08 34.5158 30.92C34.5158 32.48 34.0758 33.72 33.1958 34.64C32.3158 35.5467 31.0758 36 29.4758 36C28.4091 36 27.4824 35.82 26.6958 35.46C25.9091 35.0867 25.3024 34.5733 24.8758 33.92C24.4491 33.2667 24.2158 32.5133 24.1758 31.66H27.5158C27.5958 32.0733 27.7891 32.42 28.0958 32.7C28.4024 32.9667 28.8224 33.1 29.3558 33.1C29.9824 33.1 30.4491 32.9 30.7558 32.5C31.0624 32.1 31.2158 31.5667 31.2158 30.9C31.2158 30.2467 31.0558 29.7467 30.7358 29.4C30.4158 29.0533 29.9491 28.88 29.3358 28.88C28.8824 28.88 28.5091 28.9933 28.2158 29.22C27.9224 29.4333 27.7291 29.72 27.6358 30.08H24.3358V21.38H33.6758V24.38Z"
            fill="white"
          />
        </svg>,
        <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
        <circle cx="28.7188" cy="28" r="28" fill={bgColor || "#6229BF"} />
        <path 
          d="M29.292 35.176C28.236 35.176 27.2827 34.9267 26.432 34.428C25.5813 33.9293 24.9067 33.196 24.408 32.228C23.9093 31.26 23.66 30.0793 23.66 28.686C23.66 27.8207 23.8213 26.9847 24.144 26.178C24.4813 25.3567 24.936 24.5793 25.508 23.846C26.0947 23.098 26.7693 22.4013 27.532 21.756C28.2947 21.1107 29.1087 20.5313 29.974 20.018L32.108 22.328C30.9347 22.7827 29.974 23.3693 29.226 24.088C28.478 24.792 27.928 25.5253 27.576 26.288C27.224 27.0507 27.048 27.7547 27.048 28.4L26.256 28.312C26.652 27.388 27.158 26.6693 27.774 26.156C28.4047 25.6427 29.2773 25.386 30.392 25.386C31.096 25.386 31.778 25.584 32.438 25.98C33.098 26.376 33.6333 26.926 34.044 27.63C34.4693 28.334 34.682 29.1553 34.682 30.094C34.682 31.1793 34.418 32.1033 33.89 32.866C33.3767 33.614 32.7093 34.186 31.888 34.582C31.0667 34.978 30.2013 35.176 29.292 35.176ZM29.16 32.316C29.5853 32.316 29.9593 32.2353 30.282 32.074C30.6193 31.898 30.8833 31.6487 31.074 31.326C31.2647 30.9887 31.36 30.5853 31.36 30.116C31.36 29.6613 31.2573 29.2873 31.052 28.994C30.8613 28.686 30.5973 28.4587 30.26 28.312C29.9373 28.1507 29.5707 28.07 29.16 28.07C28.7493 28.07 28.3753 28.1507 28.038 28.312C27.7153 28.4587 27.4513 28.664 27.246 28.928C27.0553 29.1773 26.96 29.4487 26.96 29.742V30.27C26.96 30.622 27.0553 30.952 27.246 31.26C27.4367 31.568 27.7007 31.8247 28.038 32.03C28.3753 32.2207 28.7493 32.316 29.16 32.316Z" 
          fill="white"
        />
      </svg>,
      <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
        <circle cx="28.7188" cy="28" r="28" fill={bgColor || "#6229BF"} />
        <path 
          d="M33.736 20.414L28.302 35H24.738L29.248 23.12L30.282 23.494H23.88V20.414H33.736Z" 
          fill="white"
        />
      </svg>,
      <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
        <circle cx="28.7188" cy="28" r="28" fill={bgColor || "#6229BF"} />
        <path 
          d="M23.33 29.27C23.33 28.5073 23.4693 27.862 23.748 27.334C24.0267 26.806 24.364 26.3733 24.76 26.036C25.1707 25.684 25.5447 25.4053 25.882 25.2C25.5593 24.8627 25.2807 24.518 25.046 24.166C24.826 23.7993 24.716 23.286 24.716 22.626C24.716 21.966 24.8993 21.3793 25.266 20.866C25.6473 20.3527 26.1533 19.9567 26.784 19.678C27.4147 19.3847 28.1113 19.238 28.874 19.238C29.6367 19.238 30.3333 19.3847 30.964 19.678C31.6093 19.9567 32.1227 20.338 32.504 20.822C32.9 21.306 33.098 21.856 33.098 22.472C33.098 23.2053 32.9733 23.7553 32.724 24.122C32.4893 24.4887 32.196 24.848 31.844 25.2C32.196 25.42 32.5627 25.706 32.944 26.058C33.34 26.41 33.6773 26.85 33.956 27.378C34.2347 27.8913 34.374 28.522 34.374 29.27C34.374 30.1207 34.132 30.92 33.648 31.668C33.164 32.416 32.504 33.0247 31.668 33.494C30.8467 33.9487 29.9007 34.176 28.83 34.176C27.7593 34.176 26.806 33.9487 25.97 33.494C25.1487 33.0247 24.5033 32.416 24.034 31.668C23.5647 30.92 23.33 30.1207 23.33 29.27ZM26.608 29.138C26.608 29.5633 26.7107 29.9593 26.916 30.326C27.1213 30.678 27.3927 30.964 27.73 31.184C28.0673 31.3893 28.4413 31.492 28.852 31.492C29.2773 31.492 29.666 31.3893 30.018 31.184C30.37 30.964 30.6487 30.678 30.854 30.326C31.074 29.9593 31.184 29.5633 31.184 29.138C31.184 28.7127 31.0813 28.3313 30.876 27.994C30.6853 27.642 30.4213 27.356 30.084 27.136C29.7613 26.916 29.3947 26.7987 28.984 26.784C28.5733 26.7693 28.1847 26.8647 27.818 27.07C27.466 27.2753 27.1727 27.5613 26.938 27.928C26.718 28.28 26.608 28.6833 26.608 29.138ZM27.818 23.022C27.818 23.3153 27.9353 23.572 28.17 23.792C28.4193 24.012 28.676 24.122 28.94 24.122C29.204 24.122 29.4533 24.012 29.688 23.792C29.9227 23.572 30.04 23.3153 30.04 23.022C30.04 22.7287 29.9227 22.472 29.688 22.252C29.468 22.0173 29.2187 21.9 28.94 21.9C28.6613 21.9 28.4047 22.0173 28.17 22.252C27.9353 22.472 27.818 22.7287 27.818 23.022Z" 
          fill="white"
        />
      </svg>,
      <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
        <circle cx="28.7188" cy="28" r="28" fill={bgColor || "#6229BF"} />
        <path 
          d="M27.95 30.446C27.246 30.446 26.564 30.248 25.904 29.852C25.2587 29.456 24.7233 28.906 24.298 28.202C23.8727 27.4833 23.66 26.662 23.66 25.738C23.66 24.5647 23.9167 23.5673 24.43 22.746C24.958 21.9247 25.6327 21.3013 26.454 20.876C27.2753 20.4507 28.1407 20.238 29.05 20.238C30.1207 20.238 31.074 20.48 31.91 20.964C32.7607 21.4333 33.4353 22.086 33.934 22.922C34.4327 23.7433 34.682 24.6747 34.682 25.716C34.682 26.9187 34.4693 28.004 34.044 28.972C33.6333 29.9253 33.076 30.7833 32.372 31.546C31.6827 32.3087 30.9127 33.0053 30.062 33.636C29.2113 34.252 28.3533 34.8313 27.488 35.374L25.354 33.064C26.5273 32.58 27.5613 32.03 28.456 31.414C29.3507 30.7833 30.0473 30.1307 30.546 29.456C31.0447 28.7667 31.294 28.092 31.294 27.432L32.086 27.52C31.69 28.444 31.184 29.1627 30.568 29.676C29.952 30.1893 29.0793 30.446 27.95 30.446ZM29.182 27.762C29.798 27.762 30.3187 27.564 30.744 27.168C31.1693 26.772 31.382 26.3247 31.382 25.826V25.32C31.382 24.9387 31.2867 24.5793 31.096 24.242C30.9053 23.9047 30.6413 23.6333 30.304 23.428C29.9813 23.208 29.6073 23.098 29.182 23.098C28.7713 23.098 28.3973 23.1933 28.06 23.384C27.7227 23.56 27.4587 23.824 27.268 24.176C27.0773 24.528 26.982 24.9607 26.982 25.474C26.982 25.9727 27.0773 26.398 27.268 26.75C27.4733 27.0873 27.7373 27.344 28.06 27.52C28.3973 27.6813 28.7713 27.762 29.182 27.762Z" 
          fill="white"
        />
      </svg>,
      <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
        <circle cx="28.7188" cy="28" r="28" fill={bgColor || "#6229BF"} />
        <path 
          d="M19.1 21.414H23.478V36H20.266V24.494H19.1V21.414ZM32.4705 36.022C31.6345 36.022 30.8425 35.8753 30.0945 35.582C29.3612 35.2887 28.7085 34.8413 28.1365 34.24C27.5792 33.624 27.1392 32.854 26.8165 31.93C26.4938 30.9913 26.3325 29.8913 26.3325 28.63C26.3325 27.222 26.4865 26.0487 26.7945 25.11C27.1025 24.1567 27.5278 23.394 28.0705 22.822C28.6278 22.25 29.2805 21.8467 30.0285 21.612C30.7765 21.3627 31.5905 21.238 32.4705 21.238C33.3065 21.238 34.0912 21.3847 34.8245 21.678C35.5725 21.9713 36.2252 22.426 36.7825 23.042C37.3545 23.6433 37.8018 24.4133 38.1245 25.352C38.4472 26.276 38.6085 27.3687 38.6085 28.63C38.6085 29.9353 38.4472 31.0573 38.1245 31.996C37.8018 32.9347 37.3545 33.7047 36.7825 34.306C36.2105 34.8927 35.5578 35.3253 34.8245 35.604C34.0912 35.8827 33.3065 36.022 32.4705 36.022ZM32.4485 32.964C33.1818 32.964 33.7538 32.7733 34.1645 32.392C34.5752 31.996 34.8612 31.4607 35.0225 30.786C35.1985 30.1113 35.2865 29.3633 35.2865 28.542C35.2865 27.7353 35.1985 27.0167 35.0225 26.386C34.8612 25.7553 34.5752 25.2567 34.1645 24.89C33.7538 24.5087 33.1818 24.318 32.4485 24.318C31.7298 24.318 31.1652 24.5087 30.7545 24.89C30.3438 25.2713 30.0578 25.7847 29.8965 26.43C29.7352 27.0753 29.6545 27.8013 29.6545 28.608C29.6545 29.4147 29.7352 30.148 29.8965 30.808C30.0578 31.468 30.3438 31.996 30.7545 32.392C31.1652 32.7733 31.7298 32.964 32.4485 32.964Z" 
          fill="white"
        />
      </svg>,
      ],
      numbersEmpty: [
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill="white" fill-opacity="0.1" />
          <path
            d="M24.7793 24.58V21.4H30.3193V36H26.7593V24.58H24.7793Z"
            fill="white"
          />
        </svg>,
        <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28.1797" cy="28" r="28" fill="white" fill-opacity="0.1" />
          <path
            d="M23.0654 33.26C23.5187 32.9 23.7254 32.7334 23.6854 32.76C24.9921 31.68 26.0187 30.7934 26.7654 30.1C27.5254 29.4067 28.1654 28.68 28.6854 27.92C29.2054 27.16 29.4654 26.42 29.4654 25.7C29.4654 25.1534 29.3387 24.7267 29.0854 24.42C28.8321 24.1134 28.4521 23.96 27.9454 23.96C27.4387 23.96 27.0387 24.1534 26.7454 24.54C26.4654 24.9134 26.3254 25.4467 26.3254 26.14H23.0254C23.0521 25.0067 23.2921 24.06 23.7454 23.3C24.2121 22.54 24.8187 21.98 25.5654 21.62C26.3254 21.26 27.1654 21.08 28.0854 21.08C29.6721 21.08 30.8654 21.4867 31.6654 22.3C32.4787 23.1134 32.8854 24.1734 32.8854 25.48C32.8854 26.9067 32.3987 28.2334 31.4254 29.46C30.4521 30.6734 29.2121 31.86 27.7054 33.02H33.1054V35.8H23.0654V33.26Z"
            fill="white"
          />
        </svg>,
        <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28.3594" cy="28" r="28" fill="white" fill-opacity="0.1" />
          <path
            d="M22.9652 25.52C23.0185 24.0933 23.4852 22.9933 24.3652 22.22C25.2452 21.4467 26.4385 21.06 27.9452 21.06C28.9452 21.06 29.7985 21.2333 30.5052 21.58C31.2252 21.9267 31.7652 22.4 32.1252 23C32.4985 23.6 32.6852 24.2733 32.6852 25.02C32.6852 25.9 32.4652 26.62 32.0252 27.18C31.5852 27.7267 31.0718 28.1 30.4852 28.3V28.38C31.2452 28.6333 31.8452 29.0533 32.2852 29.64C32.7252 30.2267 32.9452 30.98 32.9452 31.9C32.9452 32.7267 32.7518 33.46 32.3652 34.1C31.9918 34.7267 31.4385 35.22 30.7052 35.58C29.9852 35.94 29.1252 36.12 28.1252 36.12C26.5252 36.12 25.2452 35.7267 24.2852 34.94C23.3385 34.1533 22.8385 32.9667 22.7852 31.38H26.1052C26.1185 31.9667 26.2852 32.4333 26.6052 32.78C26.9252 33.1133 27.3918 33.28 28.0052 33.28C28.5252 33.28 28.9252 33.1333 29.2052 32.84C29.4985 32.5333 29.6452 32.1333 29.6452 31.64C29.6452 31 29.4385 30.54 29.0252 30.26C28.6252 29.9667 27.9785 29.82 27.0852 29.82H26.4452V27.04H27.0852C27.7652 27.04 28.3118 26.9267 28.7252 26.7C29.1518 26.46 29.3652 26.04 29.3652 25.44C29.3652 24.96 29.2318 24.5867 28.9652 24.32C28.6985 24.0533 28.3318 23.92 27.8652 23.92C27.3585 23.92 26.9785 24.0733 26.7252 24.38C26.4852 24.6867 26.3452 25.0667 26.3052 25.52H22.9652Z"
            fill="white"
          />
        </svg>,
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill="white" fill-opacity="0.1" />
          <path
            d="M22.2109 33.4V30.64L28.5309 21.6H32.3509V30.44H33.9909V33.4H32.3509V36H28.9309V33.4H22.2109ZM29.1709 25.36L25.8109 30.44H29.1709V25.36Z"
            fill="white"
          />
        </svg>,
        <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28.7188" cy="28" r="28" fill="white" fill-opacity="0.1" />
          <path
            d="M33.6758 24.38H27.4358V27.14C27.7024 26.8467 28.0758 26.6067 28.5558 26.42C29.0358 26.2333 29.5558 26.14 30.1158 26.14C31.1158 26.14 31.9424 26.3667 32.5958 26.82C33.2624 27.2733 33.7491 27.86 34.0558 28.58C34.3624 29.3 34.5158 30.08 34.5158 30.92C34.5158 32.48 34.0758 33.72 33.1958 34.64C32.3158 35.5467 31.0758 36 29.4758 36C28.4091 36 27.4824 35.82 26.6958 35.46C25.9091 35.0867 25.3024 34.5733 24.8758 33.92C24.4491 33.2667 24.2158 32.5133 24.1758 31.66H27.5158C27.5958 32.0733 27.7891 32.42 28.0958 32.7C28.4024 32.9667 28.8224 33.1 29.3558 33.1C29.9824 33.1 30.4491 32.9 30.7558 32.5C31.0624 32.1 31.2158 31.5667 31.2158 30.9C31.2158 30.2467 31.0558 29.7467 30.7358 29.4C30.4158 29.0533 29.9491 28.88 29.3358 28.88C28.8824 28.88 28.5091 28.9933 28.2158 29.22C27.9224 29.4333 27.7291 29.72 27.6358 30.08H24.3358V21.38H33.6758V24.38Z"
            fill="white"
          />
        </svg>,
        <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
        <circle cx="28.7188" cy="28" r="28" fill="white" fill-opacity="0.1"/>
        <path 
          d="M29.292 35.176C28.236 35.176 27.2827 34.9267 26.432 34.428C25.5813 33.9293 24.9067 33.196 24.408 32.228C23.9093 31.26 23.66 30.0793 23.66 28.686C23.66 27.8207 23.8213 26.9847 24.144 26.178C24.4813 25.3567 24.936 24.5793 25.508 23.846C26.0947 23.098 26.7693 22.4013 27.532 21.756C28.2947 21.1107 29.1087 20.5313 29.974 20.018L32.108 22.328C30.9347 22.7827 29.974 23.3693 29.226 24.088C28.478 24.792 27.928 25.5253 27.576 26.288C27.224 27.0507 27.048 27.7547 27.048 28.4L26.256 28.312C26.652 27.388 27.158 26.6693 27.774 26.156C28.4047 25.6427 29.2773 25.386 30.392 25.386C31.096 25.386 31.778 25.584 32.438 25.98C33.098 26.376 33.6333 26.926 34.044 27.63C34.4693 28.334 34.682 29.1553 34.682 30.094C34.682 31.1793 34.418 32.1033 33.89 32.866C33.3767 33.614 32.7093 34.186 31.888 34.582C31.0667 34.978 30.2013 35.176 29.292 35.176ZM29.16 32.316C29.5853 32.316 29.9593 32.2353 30.282 32.074C30.6193 31.898 30.8833 31.6487 31.074 31.326C31.2647 30.9887 31.36 30.5853 31.36 30.116C31.36 29.6613 31.2573 29.2873 31.052 28.994C30.8613 28.686 30.5973 28.4587 30.26 28.312C29.9373 28.1507 29.5707 28.07 29.16 28.07C28.7493 28.07 28.3753 28.1507 28.038 28.312C27.7153 28.4587 27.4513 28.664 27.246 28.928C27.0553 29.1773 26.96 29.4487 26.96 29.742V30.27C26.96 30.622 27.0553 30.952 27.246 31.26C27.4367 31.568 27.7007 31.8247 28.038 32.03C28.3753 32.2207 28.7493 32.316 29.16 32.316Z" 
          fill="white"
        />
      </svg>,
      <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
        <circle cx="28.7188" cy="28" r="28" fill="white" fill-opacity="0.1"/>
        <path 
          d="M33.736 20.414L28.302 35H24.738L29.248 23.12L30.282 23.494H23.88V20.414H33.736Z" 
          fill="white"
        />
      </svg>,
      <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
        <circle cx="28.7188" cy="28" r="28" fill="white" fill-opacity="0.1"/>
        <path 
          d="M23.33 29.27C23.33 28.5073 23.4693 27.862 23.748 27.334C24.0267 26.806 24.364 26.3733 24.76 26.036C25.1707 25.684 25.5447 25.4053 25.882 25.2C25.5593 24.8627 25.2807 24.518 25.046 24.166C24.826 23.7993 24.716 23.286 24.716 22.626C24.716 21.966 24.8993 21.3793 25.266 20.866C25.6473 20.3527 26.1533 19.9567 26.784 19.678C27.4147 19.3847 28.1113 19.238 28.874 19.238C29.6367 19.238 30.3333 19.3847 30.964 19.678C31.6093 19.9567 32.1227 20.338 32.504 20.822C32.9 21.306 33.098 21.856 33.098 22.472C33.098 23.2053 32.9733 23.7553 32.724 24.122C32.4893 24.4887 32.196 24.848 31.844 25.2C32.196 25.42 32.5627 25.706 32.944 26.058C33.34 26.41 33.6773 26.85 33.956 27.378C34.2347 27.8913 34.374 28.522 34.374 29.27C34.374 30.1207 34.132 30.92 33.648 31.668C33.164 32.416 32.504 33.0247 31.668 33.494C30.8467 33.9487 29.9007 34.176 28.83 34.176C27.7593 34.176 26.806 33.9487 25.97 33.494C25.1487 33.0247 24.5033 32.416 24.034 31.668C23.5647 30.92 23.33 30.1207 23.33 29.27ZM26.608 29.138C26.608 29.5633 26.7107 29.9593 26.916 30.326C27.1213 30.678 27.3927 30.964 27.73 31.184C28.0673 31.3893 28.4413 31.492 28.852 31.492C29.2773 31.492 29.666 31.3893 30.018 31.184C30.37 30.964 30.6487 30.678 30.854 30.326C31.074 29.9593 31.184 29.5633 31.184 29.138C31.184 28.7127 31.0813 28.3313 30.876 27.994C30.6853 27.642 30.4213 27.356 30.084 27.136C29.7613 26.916 29.3947 26.7987 28.984 26.784C28.5733 26.7693 28.1847 26.8647 27.818 27.07C27.466 27.2753 27.1727 27.5613 26.938 27.928C26.718 28.28 26.608 28.6833 26.608 29.138ZM27.818 23.022C27.818 23.3153 27.9353 23.572 28.17 23.792C28.4193 24.012 28.676 24.122 28.94 24.122C29.204 24.122 29.4533 24.012 29.688 23.792C29.9227 23.572 30.04 23.3153 30.04 23.022C30.04 22.7287 29.9227 22.472 29.688 22.252C29.468 22.0173 29.2187 21.9 28.94 21.9C28.6613 21.9 28.4047 22.0173 28.17 22.252C27.9353 22.472 27.818 22.7287 27.818 23.022Z" 
          fill="white"
        />
      </svg>,
      <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
        <circle cx="28.7188" cy="28" r="28" fill="white" fill-opacity="0.1"/>
        <path 
          d="M27.95 30.446C27.246 30.446 26.564 30.248 25.904 29.852C25.2587 29.456 24.7233 28.906 24.298 28.202C23.8727 27.4833 23.66 26.662 23.66 25.738C23.66 24.5647 23.9167 23.5673 24.43 22.746C24.958 21.9247 25.6327 21.3013 26.454 20.876C27.2753 20.4507 28.1407 20.238 29.05 20.238C30.1207 20.238 31.074 20.48 31.91 20.964C32.7607 21.4333 33.4353 22.086 33.934 22.922C34.4327 23.7433 34.682 24.6747 34.682 25.716C34.682 26.9187 34.4693 28.004 34.044 28.972C33.6333 29.9253 33.076 30.7833 32.372 31.546C31.6827 32.3087 30.9127 33.0053 30.062 33.636C29.2113 34.252 28.3533 34.8313 27.488 35.374L25.354 33.064C26.5273 32.58 27.5613 32.03 28.456 31.414C29.3507 30.7833 30.0473 30.1307 30.546 29.456C31.0447 28.7667 31.294 28.092 31.294 27.432L32.086 27.52C31.69 28.444 31.184 29.1627 30.568 29.676C29.952 30.1893 29.0793 30.446 27.95 30.446ZM29.182 27.762C29.798 27.762 30.3187 27.564 30.744 27.168C31.1693 26.772 31.382 26.3247 31.382 25.826V25.32C31.382 24.9387 31.2867 24.5793 31.096 24.242C30.9053 23.9047 30.6413 23.6333 30.304 23.428C29.9813 23.208 29.6073 23.098 29.182 23.098C28.7713 23.098 28.3973 23.1933 28.06 23.384C27.7227 23.56 27.4587 23.824 27.268 24.176C27.0773 24.528 26.982 24.9607 26.982 25.474C26.982 25.9727 27.0773 26.398 27.268 26.75C27.4733 27.0873 27.7373 27.344 28.06 27.52C28.3973 27.6813 28.7713 27.762 29.182 27.762Z" 
          fill="white"
        />
      </svg>,
      <svg
          width="57"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
        <circle cx="28.7188" cy="28" r="28" fill="white" fill-opacity="0.1"/>
        <path 
          d="M19.1 21.414H23.478V36H20.266V24.494H19.1V21.414ZM32.4705 36.022C31.6345 36.022 30.8425 35.8753 30.0945 35.582C29.3612 35.2887 28.7085 34.8413 28.1365 34.24C27.5792 33.624 27.1392 32.854 26.8165 31.93C26.4938 30.9913 26.3325 29.8913 26.3325 28.63C26.3325 27.222 26.4865 26.0487 26.7945 25.11C27.1025 24.1567 27.5278 23.394 28.0705 22.822C28.6278 22.25 29.2805 21.8467 30.0285 21.612C30.7765 21.3627 31.5905 21.238 32.4705 21.238C33.3065 21.238 34.0912 21.3847 34.8245 21.678C35.5725 21.9713 36.2252 22.426 36.7825 23.042C37.3545 23.6433 37.8018 24.4133 38.1245 25.352C38.4472 26.276 38.6085 27.3687 38.6085 28.63C38.6085 29.9353 38.4472 31.0573 38.1245 31.996C37.8018 32.9347 37.3545 33.7047 36.7825 34.306C36.2105 34.8927 35.5578 35.3253 34.8245 35.604C34.0912 35.8827 33.3065 36.022 32.4705 36.022ZM32.4485 32.964C33.1818 32.964 33.7538 32.7733 34.1645 32.392C34.5752 31.996 34.8612 31.4607 35.0225 30.786C35.1985 30.1113 35.2865 29.3633 35.2865 28.542C35.2865 27.7353 35.1985 27.0167 35.0225 26.386C34.8612 25.7553 34.5752 25.2567 34.1645 24.89C33.7538 24.5087 33.1818 24.318 32.4485 24.318C31.7298 24.318 31.1652 24.5087 30.7545 24.89C30.3438 25.2713 30.0578 25.7847 29.8965 26.43C29.7352 27.0753 29.6545 27.8013 29.6545 28.608C29.6545 29.4147 29.7352 30.148 29.8965 30.808C30.0578 31.468 30.3438 31.996 30.7545 32.392C31.1652 32.7733 31.7298 32.964 32.4485 32.964Z"
          fill="white"
        />
      </svg>,
      ],
    };
  };