import styled from "styled-components";
import iconArrow from "../../assets/images/arrow-r.svg";
export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
`;
export const ChatStyled = styled.div`
  background: #1a1422;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  min-width: 330px;
  height: ${({
    videoContainerHeigth,
  }: {
    videoContainerHeigth: number;
    toggleChat?: boolean;
  }) => (videoContainerHeigth ? `${videoContainerHeigth}px` : '70%')};
  display: none;
  box-sizing: border-box;
  z-index: 500;
  transition: all ease-in 0.4s;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 20px 20px 0px 20px;
  position: relative;  
`;

export const ChatArea = styled.div`
  display: ${({ toggleChat }: { toggleChat?: boolean }) => toggleChat ? 'none' : 'flex'};
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  margin-right: 10px;
  align-items: center;
  justify-content: center;        

  #chat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.6rem;
    gap: 0.5rem;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: auto;
    scrollbar-color: #8f54a0 #1a1422;
    ::-webkit-scrollbar {
      width: 16px;
    }
    ::-webkit-scrollbar-track {
      background: #1a1422;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #8f54a0;
      border-radius: 10px;
      border: 3px solid #1a1422;
    }
  }
`;



export const AccessibilityChatArea = styled.span`
	width:0px;
	height:0px;
	padding:0;
	margin:-1px;
	overflow:hidden;
  clip:rect(0,0,0,0);
	border:0
`;
export const ToogleChat = styled.button`
  background: #1a1422 url(${iconArrow}) no-repeat center center;
  bottom: 25%;
  position: relative;
  width: 18px;
  height: 74px;
  border-radius: 7.2px 0 0px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  border: 0px;
  z-index: 1000;
`;

export const ChatBar = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  border-radius: 60px;
  background-color: #e5e5e5;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  input {
    background: transparent;
    border: 0px;
    height: 100%;
    margin-left: 10px;
    min-height: 45px;
    ::placeholder {
      color: #8a8a8a;
    }
  }

  button {
    cursor: pointer;
    border: 0;
    border-radius: 100%;
    padding: 7px;
    background-color: ${({ bgColor }: { bgColor: string }) => bgColor};
    height: 35px;
    width: 35px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 6px;

    svg {
      width: 15px;
    }
  }
`;

export const ChatMessagesArea = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
`;

export const FileuploadArea = styled.label`
  cursor: pointer;
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 10px;

  input {
    display: block;
    width: 0;
    height: 0;
  }
`;

export const FileIconArea = styled.div`
  height: 20px;
  width: 20px;
  padding: 5px;
  background-color: ${({ bgColor }: { bgColor: string }) => bgColor};
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
`;
