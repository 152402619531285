import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { ChatBox } from "../../components/Chat";
import { ToggleAudioButton } from "../../components/ToggleAudioButton";
import { ToggleVideoButton } from "../../components/ToggleVideoButton";
import { useRoomConnect } from "../../hooks/useRoomConnect";
import { request } from "../../services/request";
import {
  getProtocolId,
  getSSVideoEnable,
  getSSAudioEnable,
  removeSSAudioEnable,
  removeSSVideoEnable,
  getVId,
} from "../../sessionstorage";
import {
  socket,
  pauseRoomByAttendant,
  roomFinalizedByAttendant,
  backInRoom,
  connectedInSocket,
  visitantGetOutRoom,
} from "../../socket";
import { ChatIconButton, ChatCloseIconButton } from "../../assets/icons";
import {
  RoomContainer,
  SwitchButtonContainer,
  AreaButtons,
  OpenChatButton,
  ChatIconArea,
} from "./styles";
import { EndCallButton } from "../../components/EndCallButton";

import { Participant as ParticipantTwilio } from "twilio-video";
import { WaitingScreen } from "../../components/WaitingScreen";
import Video from "../../components/Video";
import { useLocation } from "react-router-dom";

type IClient = {
  logo: string;
  textColor: string;
  buttonColor: string;
  iconColor: string;
  backgroundColor: string;
  contrastColor: string;
  attendantName: string;
  attendantImg: string;
  showChat: boolean;
  feedbackLink: string;
  showAudioButton: boolean;
};

interface ILocation {
  audioEnabled: boolean;
}

let backQueueTimer = setTimeout(() => {}, 10);

export const Room: React.FC = () => {
  const { state } = useLocation<ILocation>();
  const [openChat, setOpenChat] = useState<boolean>(false);
  const [openManual, setOpenManual] = useState<boolean>(false);
  const { participants, room, videoTrack, connectRoom } = useRoomConnect(); // eslint-disable-line
  const [client, setClient] = useState<IClient>();

  const [roomIsPaused, setRoomIsPaused] = useState<{
    userImg: string;
    userName: string;
    roomIsPaused: boolean;
    roleUser: string;
  }>();
  const attendantAreaRef = useRef<HTMLDivElement>({} as HTMLDivElement);

  const [chatHeight, setChatHeight] = useState<number>(0);
  const [participantIsAttendant, setParticipantIsAttendant] =
    useState<ParticipantTwilio>();
  const [participantIsGuest, setParticipantIsGuest] =
    useState<ParticipantTwilio>();

  const localVideoRef = useRef<any>();

  const history = useHistory();

  const [videoDisabled, setVideoDisabled] = useState(true);
  const [finalizeCall, setFinalizeCall] = useState<boolean>(false);

  useEffect(() => {
    if (participants.length === 0) {
      backQueueTimer = setTimeout(() => {
        visitantGetOutRoom(() => {
          setFinalizeCall(true);
          if (!!client?.feedbackLink?.length) window.location.href = client.feedbackLink
          else window.location.href = "/fila";                
        });
      }, 90000)
    } else {
      clearTimeout(backQueueTimer);
    }

    return () => clearTimeout(backQueueTimer);
  });

  const clientData = useCallback(async () => {
    await connectedInSocket();
    const protocolId = getProtocolId();

    let vId = getVId();

    const req = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "GET",
      path: `rooms/get-assets/${protocolId}`,
    });
    if (!req.error) {
      socket.removeListener("FINALIZE_ROOM_TO_VISITANT");

      roomFinalizedByAttendant(() => {
        removeSSAudioEnable();
        removeSSVideoEnable();
        if (!!req?.feedbackLink.length)
          window.location.href = req?.feedbackLink;
        else window.location.href = "/nps";
      });
      setClient(req);
      if (!room.name && protocolId) {
        try {
          await connectRoom({
            roomId: protocolId,
            type: "visitant",
          });
          if (vId) {
            backInRoom(vId, () => {});
          }
        } catch (error) {
          history.push(`fila`);
        }
      }
    } else {
      history.push(`/`);
    }
  }, [connectRoom, history, room]);

  useEffect(() => {
    setFinalizeCall(false);
    roomFinalizedByAttendant(() => {
      removeSSAudioEnable();
      removeSSVideoEnable();
      window.location.href = "/nps";
    });

    clientData();

    pauseRoomByAttendant(
      (
        userName: string,
        userImg: string,
        roomIsPaused: boolean,
        roleUser: string
      ) => {
        setRoomIsPaused({
          userImg,
          roomIsPaused,
          userName,
          roleUser,
        });
      }
    );
  }, []); // eslint-disable-line

  useEffect(() => {
    if (videoTrack && videoTrack.kind) {
      videoTrack.attach(localVideoRef.current);
      let localVideoRefIntoEffect = localVideoRef.current;

      videoTrack.on("enabled", () => setVideoDisabled(false));
      videoTrack.on("disabled", () => setVideoDisabled(true));
      setVideoDisabled(!videoTrack.isEnabled);

      return () => {
        videoTrack.detach(localVideoRefIntoEffect);
      };
    }
  }, [videoTrack]);

  useEffect(() => {
    participants.forEach((p) => {
      if (p.identity.includes("guest")) setParticipantIsGuest(p);
      else if (!p.identity.includes("guest") && !!participantIsGuest)
        setParticipantIsGuest(undefined);
      if (p.identity.includes("attendant")) setParticipantIsAttendant(p);
    });
  }, [participants, participantIsGuest]);

  return (
    <Fragment>
      {roomIsPaused?.roomIsPaused && (
        <WaitingScreen
          avatar={roomIsPaused.userImg}
          name={roomIsPaused.userName}
          role={roomIsPaused.roleUser}
          textColor={client?.textColor}
          backgroundColor={client?.backgroundColor}
          contrastColor={client?.contrastColor}
        />
      )}
      <RoomContainer pageAssets={client}>
        <Video
          hasAttendant
          hasGuest
          context="desktop"
          localVideoRef={localVideoRef}
          participantIsAttendant={participantIsAttendant}
          participantIsGuest={participantIsGuest}
          videoDisabled={videoDisabled}
          finalizeCall={finalizeCall}
          client={client}
          openChat={openChat}
        />

        {client && (
          <ChatBox
            videoContainerHeigth={chatHeight}
            isAllowDigit={true}
            className={openChat ? "chatOpen" : ""}
            toggleChat={openChat}
            pageAssets={client}
            setToggleChat={setOpenChat}
            openManual={openManual}
            setOpenManual={setOpenManual}
          />
        )}

        <AreaButtons>
          <SwitchButtonContainer>
            {true ? (
              <OpenChatButton
                data-cy="btnChat"
                onClick={() => {
                  setTimeout(() => {
                    if (!openChat) {
                      setChatHeight(attendantAreaRef.current.clientHeight);
                    }
                  }, 100);
                  setOpenChat(!openChat);
                  setOpenManual(() => true);
                }}
                aria-label={!openChat ? "Abrir Chat" : "Minimizar chat"}
                style={{
                  backgroundColor:
                    client?.buttonColor || client?.backgroundColor,
                }}
              >
                <ChatIconArea>
                  <ChatIconButton
                    bgColor={client ? client?.iconColor : "#000"}
                  />
                </ChatIconArea>
              </OpenChatButton>
            ) : null}

            {!openChat ? (
              <>
                {" "}
                {client?.showAudioButton && (
                  <ToggleAudioButton
                    data-cy="btnAudio"
                    type="circle"
                    pauseRoom={roomIsPaused?.roomIsPaused}
                    enabled={
                      state?.audioEnabled || getSSAudioEnable() === "true"
                        ? true
                        : false
                    }
                    bgColor={client?.buttonColor || "#fff"}
                  />
                )}
                <ToggleVideoButton
                  data-cy="btnVideo"
                  type="circle"
                  bgColor={client?.buttonColor || "#fff"}
                  enabled={getSSVideoEnable() === "true" ? true : false}
                />
                <EndCallButton
                  type="visitant"
                  onFinalizeCall={setFinalizeCall}
                  btnColor={
                    client?.buttonColor || client?.backgroundColor || "#fff"
                  }
                  textColor={client?.textColor || "#FFF"}
                  redirectFeedback={client?.feedbackLink || ""}
                />
              </>
            ) : null}
          </SwitchButtonContainer>
        </AreaButtons>
      </RoomContainer>
    </Fragment>
  );
};
