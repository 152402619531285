import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  #root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    touch-action: pan-y;
  }

  textarea:focus, input:focus{
    outline: none;
  }

  body ,html{
    touch-action: pan-y;
    margin: 0;
    padding: 0;
    font-family: Poppins, Helvetica, Sans-Serif;
    box-sizing:border-box;
    height: 100%;
  }

  .logo-client {
    max-width: 200px;
    max-height: 80px;
  }
`;

export default GlobalStyle;
