import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { DinamicForm } from "../../components/DinamicForm/index";
import { request } from "../../services/request";
import { getVId } from "../../sessionstorage";
import { QueueContainer } from "./styled";

type IFormFields = Array<{
  title: string;
  key: string;
  required: string;
  msgError?: string;
}>;

interface IPropsPreQueueForm {
  logo: string;
  textColor: string;
  backgroundColor: string;
  message: string;
  formFields: IFormFields;
  contrastColor: string;
}

export const PreQueueForm: React.FC<IPropsPreQueueForm> = ({
  message,
  formFields,
  logo,
  textColor,
  backgroundColor,
  contrastColor,
}: IPropsPreQueueForm) => {
  const history = useHistory();
  const [formFieldsState, setFormFieldsState] = useState<IFormFields>();
  const handleSubmit = async (finalFields: any) => {
    setFormFieldsState(formFields);
    const response = await request({
      showSuccessMessage: false,
      method: "POST",
      path: "visitant/initial-form",
      showErrorMessage: false,
      data: {
        formData: finalFields,
        visitantId: getVId(),
      },
    });

    if (response.error && response.error.data && formFieldsState) {
      let formFieldWithError: IFormFields = [];
      for (const field of formFieldsState) {
        let fieldWithoutError: any = response.error.data.find(
          (f: any) => f.key === field.key
        );

        if (fieldWithoutError) {
          formFieldWithError.push({
            ...field,
            msgError: fieldWithoutError.message,
          });
        } else {
          formFieldWithError.push({
            ...field,
          });
        }
      }

      if (formFieldWithError.length > 0) {
        setFormFieldsState(formFieldWithError);
      }
    }

    if (!response.error && response.nextPage === "queue") {
      history.push("/fila");
    }
  };

  useEffect(() => {
    setFormFieldsState(formFields);
  }, [formFields]);

  return (
    <QueueContainer pageAssets={{ backgroundColor, contrastColor }}>
      <img src={logo} alt="logo" className="logo-client" />
      {formFieldsState && (
        <DinamicForm
          message={message}
          formFields={formFieldsState}
          onHandleSubmit={handleSubmit}
          style={{ backgroundColor, contrastColor }}
        />
      )}
      <div />
    </QueueContainer>
  );
};
