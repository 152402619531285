import { useCallback, useEffect, useState } from "react";
import { request } from "../../services/request";
import { getProtocolId, getVId } from "../../sessionstorage";
import {
  addMessageIntoChat,
  chatMessages,
  fileRequestedByAttendant,
  getChatMessages,
} from "../../socket";
import {
  ChatStyled,
  ChatBar,
  ChatMessagesArea,
  ToogleChat,
  FileuploadArea,
  FileIconArea,
  ChatArea,
  AccessibilityChatArea,
  FlexContainer,
} from "./styled";
import { Ballon } from "./messageBox";
import { SendIconButton, FileIcon } from "../../assets/icons";

interface ChatProps {
  toggleChat: boolean;
  setToggleChat: Function;
  className: string;
  pageAssets: any;
  isAllowDigit: boolean;
  videoContainerHeigth: number;
  openManual: boolean;
  setOpenManual: Function;
}

interface IMessages {
  type: "attendant" | "visitant";
  msg: string;
  _id: string;
  messageType: "MESSAGE" | "ATTACHMENT";
  filename?: string;
  personId: string;
}

export const ChatBox = ({
  pageAssets,
  toggleChat,
  setToggleChat,
  isAllowDigit,
  videoContainerHeigth,
  openManual,
  setOpenManual,
  ...props
}: ChatProps) => {
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<Array<IMessages>>([]);
  const [showFileUpload, setShowFileUpload] = useState<boolean>(false);

  const setMessagesInFront = (chatMessages: Array<any>) => {
    setMessages(chatMessages);
    const chatDiv = document.getElementById("chat") as HTMLDivElement;
    if (chatDiv) chatDiv.scrollTop = chatDiv.scrollHeight;
  };

  const handleSendFile = useCallback(async (file: File) => {
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async () => {
        try {
          const response = await request({
            showLoading: true,
            timeout: 99999,
            showSuccessMessage: false,
            method: "POST",
            path: `rooms/room/send-file/${getProtocolId()}`,
            data: { file: reader.result, filename: file.name },
          });

          if (!response.error) {
            addMessageIntoChat(
              "visitant",
              getProtocolId() ?? "",
              response,
              getVId() ?? "",
              "ATTACHMENT",
              (chatMessages: Array<any>) => {
                setMessagesInFront(chatMessages);
              },
              file.name
            );
            setShowFileUpload(false);
          }
        } catch (e) {}
      };
    }
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (message === "") return;
    addMessageIntoChat(
      "visitant",
      getProtocolId() ?? "",
      message,
      getVId() ?? "",
      "MESSAGE",
      (chatMessages: Array<any>) => {
        setMessagesInFront(chatMessages);
      }
    );
    setMessage("");
  };

  const onEnterChat = (e: any) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  useEffect(() => {
    chatMessages((chatMessages: Array<any>) => {
      setToggleChat(true);
      setMessagesInFront(chatMessages);
      setOpenManual(() => false);
    });

    getChatMessages((chatMessages: Array<any>) => {
      setMessagesInFront(chatMessages);
    });

    fileRequestedByAttendant(() => {
      setToggleChat(true);
      setShowFileUpload(true);
      setOpenManual(() => false);
    });
  }, []); //eslint-disable-line
  
  return (
    <FlexContainer>
      <ChatArea toggleChat={!toggleChat}>
        <ToogleChat
          onClick={() => {setToggleChat(!toggleChat); setOpenManual(() => false)}}
          tabIndex={0}
          aria-label="Minimizar chat"
        />

        <ChatStyled
          videoContainerHeigth={videoContainerHeigth}
          toggleChat={!toggleChat}
        >
          <ChatMessagesArea id="chat">
            {messages.map((msg, index) => {
              return (
                <Ballon
                  txtColor={pageAssets?.textColor}
                  bgColor={{
                    visitant:
                      pageAssets?.chatBalloonColor ||
                      pageAssets?.backgroundColor,
                    attendant:
                      pageAssets?.attendantChatBalloonColor ||
                      pageAssets?.backgroundColor,
                  }}
                  key={msg._id}
                  type={msg.type}
                  msg={msg.msg}
                  personId={msg.personId}
                  _id={msg._id}
                  messageType={msg.messageType}
                  isAllowDigit={isAllowDigit}
                  index={index}
                  totalMsg={messages.length - 1}
                  attendantName={pageAssets?.attendantName}
                  filename={msg?.filename}
                />
              );
            })}
          </ChatMessagesArea>

          {messages ? (
            <AccessibilityChatArea
              aria-live="assertive"
              tabIndex={0}
              role="alert"
            >
              {messages[messages.length - 1]?.messageType === "MESSAGE" ? (
                <span
                  aria-label={
                    messages[messages.length - 1]?.type === "visitant"
                      ? "Eu: " + messages[messages.length - 1]?.msg
                      : "Atendente: " +
                        messages[messages.length - 1]?.msg
                  }
                ></span>
              ) : (
                <span aria-label="Anexo enviado"></span>
              )}
            </AccessibilityChatArea>
          ) : null}

          {showFileUpload && (
            <FileuploadArea
              htmlFor="file"
              aria-live="assertive"
              tabIndex={0}
              role="alert"
            >
              <FileIconArea bgColor={pageAssets?.backgroundColor}>
                <FileIcon bgColor={pageAssets?.textColor || "#FFF"} />
              </FileIconArea>
              <span color={pageAssets?.textColor || "#FFF"}>
                Adicionar um anexo
              </span>
              <input
                type="file"
                name="file"
                id="file"
                aria-hidden={true}
                onChange={(e: any) => {
                  handleSendFile(e.target.files[0]);
                }}
                autoFocus={showFileUpload}
              />
            </FileuploadArea>
          )}

          {pageAssets && isAllowDigit && toggleChat && (
            <ChatBar bgColor={pageAssets?.backgroundColor}>
              <input
                data-cy="inputChat"
                type="text"
                value={message}
                placeholder="Digite sua mensagem"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                onKeyDown={onEnterChat}
                autoFocus={toggleChat && openManual}
              />
              <button
                style={{
                  backgroundColor:
                    pageAssets?.buttonColor ||
                    pageAssets?.backgroundColor ||
                    "#FFF",
                }}
                aria-label="enviar mensagem"
                data-cy="btnSendChat"
                onClick={handleSubmit}
              >
                <SendIconButton bgColor={pageAssets?.iconColor || "#FFF"} />
              </button>
            </ChatBar>
          )}
        </ChatStyled>
      </ChatArea>
    </FlexContainer>
  );
};
